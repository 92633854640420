import React from 'react';
import PageLayout from '../../../global/partialBase/pageLayout';
import UserDetailsBody from './body';

const UserDetails = () => {
    return (
        <div>
            <PageLayout tabMenu={false} body={<UserDetailsBody />} />
        </div>
    )
}

export default UserDetails;