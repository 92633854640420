import React from 'react';
import { ResponsiveBar } from '@nivo/bar'


const SingleLineStackedBarChart = ({options,enableLabel=true }) => { 

//   console.log("Object keys",options, enableLabel)   
    return <ResponsiveBar
        className = "Data-chartdemo"
        data={options.data}
        keys={Object.keys(options.data[0])} // Stacks to display
        indexBy="id"
        // margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
        padding={.1}
        innerPadding={3}
        borderRadius={3}
        enableLabel={enableLabel}
        label={d => `${d.id}: ${d.value}`} 
        labelTextColor={d => {
            // //console.log("colors",d)
            if (d.data.id === 'COD') return '#0086C9'; // Red for categoryA
            if (d.data.id === 'Prepaid') return '#E31B54'; // Green for categoryB
            return '#000000'; // Default color
        }}
        layout="horizontal" // Can be "horizontal" for a horizontal bar chart
        // valueScale={{ type: 'linear' }}
        // indexScale={{ type: 'band', round: true }}
        // colors={{ scheme: 'nivo' }} // Color scheme for the stacks
        colors={options.color}
        // borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisBottom={null} // Remove X axis if needed
        axisLeft={null}   // Remove Y axis if needed
        enableGridX={false} // Remove grid lines if desired
        enableGridY={false} // Remove grid lines if desired
        labelSkipWidth={2}
        labelSkipHeight={6}
        tooltip={point => {
            //// console.log(point)
            return <div style={{boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",background:'#ffffff', padding:"5px 9px"}}><span style={{background:point.color, width:'12px',height:'12px',marginRight:'10px', display:'inline-block'}}></span>{point.id} - {point.value}</div>;
          }}

        theme={{
            labels: {
                text: {
                    fontSize: 16,  // Custom font size
                    fontFamily: 'InterMedium',  // Custom font family
                },
            },
        }}
        // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    />
};

export default SingleLineStackedBarChart;
