import React, { useState, useEffect, createRef } from 'react';
import { Modal, Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Search from '../../../assets/Iconsearch.svg';
import backArrow from '../../../assets/backArrow.svg'
import { Badge } from 'reactstrap';
import { useSelector } from 'react-redux';
import instance from '../../../helpers/axiosInstance';
import SuccessToast from '../../customToast/SuccessToast';
import ErrorToast from '../../customToast/ErrorToast';
import { Slide, toast } from 'react-toastify';
import { commonServiceMethod } from '../../../helpers/helper';
import Loader from '../loader';

const CallStatus = ({ item, show, handleClose, handleSave }) => {
    const [enableSaveBtn, setEnableSaveBtn] = useState(false);
    const [enableDecline, setEnableDecline] = useState(false);
    const [enableOther, setEnableOther] = useState(false);
    const [enableDeclineOther, setEnableDeclineOther] = useState(false);
    const [showAdvancedToggle, setShowAdvancedToggle] = useState(false);
    const [quickAccessplaceHolders, setQuickAccessplaceHolders] = useState('');
    const [nextQuickAccessModal, setNextQuickAccessModal] = useState(false)

    const [callstatusData, setCallStatusData] = useState({ status: "", other: "" });
    const [callData, setCallData] = useState({ fromNumber: item?.customer_phone, toNumber: "9514315599", order_id: item.order_id });
    const [calcenStatus, setCalcenStatus] = useState({ order_ids: item.order_id ? item?.order_id : item?.map(i => i.order_id).toString() || '', cancel_reason: "", cancel_comments: "" });
    const [returnStatus, setReturnStatus] = useState({ orderID: item.id ? item?.id : item?.map(i => i.id).toString() || '', returnReason: "", cancel_comments: "" });
    const [showOther, setShowOther] = useState(false);
    const [callOtherStatusData, setCallOtherStatusData] = useState("");
    const [formData, setFormData] = useState({
        quickSelect: '',
        quickInput: ''
    });


    const [loader, setLoader] = useState(false);

    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })

        if (name === 'quickSelect') {
            quickAccessChange(value)
        }

    }
    const userClaim = useSelector((state) => state.user);
    const [werehouseList, setWerehouseList] = useState([]);
    const [selectedItem, setSelectedItem] = useState("");
    const [selectedServiceList, setSelectedServiceList] = useState([]);
    const [selectedService, setSelectedService] = useState("");
    const [fraudAnalysisData] = useState(JSON.parse(item?.fraud_data || '{}'));

    useEffect(() => {
        if (userClaim.token !== "") {
            getWerehouseList();
            getshipping_services();
        }
    }, [userClaim]);
    const getWerehouseList = () => {
        try {
            instance.get("app/location").then((res) => {

                if (res.status === 200 && Array.isArray(res.data?.data)) {
                    let data = res.data?.data.map(val => {
                        if (val.shipping_carrier_name === "buledart") {
                            val.shipping_carrier_name = "bluedart";
                        }
                        return val;
                    })
                    setWerehouseList(data || []);
                }

            }).catch(e => {
                console.error(e);
                setWerehouseList([]);
            })
        }
        catch (e) {
            console.error(e);
            setWerehouseList([]);
        }
    }
    const getshipping_services = () => {
        try {
            instance.get("app/shipping_services/getshipping_services").then((res) => {

                if (res.status === 200 && Array.isArray(res.data?.services)) {
                    setSelectedServiceList(res.data?.services || []);
                }

            }).catch(e => {
                console.error(e);
                setWerehouseList([]);
            })
        }
        catch (e) {
            console.error(e);
            setWerehouseList([]);
        }
    }
    const changeStatus = (e) => {
        // console.log(e.target.value);
        var value = e.target.value;
        setCallStatusData({ other: "", status: value })
        if (value === 'accepted') {
            setEnableSaveBtn(true)
            setEnableDecline(false)
            setEnableOther(false)
            setEnableDeclineOther(false)
        }
        if (value === 'declined') {
            setEnableDecline(true)
            setEnableSaveBtn(true)
            setEnableOther(false);
            setCallStatusData({ other: "Order wrongly placed", status: value })
        }

        if (value === 'other') {
            setEnableOther(true)
            setEnableDecline(false)
            setEnableSaveBtn(false)
        }

        if (value === 'select') {
            setEnableSaveBtn(false);
            setEnableDecline(false)
            setEnableOther(false)
            setEnableDeclineOther(false)
        }
    }

    const changeStatusDecline = (e) => {
        var value = e.target.value;
        setCallStatusData({ ...callstatusData, other: value })
        if (value === 'others') {
            setEnableOther(true)
            setEnableDecline(true)
            setEnableDeclineOther(true)
            setEnableSaveBtn(false)
        }
        else {
            setEnableOther(false)
            setEnableDeclineOther(false)
            setEnableSaveBtn(true)
        }
    }

    const inputChange = (e) => {
        var value = e.target.value;
        // console.log(value.length)
        setCallStatusData({ ...callstatusData, other: value })
        if (value.length > 0) {
            setEnableSaveBtn(true)
        }
        else {
            setEnableSaveBtn(false)
        }
    }

    const cancelUpdate = (e) => {
        if (e.target.value === "Others") {
            setShowOther(true);
        }
        else {
            setShowOther(false);
        }
        setCalcenStatus({ ...calcenStatus, cancel_reason: e.target.value })
    }

    const returnUpdate = (e) => {
        if (e.target.value === "Others") {
            setShowOther(true);
        }
        else {
            setShowOther(false);
        }
        setReturnStatus({ ...returnStatus, returnReason: e.target.value })
    }


    const quickAccessChange = (value) => {

        if (value === '') {
            setQuickAccessplaceHolders('Search')
        }

        if (value === 'orderID') {
            setQuickAccessplaceHolders('Search for label')
        }

        if (value === 'email') {
            setQuickAccessplaceHolders('Search for email')
        }

        if (value === 'phoneNumber') {
            setQuickAccessplaceHolders('Search for phone number')
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};

        if (!formData.quickSelect) {
            newErrors.quickSelect = "Select the option";
        }

        if (!formData.quickInput) {
            newErrors.quickInput = "Please enter the value";
        }

        if (Object.keys(newErrors).length === 0) {
            // console.log('Form data is valid:', formData);
            setNextQuickAccessModal(true)
        } else {
            setErrors(newErrors);
        }
    }

    const [barcodeCount, setBarcodeCount] = useState(1);
    const [barcodeScanArray, setBarcodeScanArray] = useState([
        {
            orderID: '', dimensionValue: '', ref: createRef()
        }
    ]);

    const [barcodeStatus, setBarcodeStatus] = useState({ message: "", faildCount: "", success: "" });

    const barcodeTextChange = (text, index) => {
        barcodeScanArray[index].orderID = text;
        barcodeScanArray[index].dimensionValue = "";
        setBarcodeScanArray([...barcodeScanArray]);
        getDimentions(text, index)
    }
    const barcodedimensionValueChange = (text, index) => {
        // barcodeScanArray[index].dimensionValue = text;
        // setBarcodeScanArray([...barcodeScanArray]);
        // return;

        let selectedItem = [...barcodeScanArray];
        selectedItem[index].dimensionValue = text;
        let a = [...selectedItem];
        if (selectedItem.length - 1 === index && barcodeCount < 25) {
            selectedItem = selectedItem.map((i, k) => {
                if (k === index && i.orderID !== "") {
                    if (selectedItem[index].orderID.length > 2) {

                        a.push({
                            orderID: "",
                            dimensionValue: "",
                            ref: createRef()
                        })
                    }

                }
            })
        }
        else {
            handleSave(selectedItem)
        }
        setBarcodeCount(() => a.length);
        setBarcodeScanArray([...a])
    }

    const removeChild = (k) => {
        let selectedItem = [...barcodeScanArray];
        if (selectedItem.length !== 1)
            selectedItem.splice(k, 1);
        setBarcodeScanArray([...selectedItem])
    }

    const getDimentions = (value, rowNumber) => {
        const newData = [...barcodeScanArray];

        while (newData.length < rowNumber) {
            newData.push({ orderID: '', dimensionValue: '' });
        }

        newData[rowNumber].orderID = value;

        const isTextLengthSatisfied = value.length > 2;

        if (isTextLengthSatisfied) {
            // setTimeout(() => {
            focusOrderId(rowNumber, value); // Assuming you want to focus on the first row
            // }, 3000);
        }
        else {
            setTimeout(() => {
                // this.focusNextCellInRow(rowNumber);
            }, 5000);
        }

        // setBarcodeScanArray(newData);
    }
    const focusOrderId = async (rowNumber, value) => {
        const targetUrl = 'http://localhost:8080/proxy';// The XML API you're trying to access

        await instance
            .get(targetUrl)
            .then(response => {
                const data = response.data

                // Parse XML data using DOMParser
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data, "text/xml");
                const width = xmlDoc.querySelector('width').textContent;
                const length = xmlDoc.querySelector('length').textContent;
                const height = xmlDoc.querySelector('height').textContent;
                const netwt = xmlDoc.querySelector('netWt').textContent;

                const dimentions = height + 'x' + width + 'x' + length + ': ' + netwt;

                const newData = [...barcodeScanArray];
                newData[rowNumber].orderID = value;
                newData[rowNumber].dimensionValue = dimentions;

                if (rowNumber === newData.length - 1 && newData.length < 25) {
                    newData.push({
                        orderID: "",
                        dimensionValue: "",
                        ref: createRef()
                    })
                    setBarcodeCount(() => newData.length);
                }
                setBarcodeScanArray(newData);
                if (newData.length === 25) {
                    handleSave(newData)
                }

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    }
    // const focusNextInput = useCallback((barcodeScanArray)=> {

    // },[]);

    useEffect(() => {
        console.log(barcodeCount)
        barcodeScanArray[barcodeCount - 1]?.ref?.current?.focus();

    }, [barcodeScanArray, barcodeCount])

    const uploadBarcode = (data = []) => {
        data = data.filter(i => i.orderId !== "" && i.dimensionValue !== "");
        data = data.map(i => {
            delete i.ref;
            return i;
        })
        console.log(data)
        const config = {};
        commonServiceMethod("app/create/manifestByOrdernumbers", "post", { orders: data }, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={'Manifest Created Successfully'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                let message = '', faildCount = 0, succesArray = res?.data?.successmessage || [];
                data.map(i => {
                    if (!succesArray.includes(i.orderID)) {
                        message += i.orderID + ', ';
                        faildCount++;
                    }
                })
                setBarcodeStatus({ message, faildCount, success: res.data })
                setBarcodeScanArray([{
                    orderID: "",
                    dimensionValue: "",
                    ref: createRef()
                }]);
                // setTimeout(() => {
                //     setBarcodeStatus({message:"",faildCount:""})
                // }, 5000);

            }
            else {
                let message = '', faildCount = 0, succesArray = res?.data?.successmessage || [];
                data.map(i => {
                    if (!succesArray.includes(i.orderID)) {
                        message += i.orderID + ', ';
                        faildCount++;
                    }
                })
                setBarcodeStatus({ message, faildCount, success: res.data })

                toast(<ErrorToast body={res.data.message} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            }
        });
    }

    return (
        <>
            <Loader showHide={loader} />
            <Modal
                size='sm'
                show={show === 'CallUpdate'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >
                <div className='modal-content modal-lg'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Update Call Status</h5>
                    </div>
                    <div className="modal-body modal-lg">
                        <label className='userLabel'>Call Status</label>
                        <select className='call-status form-select mb-3'
                            onChange={changeStatus}
                        >
                            <option value="select">Select</option>
                            <option value="accepted">Accepted</option>
                            <option value="declined">Declined</option>
                            <option value="other">Other</option>
                        </select>
                        {!enableDecline ? '' :
                            <>
                                <label className='userLabel'>Reason for Decline?</label>
                                <select className='call-status form-select mb-3'
                                    onChange={changeStatusDecline}
                                >
                                    <option value="Order wrongly placed">Order wrongly placed</option>
                                    <option value="Too expensive">Too expensive</option>
                                    <option value="Changed my mind">Changed my mind</option>
                                    <option value="others">Others</option>
                                </select>
                            </>

                        }

                        {!enableOther ? '' : <>
                            <label className='userLabel'>{!enableDeclineOther ? 'Call Description' : 'Describe Others'}</label>
                            <input type='text' maxLength={30} className='form-control max-length' onChange={inputChange} />
                            <span className='userLabel mb-3'>Max 30 characters</span>
                        </>}

                    </div>

                </div>

                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex justify-content-end">
                        <button className="btn btn-sm btn-dark" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm btn-primary" onClick={() => handleSave(callstatusData, item)} type="submit" disabled={enableSaveBtn === false ? true : false}>Save</button>

                    </div>

                </div>

            </Modal>

            <Modal
                size='sm'
                show={show === 'Call'}
                onHide={handleClose}
                centered
                scrollable
            >
                <div className='modal-content modal-lg'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Make a Call</h5>
                    </div>
                    <div className="modal-body modal-lg">
                        <label className='userLabel'>Dial</label>
                        <input type='text' className='form-control mb-3' value={callData.fromNumber} onChange={(e) => setCallData({ ...callData, fromNumber: e.target.value })} defaultValue={item?.customer_phone} />
                        <hr style={{ color: '#EAECF0', opacity: '1' }} />
                        <Link to={''} className='mb-3 userLabel' onClick={() => { setShowAdvancedToggle(!showAdvancedToggle) }} style={{ color: '#0086C9' }}>
                            <i className={!showAdvancedToggle ? 'bi bi-plus-circle' : 'bi bi-dash-circle'}></i>&nbsp;<span style={{ textDecoration: 'underline' }}>Advanced</span>
                        </Link>
                        <br />
                        {!showAdvancedToggle ? '' :
                            <>
                                <label className='userLabel'>Through</label>
                                <select className='call-status form-select mb-3'

                                >
                                    <option value="cod">COD Verify Group</option>
                                </select>

                                <label className='userLabel'>From</label>
                                <select className="call-status form-select mb-3" onChange={(e) => setCallData({ ...callData, toNumber: e.target.value })}>
                                    <option value="9047315599">9047315599</option>
                                    <option value="9159815599">9159815599</option>
                                    <option value="9655175599">9655175599</option>
                                    <option value="9626635599">9626635599</option>
                                    <option selected value="9514315599">9514315599</option>
                                </select>

                            </>

                        }

                    </div>

                </div>

                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex justify-content-end">
                        <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm btn-primary" onClick={() => handleSave(callData, item)} type="submit" >Call Now</button>

                    </div>

                </div>

            </Modal>
            <Modal
                size='md'
                show={show === 'quickAccess'}
                onHide={handleClose}
                centered
                scrollable
            >
                <form onSubmit={handleSubmit}>
                    <div className='modal-content modal-md'>
                        <div className="modal-header border-bottom-0 justify-content-between">
                            <h5 className="modal-title">Quick Access</h5>
                            {nextQuickAccessModal === true ? (<>
                                <Link to={''} onClick={handleClose}><i className='bi bi-x-lg'></i></Link>
                            </>) : ''}
                        </div>
                        <div className="modal-body modal-md">
                            {nextQuickAccessModal === true ? (<>
                                <Badge className='blueLight d-flex'>
                                    <span>Order ID: 1548</span>&nbsp;&nbsp;
                                </Badge>

                                <div className='mt-3 mb-5'>
                                    <Link to={''} className="quickAccess-btn"><i className="bi bi-box-arrow-up-right" /> Show Label</Link>
                                    <Link to={''} className="quickAccess-btn"><i className="bi bi-box-arrow-up-right" /> Show tracking</Link>
                                    <Link to={''} className="quickAccess-btn"><i className="bi bi-box-arrow-up-right" /> Show order Details</Link>
                                    <Link to={''} className="quickAccess-btn"><i className="bi bi-box-arrow-up-right" /> Show Customer Details</Link>
                                </div>

                                <button className="btn btn-sm cancel-btn" onClick={() => { setNextQuickAccessModal(false) }} type="button"><img src={backArrow} /> Go Back</button> &nbsp;&nbsp;

                            </>) : (<>

                                <label className='userLabel mb-4'>Labels help organize projects.</label>

                                <div className='row mb-3'>
                                    <div className='col-md-3'>
                                        <select className='call-status form-select' name='quickSelect' onChange={handleChange} defaultValue={formData.quickSelect}>
                                            <option value="">Select</option>
                                            <option value="orderID">Order ID</option>
                                            <option value="email">Email</option>
                                            <option value="phoneNumber">Phone number</option>
                                        </select>
                                        {errors.quickSelect && <p className="error">{errors.quickSelect}</p>}
                                    </div>
                                    <div className='col-md-9'>
                                        <div className="form-group">
                                            <div style={{ position: 'relative' }}>
                                                <span className="icon"><img alt='' src={Search} /></span>
                                            </div>
                                            <input
                                                type="text"
                                                id="filter-text-box"
                                                className='form-control inputWithIcon'
                                                placeholder={quickAccessplaceHolders}
                                                name='quickInput'
                                                onChange={handleChange}
                                                value={formData.quickInput}

                                            />
                                            {errors.quickInput && <p className="error">{errors.quickInput}</p>}
                                        </div>
                                    </div>
                                </div>
                                {/* <label className='userLabel mb-3'>Filters</label>
                        <div className='d-flex'>
                            <Badge className='blueLight d-flex'>
                                <span>In Progress</span>&nbsp;&nbsp;
                                <Link to={''}><i className='bi bi-x-lg'></i></Link>
                            </Badge>
                            <Badge className='Indigo d-flex'>
                                <span>Design</span>&nbsp;&nbsp;
                                <Link to={''}><i className='bi bi-x-lg'></i></Link>
                            </Badge>
                            <Badge className='Pink d-flex'>
                                <span>Web</span>&nbsp;&nbsp;
                                <Link to={''}><i className='bi bi-x-lg'></i></Link>
                            </Badge>
                        </div> */}

                            </>)}


                        </div>

                    </div>

                    <div className="modal-footer">

                        <div className="col-12 mb-4 d-flex">
                            {nextQuickAccessModal === true ? (<></>) : (<>
                                <button className="btn btn-sm w-100 cancel-btn" onClick={handleClose} type="button">Clear All</button> &nbsp;&nbsp;
                                <button className="btn btn-sm w-100 btn-primary" type="submit" >Apply</button>
                            </>)}


                        </div>

                    </div>
                </form>

            </Modal>

            <Modal
                size='sm'
                show={show === 'courierPartner'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >

                <div className='modal-content modal-md'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Create Bulk Label</h5>
                    </div>
                    <div className="modal-body modal-md">


                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='userLabel'>Select WareHouse Location</label>
                                <select className='call-status form-select mb-3' value={JSON.stringify(selectedItem || {})} onChange={
                                    (e) => {
                                        let val = JSON.parse(e.target.value);
                                        setSelectedService(val.shipping_carrier_name);
                                        setSelectedItem(val);

                                        // setSelectedServiceList(val.shipping_carrier_name)
                                    }}>
                                    <option value="">Select</option>
                                    {werehouseList.map((i, ind) => <option key={ind} value={JSON.stringify(i)}>{i.name}</option>)}

                                </select>
                            </div>
                            {<div className='col-md-12'>
                                <label className='userLabel'>Select Service</label>
                                <select className='call-status form-select mb-3' value={selectedService} onChange={
                                    (e) => {
                                        setSelectedService(e.target.value)
                                        let item = werehouseList.filter(i => i.shipping_carrier_name === e.target.value);
                                        console.log("item", item[0])
                                        setSelectedItem(item[0])
                                    }}>
                                    <option value="">Select</option>
                                    {selectedServiceList.map((i, ind) => <option key={ind} value={i.services}>{i.services.toUpperCase()}</option>)}

                                </select>
                            </div>}
                        </div>
                    </div>
                </div>

                <div className="modal-footer">

                    <div className="col md-12 mb-4">
                        <div className='row'>
                            <div className='col-md-6'>
                                <button className="btn btn-sm w-100 cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                            </div>
                            <div className='col-md-6'>
                                <button className="btn btn-sm w-100 btn-primary" disabled={selectedItem !== "" && selectedService !== "" ? false : true} type="submit" onClick={() => handleSave(item, selectedItem, selectedService)} >Assign</button>
                            </div>
                        </div>
                    </div>

                </div>

            </Modal>

            <Modal
                size='sm'
                show={show === 'scanawb'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >

                <div className='modal-content modal-md'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Scan/Paste AWBs to Manifest</h5>
                    </div>
                    <div className="modal-body modal-md">


                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='userLabel'>Scan/Paste AWBs to Manifest</label>
                                <textarea className='form-control' style={{ height: '84px', width: '350px' }}></textarea>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex">
                        <button className="btn btn-sm w-100 cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm w-100 btn-primary" type="submit" >Download Manifest</button>

                    </div>

                </div>

            </Modal>

            <Modal
                size='sm'
                show={show === 'cancelPopup'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >

                <div className='modal-content modal-md'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Cancellation Reasons</h5>
                    </div>
                    <div className="modal-body modal-md">


                        <div className='row'>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck1" value='Wrong contact Information' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck1">Wrong contact Information</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck2" value='High Price' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck2">High Price</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck3" value='COD to Prepaid Conversion' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck3">COD to Prepaid Conversion</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck4" value='Unserviceable pin code' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck4">Unserviceable pin code</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck5" value='Ordered twice' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck5">Ordered twice</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck6" value='Merge Orders' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck6">Merge Orders</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck7" value='No reason: Customer wants to cancel' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck7">No reason: Customer wants to cancel</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck8" value='Others' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck8">Others</label>
                            </div>
                            {showOther && <div className='col-md-12 mb-2'>
                                <textarea className='form-control' onChange={e => { setCalcenStatus({ ...calcenStatus, cancel_comments: e.target.value }) }} style={{ height: '84px', width: '350px' }}></textarea>
                            </div>}
                        </div>
                    </div>

                </div>

                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex">
                        <button className="btn btn-sm w-100 cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm w-100 btn-primary" disabled={calcenStatus.cancel_reason === "" || (calcenStatus.cancel_reason === "Others" && calcenStatus.cancel_comments === "")} type="submit" onClick={() => handleSave(calcenStatus, item)}  >Submit</button>
                    </div>

                </div>

            </Modal>

            <Modal
                size='sm'
                show={show === 'returnReason'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
                className='return-request-modal'
            >

                <div className='modal-content modal-md'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Return Reasons</h5>
                    </div>
                    <div className="modal-body modal-md">


                        <div className='row'>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck1" value='Damaged Product' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck1">Damaged Product</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck2" value='Received Wrong Product' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck2">Received Wrong Product</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck3" value='Late Delivery' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck3">Late Delivery</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck4" value='Changed Mind' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck4">Changed Mind</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck5" value='Did Not Meet Expectations' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck5">Did Not Meet Expectations</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck6" value='Item Does Not Fit' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck6">Item Does Not Fit</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck7" value='Product No Longer required' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck7">Product No Longer required</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck8" value='Others' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck8">Others</label>
                            </div>
                            {showOther && <div className='col-md-12 mb-2'>
                                <textarea className='form-control' onChange={e => { setReturnStatus({ ...returnStatus, cancel_comments: e.target.value }) }} style={{ height: '84px', width: '350px' }}></textarea>
                            </div>}
                        </div>
                    </div>

                </div>

                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex">
                        <button className="btn btn-sm w-100 cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm w-100 btn-primary" disabled={returnStatus.returnReason === "" || (returnStatus.returnReason === "Others" && returnStatus.cancel_comments === "")} type="submit" onClick={() => handleSave(returnStatus, item)}  >Submit</button>
                    </div>

                </div>

            </Modal>

            <Modal
                size='sm'
                show={show === 'other-text'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >
                <div className='modal-content modal-lg'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Update Call Status</h5>
                    </div>
                    <div className="modal-body modal-lg">
                        <div className="call-status-popup">
                            <textarea type="text" className="form-control" value={callOtherStatusData} onChange={e => setCallOtherStatusData(e.target.value)} />
                            <div className="buttons">
                                <button className="btn btn-secondary submit" onClick={() => handleClose()}>Cancel</button>
                                <button className="btn btn-primary submit" onClick={() => handleSave({ status: "other", other: callOtherStatusData })}>Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                size='sm'
                show={show === 'make-a-call'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >
                <div className='modal-content modal-lg'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Update Call Status</h5>
                    </div>
                    <div className="modal-body modal-lg">
                        <div className="call-connection-popup">
                            {/* <label>From:</label>
                            <input type="text" className="form-control" value={fromNumber} onChange={e => setFromNumber(e.target.value)} />
                            <label>To:</label>
                            <input type="text" className="form-control" value={toNumber} onChange={e => setToNumber(e.target.value)} />
                             */}
                            <div className="buttons">
                                <button className="btn btn-secondary submit" onClick={() => handleClose()}>Cancel</button>
                                <button className="btn btn-primary submit" onClick={() => handleSave(callData)}>Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                size='md'
                show={show === 'barcdoe-manifest'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
                className='barcode-manifest-popup'
            >
                <div className='modal-content modal-lg'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Barcode Manifest</h5>
                    </div>
                    <div className="modal-body modal-lg">
                        <p className='help-text'> Auto Submission after 25 Products</p>
                        {
                            (barcodeStatus?.message !== "" || barcodeStatus.faildCount !== "" || barcodeStatus?.success !== "") && <>
                                <div className='status-container'>
                                    <p className='success-msg'>Success: {barcodeStatus?.success?.successcount || 0}</p>
                                    <p className='filer-msg'>Faild : {barcodeStatus?.faildCount}</p>
                                </div>
                            </>
                        }
                        <div className="barcode-manifest-body">
                            <div className='table-container'>
                                <Table className='table'>
                                    <thead className='table-head'>
                                        <tr>
                                            <th>#</th>
                                            <th>ORder Id</th>
                                            <th>Dimensions & Weight	</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {barcodeScanArray.map((i, k) => {
                                            return <tr key={k}>
                                                <td> {k + 1}</td>
                                                <td><input id={`input-${k}-1`} ref={i.ref} className='form-control' type='text' onChange={(e) => barcodeTextChange(e.target.value, k)} value={i.orderID} /></td>
                                                <td><input className='form-control' type='text'
                                                    onChange={(e) => barcodedimensionValueChange(e.target.value, k)}
                                                    value={i.dimensionValue} /></td>
                                                <td><button className='btn btn-danger' onClick={() => removeChild(k)}>Clear</button></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </div>

                            <div className=''>
                                {

                                }
                            </div>
                            <div className="buttons">
                                <button className="btn btn-secondary submit me-1" onClick={() => handleClose()}>Cancel</button>
                                <button className="btn btn-primary submit" onClick={() => uploadBarcode(barcodeScanArray)}>Submit</button>
                            </div>
                            {
                                (barcodeStatus?.message !== "" || barcodeStatus.faildCount !== "" || barcodeStatus?.success !== "") && <>
                                    <div className='status-container'>
                                        <p className='filer-msg'>Below Orders are failed to Manifest:<br /> {barcodeStatus?.message}</p>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                size='md'
                show={show === 'fraud-info-popup'}
                onHide={handleClose}
                centered
                backdrop="static"
                className='Fraud-info-popup'
            >
                <div className='modal-content modal-lg'>
                    <div className="modal-header justify-content-center">
                        <h5 className="modal-title">Fraud Analysis</h5>
                    </div>
                    <div className="modal-body modal-lg">
                        <div className='fraud-info'>
                            <p className='title'>Order Quantity Alert</p>
                            <div className="slider-container">
                                <div className="range">
                                    <div className={`range-limt level-${fraudAnalysisData?.order?.alert}`}> </div>
                                </div>
                                <div className="slider-labels">
                                    <span>Low</span>
                                    <span>Medium</span>
                                    <span>High</span>
                                </div>
                                <div className='content'>
                                    <ul>
                                        <li>{item?.customer_name} has {fraudAnalysisData?.order?.quantity} products in the cart worth {fraudAnalysisData?.order?.value}</li>
                                        <li>This is an {fraudAnalysisData?.order?.payment_type?.search("manual") === -1 ? 'COD' : "manual"}</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='fraud-info'>
                            <p className='title'>Frequently Return Alert</p>
                            <div className="slider-container">
                                <div className="range">
                                    <div className={`range-limt level-${fraudAnalysisData?.frequency?.alert}`}> </div>
                                </div>
                                <div className="slider-labels">
                                    <span>Low</span>
                                    <span>Medium</span>
                                    <span>High</span>
                                </div>
                                <div className='content'>
                                    <ul>
                                        <li>{item?.customer_name} placed an order from the city {fraudAnalysisData?.frequency?.city} - {fraudAnalysisData?.frequency?.zipcode}</li>
                                        <li>This is an {fraudAnalysisData?.frequency?.payment_type?.search("manual") === -1 ? 'COD' : "manual"}</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='fraud-info'>
                            <p className='title'>Repeat order alert</p>
                            <div className="slider-container">
                                <div className="range">
                                    <div className={`range-limt level-${fraudAnalysisData?.repeat?.alert}`}> </div>
                                </div>
                                <div className="slider-labels">
                                    <span>Low</span>
                                    <span>Medium</span>
                                    <span>High</span>
                                </div>
                                <div className='content'>
                                    <ul>
                                        <li>{item?.customer_name} previous order is {fraudAnalysisData?.repeat?.order_number} placed at {fraudAnalysisData?.repeat?.order_date} and it is an {fraudAnalysisData?.repeat?.payment_type}</li>
                                        <li>{fraudAnalysisData?.repeat?.order_number} is {fraudAnalysisData?.repeat?.fulfilment_status}</li>
                                        <li>Current Order is {fraudAnalysisData?.repeat?.payment_type?.search("manual") === -1 ? 'COD' : "manual"}</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='fraud-info'>
                            <p className='title'>Cancellation History</p>
                            <div className="slider-container">
                                <div className="range">
                                    <div className={`range-limt level-${fraudAnalysisData?.customerCancel?.alert}`}> </div>
                                </div>
                                <div className="slider-labels">
                                    <span>Low</span>
                                    <span>Medium</span>
                                    <span>High</span>
                                </div>
                                <div className='content'>
                                    <ul>
                                        <li>{item?.customer_name} has the order cancellation percentage of {fraudAnalysisData?.customerCancel?.alert}%</li>
                                        <li>Out of {fraudAnalysisData?.customerCancel?.orderQuantity} orders {fraudAnalysisData?.customerCancel?.cancelQuantity} are cancelled</li>
                                        <li>This is an {fraudAnalysisData?.customerCancel?.payment_type?.search("manual") === -1 ? 'COD' : "manual"} order</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <button className="btn btn-secondary submit me-1" onClick={() => handleClose()}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CallStatus;