import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import './barChart.scss';

const BarChart = ({barChartData}) => {
    const [data, setData] = useState({});
    useEffect(()=>{
        const collator = new Intl.Collator([], {numeric: true});
        const categories = Object.keys(barChartData).sort((a, b) => collator.compare(a, b)) || [];
        const data = {
            series: [{
                data: categories.map((i)=>barChartData[i])
            }],
            options: {
                grid: {
                    show: false, // Hides the grid lines
                  },
                chart: {
                    height: 350,
                    type: 'bar',
                    toolbar: {
                        show: false, // Show the toolbar
                        tools: {
                            download: false, // Hide the download button
                        },
                    },
                    events: {
                        // click: function (chart, w, e) {
                        //     //console.log(chart, w, e)
                        // }
                    }
                },
                colors: new Array(categories.length).fill('#FF8243'),
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: categories,
                    labels: {
                        style: {
                            // colors: new Array(categories.length).fill('#FF8243'),
                            fontSize: '12px'
                        }
                    }
                }
            },
    
    
        }
        setData(data)
        // console.log("barChartData", data)
    },[barChartData])
    return (
        <>
            <div id="chart">
                {JSON.stringify(barChartData) !== "{}" && <ReactApexChart options={data.options || {}} series={data.series || []} type="bar" height={300} />}
            </div>
            <div id="html-dist"></div>
        </>
    )
}
export default BarChart;
