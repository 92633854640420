import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { useSelector } from 'react-redux';
import instance from '../../../../helpers/axiosInstance';
import { Link } from 'react-router-dom';
import Search from '../../../../assets/Iconsearch.svg';
import Calandar from '../../../../assets/Icondate.svg';
import FraudFilter from '../../../../assets/fraud-icon.png';
import AllFilter from '../../../../assets/filter-icon.png';
import DataGridPagination from '../../../global/dataGridPagination';
// import Moment from 'react-moment';
import moment from 'moment';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
// import actionButton from '../../../../assets/action-button.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import FilterMenu from "../../../global/filterComponent/FilterMenu";
import CallStatus from '../../../global/popupModal/CallStatus';
import { filterOptions } from './filterObj';
import './newOrder.scss';
import { commonServiceMethod } from '../../../../helpers/helper';
import Loader from '../../../global/loader';
import SuccessToast from '../../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
// import CloseIcon from '../../../../assets/close -Icon.png';
import { Popup } from '../../../global/popupModal/BottomDialog';
// import refreshIcon from '../../../assets/dashboard/refreshIcon.png';

const DeliveredOrderBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [originalData, setOriginalData] = useState({});
    const [selectedObject, setSelectedObject] = useState([]);
    const [open, setOpen] = useState(false);

    // pagination and Filtr variables 
    const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [loader, setLoader] = useState(false);
    // const [callRowID, setCallRowID] = useState('');
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })
    const [searchParam, setSearchParam] = useState("");
    const [columnData, setComlumnData] = useState([]);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        //console.log(isOpen);
    };


    let columnDefsWithCall = [
        {
            headerName: 'Order Id',
            field: 'order_name',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            width: 180,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {
                // let productNames = param.data.orderProducts;
                //console.log(productNames, param.value)
                return (<div>
                    <Link className='grid-link' to={`/orders/all-order/order-details/${btoa(param.data?.order_id)}`}>{param.value}</Link>
                    {/* <p className='font-insetMedium order-date'><Moment format={"DD MMM YYYY hh:mm A"}>{param.data?.order_created_at}</Moment> </p>
                    <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={
                        <Popover id="popover-basic" className='z-index-2000 produc-list'>
                            <Popover.Body>
                                {productNames?.map((i, j) => <div key={j} className='product-list-container'>
                                    <p className='p-name bold'><b>{i.product_name}</b></p>
                                    <p className='p-sku'>SKU <b>{i.sku}</b></p>
                                    <p className='p-qty'>QTY <b>{i.quantity}</b></p>
                                </div>)}
                            </Popover.Body>
                        </Popover>
                    }>
                        <p className='viewMore'>
                            View Products
                        </p>
                    </OverlayTrigger> */}
                </div>)
            }
        },
        
        {
            width: 200,
            field: 'shipping_address',
            wrapText: true,
            autoHeight: true,
            headerName: 'Recipient',
            cellClass: "d-flex-center customers",
            cellRenderer(params) {
                let address = params.data?.shipping_address;
                return (
                    <div className="address-wrap">
                        <p className="customer-name">
                            {params.data?.customer_name}
                        </p>
                        <p className="address">
                            {params.data?.customer_email}
                        </p>
                        <p className="address">
                            {address.phone ? address.phone : ''}
                        </p>
                        <p className="address">
                            {address.city ? address.city : ''} - {address.zip ? address.zip : ''}
                        </p>
                    </div>
                )
            }
        },
        {
            headerName: 'Order Total',
            field: 'total_price',
            width: 150,
            cellClass: "d-flex-center",
            cellRenderer(params) {
                let payment_mode = "";
                let payment_status = "";
                if (params.data?.gateway === "Cash on Delivery (COD)") {
                    payment_mode = "COD";
                    payment_status = "info";
                } else {
                    payment_mode = "Pre-paid";
                    payment_status = "success";
                }
                return (
                    <div className='address-wrap'>
                        <p className="price_tag">{"Rs. " + params.value}</p>
                        <p className="price_tag">
                            <span className={payment_status}>{payment_mode}</span>
                        </p>
                    </div>
                )
            }
        },
        {
            width: 100,
            field: 'order_created_at',
            wrapText: true,
            autoHeight: true,
            headerName: 'Order Date',
            cellClass: 'order-date',
            cellRenderer(param) {
                return (<span className={`order-date`}>{moment(param.value).format("MMM DD, YYYY hh:mm")}</span>)
            }
        },
        {
            field: 'carrier_name',
            width: 200,
            wrapText: true,
            autoHeight: true,
            headerName: 'Carrier',
            cellClass: "carrier-data",
        },
       
        {
            width: 450,
            field: 'product_name',
            wrapText: true,
            autoHeight: true,
            headerName: 'Item Name',
            cellClass: 'product-name',
            cellRenderer(param) {
                return (<span title={param.value} className={`status-value ${param.value}`}>{param.value}</span>)
            }
        },
    ];

    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
            setComlumnData([...columnDefsWithCall]);
        }
    }, [userClaim]);

    const onClearFilter = () => {
        setSearchText("");
        setSearchParam("");
        toggleDropdown();
        getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), "","");
    }
    const showModal = (popupName, data, callBackMethod) => {

        // //console.log(string, value);
        // setCallDetails(string);
        // setCallRowID(value)
        setPopupData({ popupName, data, callBackMethod })
    }

    const closeModal = () => {
        // setCallDetails('');
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }

    const getData = async (
        page = 1,
        startDate,
        endDate,
        searchText,
        perams = searchParam
    ) => {
        var rowData = [];
        setLoader(true);
        if (searchText !== "") {
            if (/^\d{10}$/.test(searchText)) {
                searchText += '&orderDetails=m'
            }
            else if (searchText.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                searchText += '&orderDetails=e'
            }
            else {
                searchText += '&orderDetails='
            }
        }
        try {
            await instance.get(`app/order/getOrder?per_page=${per_page}&page=${page}&order_date=${startDate}&end_order_date=${endDate}&search=${searchText}&order_status=Delivered${perams}`).then((res) => {

                //console.log(res);
                if (res.status === 200 && Array.isArray(res?.data?.orders)) {
                    setRowData(res?.data?.orders || []);
                    setOriginalData(res?.data)
                }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        //console.log(selectedITem.length > 0)
        // setSelectedObject((selectedObject) => [...selectedObject]);
        selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setSelectedObject([]));
    }
    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        getData(
            pageNo,
            date.startDate.format('YYYY-MM-DD'),
            date.endDate.format('YYYY-MM-DD'),
            searchText,
            searchParam
        )
    }
    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText,
            searchParam
        );
    }

    const onApplyFilter = (selectionOption, searchTextval = "") => {
        //console.log(selectionOption)
        let url = "";
        Object.keys(selectionOption).map(i => {

            url += `&${i}=${selectionOption[i]}`
        })
        setSearchParam(url);
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchTextval || searchText,
            url
        );
        toggleDropdown();
    }

    const closeExportPopup = () => {
        setOpen(false);
        setSelectedObject([])
        gridRef.current.api.deselectAll();
    }
    const exportMethod = () => {
        var objdata = {};
        const config = {};
        objdata.order_id = selectedObject.map((i) => i.order_id).join();
        commonServiceMethod("app/order/export", "post", objdata, config, setLoader, (res) => {
            //console.log("res", res)
            const downloadUrl = window.URL.createObjectURL(
                new Blob([res.data])
            );
            //console.log(downloadUrl)
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", "file.csv"); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            closeExportPopup();
        });
    }
    const printInvoiceMethod = (obj = []) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        var objdata = "";
        objdata = obj.map((i) => i.order_id).join();
        commonServiceMethod(`app/order/print-invoice/${objdata}?templateName=temp1`, "post", {}, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
            closeExportPopup();
        });
    }

    const cancelOrder = (obj, item) => {
        const config = {};
        commonServiceMethod("app/orders/cancel", "post", obj, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={'This order is ' + item.order_name + ' cancelled'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText, searchParam);
                closeModal();
                closeExportPopup();
            }
        });
    }
    // const gridReadyEvent = () =>{
    //     let allColumnIds = [];
    //     gridRef.current.columnApi.getColumns().forEach((column) => {
    //         allColumnIds.push(column.getId());
    //     });
    //     gridRef.current.columnApi.autoSizeColumns(allColumnIds, true);
    // }
    return (
        <>
            <div className='order-tabs'>

                <Loader showHide={loader} />
                <div className='quick-buttons'>
                    {/* <Link to={'/customers'} className='quickBtn' >Customer</Link>&nbsp;
                <Link to={'/products'} className='quickBtn' >Product</Link>&nbsp; */}

                    {/* {checkAccess(10) && <button className='quickBtn' onClick={() => showModal('quickAccess', 'quickAccess', quickAccessMethod)}> <img src={gridIcon} className="gridIcon"></img> Quick Actions</button>}
                <button className='syncBtn'> <img src={refreshIcon} className="refreshIcon"></img> Sync Orders</button> */}

                    {/* <button className='syncBtn' onClick={() => showModal('courierPartner', 'courierPartner')}>Assign Courier Partner</button>
                <button className='syncBtn' onClick={() => showModal('scanawb', 'scanawb')}>Scan/Paste AWBs</button> */}
                </div>
                {open ? <Popup className='all-order' exportMethod={exportMethod} printInvoiceMethod={printInvoiceMethod} cancelMethod={() => showModal('cancelPopup', selectedObject, cancelOrder)} selecteObject={selectedObject} closePopup={() => { closeExportPopup(); }} /> : null}
                <div className='row filter-section'>
                    <div className="filter-left-container">
                        <div className="form-group">
                            <form onSubmit={searchTextChangeEvent}>
                                <div style={{ position: 'relative' }}>
                                    <span className="icon"><img alt='' src={Search} /></span>
                                </div>
                                <input
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Search"
                                    className='form-control inputWithIcon'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>
                    <div className='filter-right-container'>
                        <div className='dropdown-dialog-parent me-2'>
                            <div className={` dropdown-dialog ${isOpen ? 'open' : ''}`}>
                                <button type='button' className='btn btn-sm  filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 17 }} src={AllFilter} /> Filters</button>
                                <FilterMenu show={isOpen} filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                            </div>
                        </div>
                        <div className="form-group datepicker-class me-4">
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Calandar} /></span>
                            </div>
                            <DateRangePicker
                                className="datepicker-custom"
                                initialSettings={
                                    {
                                        startDate: startDate,
                                        endDate: endDate,
                                        maxDate: moment().toDate(),
                                        showDropdowns: true,
                                        locale: {
                                            format: 'MMM DD, YYYY',
                                        },
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, 'days').toDate(),
                                                moment().subtract(1, 'days').toDate(),
                                            ],
                                            'Last 7 Days': [
                                                moment().subtract(6, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'Last 30 Days': [
                                                moment().subtract(30, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'This Month': [
                                                moment().startOf('month').toDate(),
                                                moment().endOf('month').toDate(),
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                            ],
                                        },
                                    }}
                                onApply={(e, val) => dateChangeEvent(val, e)}
                            >
                                <input type="text" className="form-control datepicker-range" />
                            </DateRangePicker>
                        </div>
                        <div className='dropdown-dialog-parent'>
                            <div className={` dropdown-dialog ${isOpen ? 'open' : ''}`}>
                                <button type='button' className='btn btn-sm  filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 17 }} src={FraudFilter} /> Fraud Filter</button>
                                {isOpen && (
                                    <>
                                        <FilterMenu filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <p className='total-count'>Total Count: {originalData?.count}</p>
                </div >
                <DataGridPagination
                    rowData={rowData}
                    columnDefs={columnData}
                    onSelectionChanged={onCellClick}
                    gridref={gridRef}
                    rowSelection={"multiple"}
                    domLayout="autoHeight"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={originalData.per_page}
                    pageCount={originalData.count}
                />
                {popupData.popupName !== "" &&
                    <CallStatus
                        item={popupData.data}
                        show={popupData.popupName}
                        handleSave={popupData.callBackMethod}
                        handleClose={closeModal}
                    />
                }

            </div>
        </>
    );
}

export default DeliveredOrderBody;