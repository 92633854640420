export const filterOptions = [
    {
        "title": "Order Details",
        "label": [
            {
                "id": 1,
                "menu": 'Order Number',
                "value":"N",
                "key":"orderDetails"

            },
            {
                "id": 2,
                "menu": 'AWB Number',
                "value": "A" ,
                "key":"orderDetails"
            },
            {
                "id": 1,
                "menu": 'Mobile Number',
                "value":"M",
                "key":"orderDetails"

            },
            {
                "id": 2,
                "menu": 'Email',
                "value": "E" ,
                "key":"orderDetails"
            },
        ]
    }, 
    {
        "title": "Payment Type",
        "label": [
            {
                "id": 1,
                "menu": 'COD',
                "value":"COD",
                "key":"payment_type"

            },
            {
                "id": 2,
                "menu": 'Prepaid',
                "value": "Pre-paid" ,
                "key":"payment_type"
            },
        ]
    },
    {
        "title": "Fulfilment Status",
        "label": [
            {
                "id": 1,
                "menu": 'Fulfilled',
                "value":"Fulfilled",
                "key":"fulfillment"

            },
            {
                "id": 2,
                "menu": 'Unfulfilled',
                "value":"Unfulfilled",
                "key":"fulfillment"
            },
        ]
    },
    {
        "title": "Order Status",
        "label": [
            {
                "id": 1,
                "menu": 'Unlabel',
                value:"Unlabel",
                key: "order_status",

            },
            {
                "id": 2,
                "menu": 'Label',
                value:"Label",
                key: "order_status",

            },
            {
                "id": 3,
                "menu": 'In Progress',
                value:"In Progress",
                key: "order_status",

            },
            {
                "id": 4,
                "menu": 'Printed',
                value:"Printed",
                key: "order_status",

            },
            {
                "id": 5,
                "menu": 'Manifest',
                value:"Manifest",
                key: "order_status",

            },
            {
                "id": 6,
                "menu": 'Dispatched',
                value:"Dispatched",
                key: "order_status",

            },
            {
                "id": 7,
                "menu": 'Delivered',
                value:"Delivered",
                key: "order_status",

            },
            {
                "id": 8,
                "menu": 'Cancel',
                value:"Cancel",
                key: "order_status",

            }, 
            {
                "id": 9,
                "menu": 'Refunded',
                value:"Refunded",
                key: "order_status",

            }, 
        ]
    },
    {
        "title": "Carrier",
        "label": [ {
            "id": 1,
            "menu": 'Bluedart',
            value:"Bluedart",
            key: "carrier",

        },
        {
            "id": 2,
            "menu": 'Delhivery',
            value:"Delhivery",
            key: "carrier",

        },
        {
            "id": 3,
            "menu": 'DTDC',
            value:"DTDC",
            key: "carrier",

        },
        {
            "id": 4,
            "menu": 'Ekart',
            value:"Ekart",
            key: "carrier",

        },
        {
            "id": 5,
            "menu": 'DHL',
            value:"DHL",
            key: "carrier",

        },
        {
            "id": 6,
            "menu": 'Other',
            value:"Other",
            key: "carrier",
        }]
    },
    // {
    //     "title": "Call Status",
    //     "label": [
    //         {
    //             "id": 1,
    //             "menu": 'Accepted',
    //             value:"Accepted",
    //             key: "callStatus",
    
    //         },
    //         {
    //             "id": 2,
    //             "menu": 'Declined',
    //             value:"Declined",
    //             key: "callStatus",
    
    //         },
    //         {
    //             "id": 3,
    //             "menu": 'Other',
    //             value:"Other",
    //             key: "callStatus",
    
    //         }
    //     ]
    // }
]