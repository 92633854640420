import React from 'react';
import PageLayout from '../../../global/partialBase/pageLayout';
import UnLabledBody from './body';

const UnLabled = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<UnLabledBody />} selectedItem = "Unlabelled Orders" />
        </div>
    )
}

export default UnLabled;