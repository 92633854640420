import React from 'react';
import PageLayout from '../../global/partialBase/pageLayout';
import SettingBody from './settingsBody';

const Settings = () => {
    return (
        <div>
            <PageLayout tabMenu={false} body={<SettingBody />} />
        </div>
    )
}

export default Settings;