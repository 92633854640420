import React, { useState } from 'react';
import './orderTracking.scss';
import './banner-deyga-image.png'
import ErrorToast from '../../customToast/ErrorToast';
import { useNavigate } from "react-router-dom";
import Loader from '../../global/loader';
import instance from '../../../helpers/axiosInstance';
import { Slide, toast } from 'react-toastify';

//var textStatus = 'no';

const OrderTracking = () => {

    const [email, setEmail] = useState('');
    const [orderId, setOrderId] = useState('');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const handleOrderIdChange = (e) => {
        setOrderId(e.target.value);
    };

    const handleEmailIdChange = (e) => {
       setEmail(e.target.value);
    };

    const trackFormSubmit = async () => {
        let url = `/order/status`;
        let obj = {};
        obj.emailId = email;
        obj.orderNumber = orderId;
        try {
            setLoader(true);
            await instance.post(url, obj).then((res) => {
                console.log(res);
                if (res.status === 200) {
                    console.log(res);
                    if(res?.data.message != null){
                        toast(<ErrorToast body={'Order Not found please check email Id and order number.'} />, {
                            transition: Slide,
                            hideProgressBar: true,
                            autoClose: 2000,
                        });
                    }else{
                        navigate("details", { state: { status: res?.data.status, orderNumber: orderId,emailid:email,orderDate: res?.data.orderDate } });
                    }
                }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
        }
    }


    return (
        <>
            <section className="track-order-sec">
                <Loader showHide={loader} />
                <div className="page-width">
                    <h2 className="title inline-richtext h0">Track Your Order</h2>
                    <h5>Please enter your email id & order number</h5>
                    <div className="track-card">
                        <div className="track-card-img">
                            <img src="https://cdn.shopify.com/s/files/1/0034/7901/1441/files/image_a9f49919-ccf5-40ce-b737-1af19a243740.png?v=1737982054" alt="" />
                        </div>
                        <div className="track-card-text">
                            <form className="track-order-form">
                                <div className="input-group">
                                    <label htmlFor="email" style={{width:'95%'}}>
                        <span className="icon"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8333 1.66492H4.16667C3.062 1.66624 2.00296 2.10565 1.22185 2.88677C0.440735 3.66788 0.00132321 4.72692 0 5.83158L0 15.8316C0.00132321 16.9362 0.440735 17.9953 1.22185 18.7764C2.00296 19.5575 3.062 19.9969 4.16667 19.9983H15.8333C16.938 19.9969 17.997 19.5575 18.7782 18.7764C19.5593 17.9953 19.9987 16.9362 20 15.8316V5.83158C19.9987 4.72692 19.5593 3.66788 18.7782 2.88677C17.997 2.10565 16.938 1.66624 15.8333 1.66492ZM4.16667 3.33158H15.8333C16.3323 3.33257 16.8196 3.48285 17.2325 3.76309C17.6453 4.04333 17.9649 4.44071 18.15 4.90408L11.7683 11.2866C11.2987 11.7543 10.6628 12.017 10 12.017C9.33715 12.017 8.70131 11.7543 8.23167 11.2866L1.85 4.90408C2.03512 4.44071 2.35468 4.04333 2.76754 3.76309C3.1804 3.48285 3.66768 3.33257 4.16667 3.33158ZM15.8333 18.3316H4.16667C3.50363 18.3316 2.86774 18.0682 2.3989 17.5994C1.93006 17.1305 1.66667 16.4946 1.66667 15.8316V7.08158L7.05333 12.4649C7.83552 13.2451 8.89521 13.6833 10 13.6833C11.1048 13.6833 12.1645 13.2451 12.9467 12.4649L18.3333 7.08158V15.8316C18.3333 16.4946 18.0699 17.1305 17.6011 17.5994C17.1323 18.0682 16.4964 18.3316 15.8333 18.3316Z" fill="#999999"/>
                            </svg>
                            </span>
                                        <input type="email" id="email" placeholder="Enter email id" value={email} onChange={handleEmailIdChange} required />
                                    </label>
                                </div>
                                <div className="input-group">
                                    <label htmlFor="order-number" style={{width:'95%'}}>
                        <span className="icon"><svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.86328 1.9679L2.86328 19.6952" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19.1367 1.9679L14.1367 19.6952" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2.77344 6.05884H20.5007" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1.5 15.1498H19.2273" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </span>
                                        <input type="text" id="order-number" placeholder="Enter order number" value={orderId} onChange={handleOrderIdChange} required />
                                    </label>
                                </div>
                                <button type="button" className="track-button" onClick={trackFormSubmit}>Track Now</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
  }
export default OrderTracking