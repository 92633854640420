
/* eslint-disable */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import initialState from './initialState';

import { API_URL_LIVE, API_URL_STAGING } from './../constants';
import instance from '../helpers/axiosInstance';

let API_URL = ''
if (window.location.href.search("staging.deyga.in") > -1) {
  // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_STAGING;
}
else if (window.location.href.search("shipping.deyga.in") > -1) {
  // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_LIVE;
}
else {
  // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_STAGING;
}

const getChartDatas = createAsyncThunk(
  'chartData/getChartDatas',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/dashbordapi?startdate=${params.startDate}&enddate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getdDeliveryAttempts = createAsyncThunk(
  'chartData/getdDeliveryAttempts',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/dashboard-chart-delivery-attempts?fromDate=${params.startDate}&toDate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getdDeliveryAttemptsTable = createAsyncThunk(
  'chartData/getdDeliveryAttemptsTable',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/dashboard-table-delivery-attempts?fromDate=${params.startDate}&toDate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getdShipmentOverview = createAsyncThunk(
  'chartData/getdShipmentOverview',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/dashboard-shipment-overview`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getdShipmentDelivered = createAsyncThunk(
  'chartData/getdShipmentDelivered',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/dashboard-chart-shipment-delivered?startdate=${params.startDate}&enddate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getdShipmentDeliveredTable = createAsyncThunk(
  'chartData/getdShipmentDeliveredTable',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/dashboard-table-shipment-delivered?startdate=${params.startDate}&enddate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getOrdersFromTopCities = createAsyncThunk(
  'chartData/getOrdersFromTopCities',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/top-city-orders?fromDate=${params.startDate}&toDate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getOrdersCourierSplit = createAsyncThunk(
  'chartData/getOrdersCourierSplit',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/orders-by-delivery-partners?fromDate=${params.startDate}&toDate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getOverallShipmentStatus = createAsyncThunk(
  'chartData/getOverallShipmentStatus',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/order-count-by-order-status?fromDate=${params.startDate}&toDate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getCancelledReasons = createAsyncThunk(
  'chartData/getCancelledReasons',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/order-count-by-cancel-reason?fromDate=${params.startDate}&toDate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getZoneWiseTatPerformance = createAsyncThunk(
  'chartData/getZoneWiseTatPerformance',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/zone-wise-tat-performance?fromDate=${params.startDate}&toDate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const getZoneWiseTatPerformanceTable = createAsyncThunk(
  'chartData/getZoneWiseTatPerformanceTable',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/zone-wise-tat-performance-table?fromDate=${params.startDate}&toDate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);


const getZoneWisePerformance = createAsyncThunk(
  'chartData/getZoneWisePerformance',
  async (params, ThunkAPI) => {
    try {
      let response = await instance.get(API_URL + `app/zone-wise-performance?fromDate=${params.startDate}&toDate=${params.endDate}`);
      return response;
    } catch (error) {
      ThunkAPI.rejectWithValue(error);
    }
  },
);

const chartDataSlice = createSlice({
  name: 'chartData',
  initialState: initialState.chartData,
  reducers: {
    setloader(state, action) {
      state.loader = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChartDatas.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getChartDatas.fulfilled, (state, action) => {
      state.loader = false;
      state.data = action?.payload?.data || {};
    })
    builder.addCase(getChartDatas.rejected, (state, action) => {
      state.loader = false;
      state.data = initialState.chartData;
    })
    builder.addCase(getdDeliveryAttempts.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getdDeliveryAttempts.fulfilled, (state, action) => {
      state.loader = false;
      state.deliveryAttempts = action?.payload?.data || [];
    })
    builder.addCase(getdDeliveryAttempts.rejected, (state, action) => {
      state.loader = false;
      state.deliveryAttempts = initialState.chartData.deliveryAttempts;
    })
    builder.addCase(getdDeliveryAttemptsTable.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getdDeliveryAttemptsTable.fulfilled, (state, action) => {
      state.loader = false;
      state.deliveryAttemptsTable = action?.payload?.data || [];
    })
    builder.addCase(getdDeliveryAttemptsTable.rejected, (state, action) => {
      state.loader = false;
      state.deliveryAttemptsTable = initialState.chartData.deliveryAttemptsTable;
    })
    builder.addCase(getdShipmentOverview.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getdShipmentOverview.fulfilled, (state, action) => {
      state.loader = false;
      state.shipmentOverview = action?.payload?.data || [];
    })
    builder.addCase(getdShipmentOverview.rejected, (state, action) => {
      state.loader = false;
      state.shipmentOverview = initialState.chartData.shipmentOverview;
    })
    builder.addCase(getdShipmentDelivered.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getdShipmentDelivered.fulfilled, (state, action) => {
      state.loader = false;
      state.shipmentDelivered = action?.payload?.data || [];
    })
    builder.addCase(getdShipmentDelivered.rejected, (state, action) => {
      state.loader = false;
      state.shipmentDelivered = initialState.chartData.shipmentDelivered;
    })
    builder.addCase(getdShipmentDeliveredTable.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getdShipmentDeliveredTable.fulfilled, (state, action) => {
      state.loader = false;
      state.shipmentDeliveredTable = action?.payload?.data || [];
    })
    builder.addCase(getdShipmentDeliveredTable.rejected, (state, action) => {
      state.loader = false;
      state.shipmentDeliveredTable = initialState.chartData.shipmentDeliveredTable;
    })
    builder.addCase(getOrdersFromTopCities.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getOrdersFromTopCities.fulfilled, (state, action) => {
      state.loader = false;
      state.ordersFromTopCities = action?.payload?.data || [];
    })
    builder.addCase(getOrdersFromTopCities.rejected, (state, action) => {
      state.loader = false;
      state.ordersFromTopCities = initialState.chartData.ordersFromTopCities;
    })
    builder.addCase(getOrdersCourierSplit.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getOrdersCourierSplit.fulfilled, (state, action) => {
      state.loader = false;
      state.ordersCourierSplit = action?.payload?.data || [];
    })
    builder.addCase(getOrdersCourierSplit.rejected, (state, action) => {
      state.loader = false;
      state.ordersCourierSplit = initialState.chartData.ordersCourierSplit;
    })
    builder.addCase(getOverallShipmentStatus.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getOverallShipmentStatus.fulfilled, (state, action) => {
      state.loader = false;
      state.overallShipmentStatus = action?.payload?.data || [];
    })
    builder.addCase(getOverallShipmentStatus.rejected, (state, action) => {
      state.loader = false;
      state.overallShipmentStatus = initialState.chartData.overallShipmentStatus;
    })
    builder.addCase(getCancelledReasons.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getCancelledReasons.fulfilled, (state, action) => {
      state.loader = false;
      state.cancelledReasons = action?.payload?.data || [];
    })
    builder.addCase(getCancelledReasons.rejected, (state, action) => {
      state.loader = false;
      state.cancelledReasons = initialState.chartData.cancelledReasons;
    })
    builder.addCase(getZoneWiseTatPerformance.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getZoneWiseTatPerformance.fulfilled, (state, action) => {
      state.loader = false;
      state.zoneWiseTatPerformance = action?.payload?.data || [];
    })
    builder.addCase(getZoneWiseTatPerformance.rejected, (state, action) => {
      state.loader = false;
      state.zoneWiseTatPerformance = initialState.chartData.zoneWiseTatPerformance;
    })
    builder.addCase(getZoneWiseTatPerformanceTable.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getZoneWiseTatPerformanceTable.fulfilled, (state, action) => {
      state.loader = false;
      state.zoneWiseTatPerformanceTable = action?.payload?.data || [];
    })
    builder.addCase(getZoneWiseTatPerformanceTable.rejected, (state, action) => {
      state.loader = false;
      state.zoneWiseTatPerformanceTable = initialState.chartData.zoneWiseTatPerformanceTable;
    })
    builder.addCase(getZoneWisePerformance.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getZoneWisePerformance.fulfilled, (state, action) => {
      state.loader = false;
      state.zoneWisePerformance = action?.payload?.data || [];
    })
    builder.addCase(getZoneWisePerformance.rejected, (state, action) => {
      state.loader = false;
      state.zoneWisePerformance = initialState.chartData.zoneWisePerformance;
    })
  },
});

export const chartDataThunks = {
  getChartDatas,
  getdDeliveryAttempts,
  getdShipmentOverview,
  getdShipmentDelivered,
  getdShipmentDeliveredTable,
  getdDeliveryAttemptsTable,
  getOrdersFromTopCities,
  getOrdersCourierSplit,
  getOverallShipmentStatus,
  getCancelledReasons,
  getZoneWiseTatPerformance,
  getZoneWiseTatPerformanceTable,
  getZoneWisePerformance
};

export const chartDataActions = chartDataSlice.actions;
export default chartDataSlice.reducer;
