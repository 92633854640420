import React, { useEffect, useState } from "react";
import backArrow from '../../../../assets/back-button.png';
import '../../orders/orderDetails/orderDetails.scss';
import './userDetails.scss';
import Loader from "../../../global/loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import instance from "../../../../helpers/axiosInstance";
import SuccessToast from "../../../customToast/SuccessToast";
import { Slide, toast } from "react-toastify";
import { commonServiceMethod } from "../../../../helpers/helper";
import ErrorToast from "../../../customToast/ErrorToast";
// import SuccessToast from "../../customToast/SuccessToast";


const UserDetailsBody = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [userMode, setMode] = useState(false);
    const [userDetails, setUSerDetails] = useState(id ? JSON.parse(sessionStorage.getItem("d-user-details")) : {
        // "id": 9,
        // "app_store_id": "3834591704",
        // "user_id": "7",
        // "user_name": "deyga-testing-store.myshopify.com",
        // "full_name": "Deyga Testing Store",
        "email": "",
        "permissions": null,
        "restrictions": null,
        "is_active": "Y",
        "is_deleted": "N",
        "role_id": "",
        "first_name": "",
        "last_name": "",
        "phone_number": null,
    });
    const [loader, setLoader] = useState(false);
    const [moduleList, setModuleList] = useState([]);
    const [permission, setPermission] = useState([]);

    useEffect(() => {
        getUserRoleLsit();
        if (id === undefined) {
            setMode(true)
        }
    }, [])


    const getPermissionList = async () => {
        try {
            instance.get("app/getPermissionbyid?id=" + id).then((res) => {

                if (res.status === 200) {
                    setPermission(res.data.map(i => i.module_id));
                }
                else {
                    setPermission([]);
                }
                setLoader(false);
            }).catch(e => {
                console.error(e);
                setModuleList([]);
                setLoader(false);
            })
        }
        catch (e) {
            console.error(e);
            setModuleList([]);
            setLoader(false);
        }
    }
    const getUserRoleLsit = async () => {
        setLoader(true);
        try {
            instance.get("app/getModuleList").then((res) => {

                if (res.status === 200 && Array.isArray(res.data)) {
                    setModuleList(res.data);
                    id ? getPermissionList() : setLoader(false);
                }

            }).catch(e => {
                console.error(e);
                setModuleList([]);
                setLoader(false);
            })
        }
        catch (e) {
            console.error(e);
            setModuleList([]);
            setLoader(false);
        }
    }
    const setPermissionValues = (e) => {
        let value = parseInt(e.value);
        if (e.checked) {
            permission.push(parseInt(value));
            setPermission([...permission]);
        }
        else {
            setPermission([...permission.filter((l) => l !== value)]);
        }
    }

    const suspendAccess = () => {
        setLoader(true);
        try {
            instance.get("app/updateaccountStatus?id=" + id).then((res) => {

                if (res.status === 200) {
                    toast(<SuccessToast body={'Account Re-Activated'} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }

            }).catch(e => {
                console.error(e);
                setModuleList([]);
                setLoader(false);
            })
        }
        catch (e) {
            console.error(e);
            setModuleList([]);
            setLoader(false);
        }
    }

    const deleteAccess = () => {
        setLoader(true);
        try {
            instance.get("app/deleteaccountStatus?id=" + id).then((res) => {

                if (res.status === 200) {
                    toast(<SuccessToast body={'Account deleted successfully'} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });

                }

            }).catch(e => {
                console.error(e);
                setModuleList([]);
                setLoader(false);
            })
        }
        catch (e) {
            console.error(e);
            setModuleList([]);
            setLoader(false);
        }
    }
    const updatePermissions  = async () =>{
        const obj = {
            "userId": id,
            "modules": [...permission]
        }
        await commonServiceMethod(`app/setpermission`, "POST", obj, {}, setLoader, (res) => {
            if (res.status === 200) {
                if (res.data?.message !== "") {
                    toast(<SuccessToast body={res.data?.message} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }
                else {
                    toast(<ErrorToast body={res.data?.error} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }

            }

        })
    }
    const createUSer = async () => {
        console.log(userDetails, permission)
        const obj = {
            "userName": userDetails.email,
            "phoneNumber": userDetails.phoneNumber,
            "firstName": userDetails.first_name,
            "lastName": userDetails.last_name,
            "roleId": userDetails.role_id,
            "modules": [...permission]
        }
        await commonServiceMethod(`app/createuser`, "POST", obj, {}, setLoader, (res) => {
            if (res.status === 200) {
                if (res.data?.success !== "") {
                    toast(<SuccessToast body={res.data?.success} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                    navigate(-1)
                }
                else {
                    toast(<ErrorToast body={res.data?.error} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }

            }

        })
    }

    return <>
        <Loader showHide={loader} />
        <div className="user-details-page order-detail-page page-container pos-relative">
            <div className="inner-container">
                <div className="header d-flex">
                    <Link className="back" onClick={() => navigate(-1)}>
                        <img src={backArrow} />
                    </Link>
                    <h1 className='order-number' >{!userMode? `${userDetails?.first_name} ${userDetails?.last_name} - Details` : "Add User" }</h1>
                </div>
                <div className="detail-container">
                    <div className="left-container d-flex">
                        <div className={`order-items mb-3 ${userMode && 'user-add'}`}>
                            <h3 className="con-title">Primary Information</h3>
                            <div className="item-list">
                                <label className="label">User ID</label>
                                
                                {!userMode ?
                                    <label className="value">#{id}</label>
                                    :
                                    <input type="text" readOnly placeholder="auto generated"  disabled className="form-control user-input" value={userDetails?.id} />
                                }
                            </div>
                            <div className="item-list">
                                <label className="label">Email Address</label>
                                {!userMode ?
                                    <label className="value">{userDetails?.email}</label>
                                    :
                                    <input type="text" onChange={(e) => setUSerDetails({ ...userDetails, email: e.target.value })} className="form-control user-input" value={userDetails?.email} />
                                }
                            </div>
                            <div className="item-list">
                                <label className="label">Phone Number</label>

                                {!userMode ?
                                    <label className="value">{userDetails?.phone_number || "-"}</label>
                                    :
                                    <input type="text" className="form-control user-input" onChange={(e) => setUSerDetails({ ...userDetails, phone_number: e.target.value })} value={userDetails?.phone_number} />
                                }
                            </div>
                            {userMode &&
                                <>
                                    <div className="item-list">
                                        <label className="label">First Name</label>

                                        {!userMode ?
                                            <label className="value">{userDetails?.first_name || "-"}</label>
                                            :
                                            <input type="text" className="form-control user-input" onChange={(e) => setUSerDetails({ ...userDetails, first_name: e.target.value })} value={userDetails?.first_name} />
                                        }
                                    </div>
                                    <div className="item-list">
                                        <label className="label">Last Name</label>

                                        {!userMode ?
                                            <label className="value">{userDetails?.last_name || "-"}</label>
                                            :
                                            <input type="text" className="form-control user-input" onChange={(e) => setUSerDetails({ ...userDetails, last_name: e.target.value })} value={userDetails?.last_name} />
                                        }
                                    </div>
                                </>

                            }
                            <div className="item-list">
                                <label className="label">Role</label>

                                {!userMode ?
                                    <label className="value">{userDetails?.role_id === 1 ? "Administrator" : "Staf"}</label>
                                    :
                                    <select className="form-control user-input" value={userDetails?.role_id} onChange={(e) => setUSerDetails({ ...userDetails, role_id: e.target.value })} >
                                        <option value={""}>Select</option>
                                        <option value={1}>Administrator</option>
                                        <option value={2}>Staff</option>
                                    </select>
                                }
                            </div>
                        </div>
                        <div className="order-summary mb-3">
                            <div className="order-items">
                                <h3 className="con-title">Permissions </h3>
                                <div className="permisstion-list">
                                    <div className="row">
                                        {
                                            moduleList.map((i, index) => (
                                                <div key={index} className='permisstion-item col-md-5'>
                                                    <div className="form-check mb-3">
                                                        <input value={i.id} type="checkbox" onChange={(e) => { setPermissionValues(e.target) }} checked={permission?.indexOf(i.id) > -1} className="form-check-input" id={'validationFormCheck' + index} />
                                                        <label className="form-check-label " htmlFor={'validationFormCheck' + index}>{i.module_name}</label>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                {!userMode &&
                                    <div className="text-right">
                                        <button onClick={()=>updatePermissions()} className="btn permission-btn green-btn">Update Permissions</button>
                                    </div>
                                }
                            </div>
                        </div>
                        {userMode &&
                            <div className="">
                                <button onClick={() => createUSer()} className="btn btn-white green-btn me-3">Save and Send Invitation</button>
                                <button onClick={() => navigate(-1)} className="btn btn-dark">cancel</button>
                            </div>
                        }
                        {!userMode &&
                            <div className="call-information mb-3">
                                <div className="order-items">
                                    <h3 className="con-title">Manage Staff Access</h3>
                                    <div className="manage-staf">
                                        <div className="suspend-access">
                                            <h4 className="sub-title">Suspend Access</h4>
                                            <p className="sub-content">This account will no longer have access to your app. You can restore access at any time.</p>
                                            <button className="btn suspend-btn" onClick={() => suspendAccess()}>Suspend Access</button>
                                        </div>
                                        <div className="suspend-access">
                                            <h4 className="sub-title">Delete Account</h4>
                                            <p className="sub-content">Removed staff members will be permanently removed from your app. This action cannot be reversed.</p>
                                            <button className="btn delete-btn" onClick={() => deleteAccess()}>Delete Account</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default UserDetailsBody;