import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Divider from '@mui/material/Divider';
import Body from '../body';
import './OrdersTabMenu.scss';
import { useNavigate } from 'react-router-dom';
import { AllOrderTabDetails  } from '../../../constants';

const OrdersTabMenu = ({ body, selectedItem='All Order' }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(selectedItem)

  const handleChange = (value) => {
    setValue(value);
    navigate(value)
  };
  const [orderMenuData, setOrderMenuData] = useState([]);

  useEffect(() => {
    const permission = localStorage?.userPermission?.split(",") || [];
    if (AllOrderTabDetails.length > 0) {
      let finalData = AllOrderTabDetails.filter((item) => {
        return permission.includes(item.moduleId.toString())
      });
     console.log("finalData", finalData)
      setOrderMenuData(finalData);
    }

  }, []);
  return (
    <div className="orders-filter-page page-container pos-relative">
      <div className="topview">
        <h4 className='fw-600' >{selectedItem}</h4>
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        // textColor="secondary"
        TabIndicatorProps={{
          sx: {
            height: "5px",
          }
        }}
        className='order-tabs-item'
        // indicatorColor="secondary"
        aria-label="secondary tabs example">
        {orderMenuData.map((item, i) => (
          <div className={`tab-list-item ${selectedItem === item.value && 'active'}`} key={i} ><Tab onClick={()=>handleChange(item.url)} value={item.value} data-url={item.url} label={item.label} className='tabItem'></Tab></div>
        ))}
      </Tabs>
      {/* <Divider variant='fullWidth' className='divider' /> */}
      <Body body={body} style={{ height: '100%' }} />
    </div>
  );
}

export default OrdersTabMenu;

// import React from 'react';
// import { Tab, Nav } from 'react-bootstrap';

// const CustomTabs = ({body}) => {
//   return (
//     <Tab.Container defaultActiveKey="unlabelled">
//       <Nav variant="tabs" className="flex-row justify-content-center">
//         <Nav.Item>
//           <Nav.Link eventKey="all-orders" className="tab-item">
//             All Orders
//           </Nav.Link>
//         </Nav.Item>
//         <Nav.Item>
//           <Nav.Link eventKey="unlabelled" className="tab-item">
//             Unlabelled
//           </Nav.Link>
//         </Nav.Item>
//         <Nav.Item>
//           <Nav.Link eventKey="labelled" className="tab-item">
//             Labelled
//           </Nav.Link>
//         </Nav.Item>
//         <Nav.Item>
//           <Nav.Link eventKey="printed" className="tab-item">
//             Printed
//           </Nav.Link>
//         </Nav.Item>
//         <Nav.Item>
//           <Nav.Link eventKey="manifest" className="tab-item">
//             Manifest
//           </Nav.Link>
//         </Nav.Item>
//         <Nav.Item>
//           <Nav.Link eventKey="intransit" className="tab-item">
//             Intransit
//           </Nav.Link>
//         </Nav.Item>
//         <Nav.Item>
//           <Nav.Link eventKey="delivered" className="tab-item">
//             Delivered
//           </Nav.Link>
//         </Nav.Item>
//       </Nav>
//       <Tab.Content>
//       <Body body={body} style={{ height: '100%' }} />
//       </Tab.Content>
//     </Tab.Container>
//   );
// };

// export default CustomTabs;
