import React from 'react';
import PageLayout from '../../../global/partialBase/pageLayout';
import NDROrderBody from './body';

const NDROrder = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<NDROrderBody />} selectedItem = "NDR" />
        </div>
    )
}

export default NDROrder;