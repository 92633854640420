import React from 'react';
import { useEffect } from "react";
import './orderTrackingDetails.scss';
import './banner-deyga-image.png';
import { useLocation } from "react-router-dom";

//var textStatus = 'no';

const OrderTrackingDetails = () => {

    const location = useLocation();
    const { status, orderNumber,emailid,orderDate } = location.state || {};
    // const [orderNumbers, setOrderId] = useState('');
    //const [orderStatus, setOrderStatus] = useState('');

    useEffect(() => {
        // Redirect to home if no data is available (prevents direct access)
        //setOrderId(orderNumber);
        //setOrderStatus(status);        
    }, [status, orderNumber,emailid,orderDate]);

    const getProgressBarWidth = () => {
        switch (status.toLowerCase()) {
          case "labeled": return "20%";
          case "printed": return "30%";
          case "manifest": return "50%";
          case "in transit": return "60%";
          case "out for delivery": return "83%";
          case "delivered": return "100%";
          default: return "8%";
        }
      };

      const getOrderContent = () =>{
        switch (status.toLowerCase()) {
            case "labeled": return "Your order is ready";
            case "printed": return "Your order ready to pick up";
            case "manifest": return "Your order dispatched";
            case "in transit": return "Your order in transit";
            case "out for delivery": return "Your order is our for delivery";
            case "delivered": return "Your order delivered";
            default: return "Your Order Placed";
        }
      };

      const getOrderTime = () =>{
        switch (status.toLowerCase()) {
            case "labeled": return "5 to 6 Days";
            case "printed": return "5 to 6 Days";
            case "manifest": return "4 to 5 Days";
            case "in transit": return "3 to 4 Days";
            case "out for delivery": return "1 Days";
            case "delivered": return "0 Days";
            default: return "5 to 7 Days";
          }
        };

      const getActiveClass = (lineStatus) => {
        const statusOrder = ["labeled", "printed", "manifest", "in transit", "out for delivery", "delivered"];
        return statusOrder.indexOf(lineStatus.toLowerCase()) <= statusOrder.indexOf(status.toLowerCase()) ? "track-item active-section" : "track-item";
      };

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });
      };

    return (
        <>
            <section className="order-header">
                <div className="outer-main-box-header">
                    <div className="order-date-sct">
                        <h3>Order #{orderNumber}</h3>
                        <p style={{marginBottom: '0px'}}>Ordered on {formatDate(orderDate)}</p>
                    </div>
                    {/* <div className="order-detail-sct">
                        <a href="#" className="order-detail-link">
                            Order Details
                            <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.76172L7 7.76172L1 13.7617" stroke="#507D3C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </a>
                    </div> */}
                </div>
            </section>
            <section className="order-way-sct">
                <div className="outer-odr-way-sct">
                    <div className="left-sct-odr">
                        <img src="https://cdn.shopify.com/s/files/1/0034/7901/1441/files/image_a9f49919-ccf5-40ce-b737-1af19a243740.png?v=1737982054" alt="banner"/>
                    </div>
                    <div className="right-sct-odr">
                        <div className="inner-content-odr">
                            <h4>Arriving in <b>{getOrderTime()}</b></h4>
                            <h2>{getOrderContent()}</h2>
                            <div className="progressbar">
                                <div className="progress-bar"></div>
                                <div className="progress-bar-fillment" style={{maxWidth:getProgressBarWidth()}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="track-odr-sct">
                <div className="outer-sct-track">
                    <div className="inner-track-sct">
                        <div className="progress-bar-track">
                            <div className="progress-bar-line"></div>
                            <div className="progress-bar-line-filled" style={{ height: getProgressBarWidth() }}></div>
                        </div>
                        <div className="track-item-sct">
                            <div className="track-item active-section">
                                <div className="icon-sct-track">
                                    <div className="svg-icon-sct">
                                        <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M28.534 1.47782C28.4196 1.36342 28.2644 1.29915 28.1026 1.29915C27.9408 1.29915 27.7857 1.36342 27.6713 1.47782L12.2693 16.8798L4.17498 8.78557C3.93675 8.54734 3.5505 8.54734 3.31227 8.78557L0.876384 11.2214C0.76198 11.3358 0.697709 11.491 0.697709 11.6528C0.697709 11.8146 0.761981 11.9698 0.876385 12.0842L11.8379 23.0456C12.0762 23.2838 12.4624 23.2838 12.7007 23.0456L30.9699 4.77643C31.2081 4.5382 31.2081 4.15195 30.9699 3.91371L28.534 1.47782Z" fill="white" stroke="white" strokeWidth="1.22006" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="content-sct-track">
                                    <h2>Order Placed</h2>
                                    <p>Your order is placed successfully</p>
                                </div>
                            </div>

                            <div className={getActiveClass("labeled")}>
                                <div className="icon-sct-track">
                                    <div className="svg-icon-sct">
                                        <svg width="33" height="35" viewBox="0 0 33 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M28.2109 21.8774L28.2372 21.5693L28.4906 18.5261" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.57715 18.5261L4.54931 30.166C4.61267 30.924 5.24625 31.5074 6.00651 31.5074H20.1937" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M23.5992 20.1405L23.4375 22.3885" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.55371 20.1405L8.95571 28.1211" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M18.3613 28.1211L18.5492 20.1405" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M13.537 28.1211L13.5195 20.1405" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M29.3934 12.4701H2.67725C2.06914 12.4701 1.57617 12.9631 1.57617 13.5712V15.675C1.57617 16.2831 2.06914 16.7761 2.67725 16.7761H29.3934C30.0015 16.7761 30.4945 16.2831 30.4945 15.675V13.5712C30.4945 12.9631 30.0015 12.4701 29.3934 12.4701Z" fill="#FFEAC8" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.8621 3.50427H7.96856C7.43113 3.50427 6.96143 3.86473 6.82161 4.3825L4.63477 12.4701" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M27.4335 12.4701L25.2467 4.3825C25.1069 3.86473 24.6372 3.50427 24.0998 3.50427H23.2324" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M20.1963 1.54907H11.8727C11.2128 1.54907 10.6777 2.08409 10.6777 2.74407V4.26679C10.6777 4.92677 11.2128 5.46179 11.8727 5.46179H20.1963C20.8563 5.46179 21.3913 4.92677 21.3913 4.26679V2.74407C21.3913 2.08409 20.8563 1.54907 20.1963 1.54907Z" fill="#FFEAC8" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M27.1244 34.2054C29.9658 34.2054 32.2692 31.9019 32.2692 29.0605C32.2692 26.2191 29.9658 23.9156 27.1244 23.9156C24.2829 23.9156 21.9795 26.2191 21.9795 29.0605C21.9795 31.9019 24.2829 34.2054 27.1244 34.2054Z" fill="#CDDF95" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M25.001 29.1381L26.3596 30.4966L29.2277 27.6285" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="content-sct-track">
                                    <h2>Product getting ready</h2>
                                    <p>Your order is getting packed</p>
                                </div>
                            </div>

                            <div className={getActiveClass("printed")}>
                                <div className="icon-sct-track">
                                    <div className="svg-icon-sct">
                                        <svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.7539 12.1462V19.3898L25.409 19.7348L23.1669 19.5623L21.2697 21.9769H20.5799V14.9057L7.98967 7.31705L12.8188 4.55756L25.7539 12.1462Z" fill="#CDDF95"/>
                                            <path d="M1.43551 9.12799L15.9229 17.4065V34.1359L1.43551 25.6849V9.12799Z" fill="#FFEAC8"/>
                                            <path d="M15.5644 33.8686L1.67016 25.9423C1.44479 25.8138 1.30732 25.5749 1.30732 25.318V9.49239C1.30732 9.23321 1.44705 8.99431 1.67242 8.8681L15.5689 0.944021C15.7897 0.817813 16.0602 0.817813 16.281 0.944021L30.1752 8.87036C30.4006 8.99882 30.5381 9.23771 30.5381 9.49464V25.3203C30.5381 25.5794 30.3984 25.8183 30.173 25.9445L16.2765 33.8686C16.0557 33.9948 15.7852 33.9948 15.5644 33.8686Z" stroke="#191919" strokeWidth="1.21795" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M27.7418 10.6711L30.1758 9.28284" stroke="#191919" strokeWidth="1.21795" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M20.5694 14.7503L25.8994 11.7236" stroke="#191919" strokeWidth="1.21795" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M1.68883 9.29187L15.9233 17.4098L18.2041 16.1094" stroke="#191919" strokeWidth="1.21795" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M15.9229 17.4098L15.9206 31.4978" stroke="#191919" strokeWidth="1.21795" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12.9225 4.57941L25.8994 11.7237" stroke="#191919" strokeWidth="1.21795" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.04412 7.36047L20.5703 14.7549" stroke="#191919" strokeWidth="1.21795" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M20.5707 14.7548V21.5002C20.5707 21.8766 21.0417 22.0456 21.2828 21.7571L23.2346 19.4155L25.468 19.5733C25.7001 19.5891 25.8984 19.4042 25.8984 19.1721V11.7236" stroke="#191919" strokeWidth="1.21795" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M4.77298 23.3258L7.73438 25.0138" stroke="#191919" strokeWidth="1.21795" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="content-sct-track">
                                    <h2>Packing</h2>
                                    <p>Your order is getting packed</p>
                                </div>
                            </div>

                            <div className={getActiveClass("manifest")}>
                                <div className="icon-sct-track">
                                    <div className="svg-icon-sct">
                                        <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.987 29.2335C26.9615 28.4156 27.8991 26.152 27.0813 24.1775C26.2634 22.203 23.9998 21.2654 22.0253 22.0832C20.0508 22.9011 19.1132 25.1647 19.9311 27.1392C20.7489 29.1137 23.0125 30.0513 24.987 29.2335Z" fill="#FFEAC8" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M24.8413 25.658C24.8413 26.3945 24.2441 26.9937 23.5056 26.9937C22.7671 26.9937 22.1699 26.3965 22.1699 25.658C22.1699 24.9195 22.7671 24.3223 23.5056 24.3223" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.61201 29.2332C11.5865 28.4154 12.5241 26.1517 11.7063 24.1772C10.8884 22.2028 8.62477 21.2651 6.65029 22.083C4.67582 22.9008 3.7382 25.1645 4.55605 27.139C5.37391 29.1134 7.63753 30.0511 9.61201 29.2332Z" fill="#FFEAC8" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.46628 25.658C9.46628 26.3945 8.86909 26.9937 8.13059 26.9937C7.39209 26.9937 6.79492 26.3965 6.79492 25.658C6.79492 24.9195 7.39209 24.3223 8.13059 24.3223" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M17.6967 25.2041H13.9385" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M18.0986 8.41016H22.2955L26.8033 16.4931H18.0986V8.41016Z" fill="#CDDF95"/>
                                            <path d="M16.79 8.15887H20.4746C21.448 8.15887 22.3517 8.66846 22.8573 9.50052L27.0554 16.4297L27.7601 16.4237C29.46 16.4098 30.8455 17.7833 30.8455 19.4832V24.0078C30.8455 24.6687 30.31 25.2061 29.6472 25.2061H29.2311" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M2.51881 25.2041C1.67879 25.2041 1 24.5234 1 23.6853V15.528" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M24.083 16.4277H18.0635V14.467V10.9954" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M1.15527 5.30133L7.83922 9.18735V16.8039L1.15527 13.0734V5.30133Z" fill="#FFEAC8"/>
                                            <path d="M8.30034 16.6008L14.288 13.185C14.493 13.0676 14.6184 12.8506 14.6184 12.6157V5.61684C14.6184 5.38196 14.491 5.163 14.288 5.04754L8.30034 1.63172C7.99778 1.45854 7.62357 1.45854 7.321 1.63172L1.33335 5.04754C1.12832 5.16499 1.00293 5.38196 1.00293 5.61684V12.6157C1.00293 12.8506 1.13031 13.0695 1.33335 13.185L7.321 16.6008C7.62357 16.774 7.99778 16.774 8.30034 16.6008Z" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12.7909 6.27576L7.81053 9.11631L2.82812 6.27576" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.81152 9.11639V14.903" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="content-sct-track">
                                    <h2>Order ready</h2>
                                    <p>Waiting for the courier partner to pickup</p>
                                </div>
                            </div>

                            <div className={getActiveClass("in transit")}>
                                <div className="icon-sct-track">
                                    <div className="svg-icon-sct">
                                        <svg width="38" height="27" viewBox="0 0 38 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.38379 16.3988H7.53037" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.30371 12.794H9.45029" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M1.38379 9.18726H7.53037" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.30371 5.58252H9.45029" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M28.7741 26.0039C30.9658 26.0039 32.7426 24.2271 32.7426 22.0354C32.7426 19.8437 30.9658 18.067 28.7741 18.067C26.5824 18.067 24.8057 19.8437 24.8057 22.0354C24.8057 24.2271 26.5824 26.0039 28.7741 26.0039Z" fill="#FFEAC8" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M13.0065 26.0032C15.1983 26.0032 16.975 24.2265 16.975 22.0348C16.975 19.8431 15.1983 18.0663 13.0065 18.0663C10.8148 18.0663 9.03809 19.8431 9.03809 22.0348C9.03809 24.2265 10.8148 26.0032 13.0065 26.0032Z" fill="#FFEAC8" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M30.143 22.035C30.143 22.7906 29.5301 23.4034 28.7746 23.4034C28.0191 23.4034 27.4062 22.7906 27.4062 22.035C27.4062 21.2795 28.0191 20.6667 28.7746 20.6667" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.3773 22.035C14.3773 22.7906 13.7645 23.4034 13.009 23.4034C12.2535 23.4034 11.6406 22.7906 11.6406 22.035C11.6406 21.2795 12.2535 20.6667 13.009 20.6667" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M22.6554 21.5709H19.125" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M5.37598 18.4885V20.0136C5.37598 20.8736 6.07322 21.5709 6.93322 21.5709" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M21.8438 1.84393H26.7765L32.0746 10.6132H21.8438V1.84393Z" fill="#CDDF95"/>
                                            <path d="M34.6452 21.5709H34.9526C35.7865 21.5709 36.4616 20.8957 36.4616 20.0619V13.6902C36.4616 11.9923 35.0772 10.62 33.3793 10.634L32.4148 10.6421L27.6145 2.98647C27.0117 2.02601 25.9568 1.4433 24.8235 1.4433H6.92429C6.20093 1.4433 5.61621 2.02802 5.61621 2.75138V3.49283" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M29.3662 10.6421H21.584V3.78619" stroke="#131313" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="content-sct-track">
                                    <h2>Intransit</h2>
                                    <p>Your order is on the way</p>
                                </div>
                            </div>

                            <div className={getActiveClass("out for delivery")}>
                                <div className="icon-sct-track">
                                    <div className="svg-icon-sct">
                                        <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M30.5297 7.625H22.2305V12.2726V14.5963H24.3053V16.9201H26.3801H30.5297V7.625Z" fill="#FFEAC8"/>
                                            <path d="M26.9575 16.8604H29.6567C30.1438 16.8604 30.538 16.4662 30.538 15.9791V8.24808C30.538 7.76097 30.1438 7.36676 29.6567 7.36676H23.0053C22.5182 7.36676 22.124 7.76097 22.124 8.24808V11.6855" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M19.575 5.39326C19.1984 7.1785 17.4432 8.32095 15.658 7.94432C13.8728 7.56768 12.7303 5.81258 13.107 4.02734C13.4836 2.2421 15.2387 1.09965 17.0239 1.47628C18.8091 1.85291 19.9516 3.60802 19.575 5.39326Z" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M6.46191 25.7172L7.29183 23.7254L8.28774 21.4016L11.4414 24.2233L10.1136 27.875L8.78569 29.0369L2.31231 30.3648L1.31641 29.0369L1.48239 27.377L2.81026 26.5471L6.46191 25.7172Z" fill="#CDDF95"/>
                                            <path d="M7.30755 23.6423L6.53418 25.7314" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M6.53443 25.7314L2.98407 26.5174C1.91444 26.7534 1.19629 27.7628 1.32184 28.8525C1.41474 29.6509 2.15796 30.2058 2.94889 30.0677L8.14898 29.1638C9.11064 28.978 9.90404 28.3026 10.243 27.3836L11.5387 24.0517" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.7235 16.3708L8.68145 19.1931C8.04368 20.9432 8.42285 22.3744 9.96202 23.1477L15.265 26.1884C15.265 26.1884 15.7044 30.0853 15.8425 31.2478C15.9831 32.4104 16.651 33.2314 17.7307 33.2691C18.8104 33.3042 19.6867 33.1511 19.5737 31.9559C19.3904 30.025 19.3326 27.3861 19.1895 25.8796C19.0765 24.6768 18.715 23.7353 17.6328 23.0749L13.3567 20.5665L15.7496 15.1305" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M15.7485 15.1305L18.1916 16.9384C18.6561 17.2824 19.216 17.4657 19.7935 17.4657H23.0024C23.9214 17.4657 24.6646 16.7224 24.6646 15.8034C24.6646 14.8845 23.9214 14.1412 23.0024 14.1412H20.0697L18.4552 12.8958C17.157 11.8965 15.7309 11.0755 14.2093 10.4653C14.2018 10.4628 14.1917 10.4578 14.1842 10.4553C12.9061 9.94305 12.3638 10.1037 10.5384 10.3096C8.76573 10.508 7.22149 10.7641 6.97793 10.8219C6.05644 11.0328 5.46638 11.5174 5.09226 12.2606L3.22166 15.9717C2.83499 16.74 3.13377 17.6791 3.89707 18.0783C4.67545 18.4876 5.63461 18.1813 6.03635 17.4004L7.45252 14.6309C7.5856 14.3722 7.83919 14.199 8.12794 14.1714L10.6313 13.9203" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="content-sct-track">
                                    <h2>Out for delivery</h2>
                                    <p>Your order will be delivered anytime soon</p>
                                </div>
                            </div>

                            <div className={getActiveClass("delivered")}>
                                <div className="icon-sct-track">
                                    <div className="svg-icon-sct">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M27.6439 15.9935V29.0925C27.6439 29.9224 26.9703 30.596 26.1404 30.596H5.57777C4.74781 30.596 4.07422 29.9224 4.07422 29.0925V16.0015" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M22.3086 3.66038V1.81803C22.3086 1.53938 22.5351 1.31284 22.8138 1.31284H25.4941C25.7728 1.31284 25.9993 1.53938 25.9993 1.81803V5.77339" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.34082 16.0273L15.9233 19.8543V27.3553L9.34082 23.6814V16.0273Z" fill="#FFEAC8"/>
                                            <path d="M16.4052 27.0696L22.3152 23.6997C22.5177 23.5854 22.6419 23.3689 22.6419 23.1363V16.228C22.6419 15.9955 22.5177 15.7809 22.3152 15.6647L16.4052 12.2947C16.1065 12.1243 15.7376 12.1243 15.4389 12.2947L9.52895 15.6647C9.32647 15.7789 9.20215 15.9955 9.20215 16.228V23.1363C9.20215 23.3689 9.32647 23.5834 9.52895 23.6997L15.4389 27.0696C15.7376 27.2401 16.1065 27.2401 16.4052 27.0696Z" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M20.5756 17.0299L15.9226 19.6842L11.2676 17.0299" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M15.9219 19.6842V25.081" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M15.9213 2.41336L1.81198 10.4464C1.44912 10.6529 1.22656 11.0378 1.22656 11.4527V14.085C1.22656 14.4759 1.64757 14.7205 1.98837 14.528L15.9213 6.59525L29.8563 14.528C30.1951 14.7205 30.6181 14.4759 30.6181 14.085V11.4527C30.6181 11.0358 30.3956 10.6508 30.0327 10.4464L15.9233 2.41336H15.9213Z" fill="#CDDF95" stroke="#191919" strokeWidth="1.46154" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="content-sct-track">
                                    <h2>Delivered</h2>
                                    <p>Your order delivered sucessfully</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    );
  }
export default OrderTrackingDetails