export const filterOptions = [   
    {
        "title": "Order Details",
        "label": [
            {
                "id": 1,
                "menu": 'Order Number',
                "value":"N",
                "key":"orderDetails"

            },
            {
                "id": 2,
                "menu": 'AWB Number',
                "value": "A" ,
                "key":"orderDetails"
            },
            {
                "id": 1,
                "menu": 'Mobile Number',
                "value":"M",
                "key":"orderDetails"

            },
            {
                "id": 2,
                "menu": 'Email',
                "value": "E" ,
                "key":"orderDetails"
            },
        ]
    }, 
    {
        "title": "Payment Type",
        "label": [
            {
                "id": 1,
                "menu": 'COD',
                "value":"COD",
                "key":"payment_type"

            },
            {
                "id": 2,
                "menu": 'Prepaid',
                "value": "Pre-paid" ,
                "key":"payment_type"
            },
        ]
    },
    {
        "title": "Not Serviceable Orders",
        "label": [
            {
                "id": 1,
                "menu": 'Not Serviceable',
                value:"NotServiceable",
                key: "tag",
    
            }
        ]
    }
]