// eslint-disable-next-line no-unused-vars
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './stackedGroupChart.scss'
const StackedGroupChart = ({ className, series, colors,categories =[] }) => {
  // const colors =['#FF8243', '#FFBF74', '#C67CD1', '#7874F4'];
  const options = {
    chart: {
      type: 'bar',
      height: 440,
      stacked: true,
      toolbar: {
        show: false, // Show the toolbar
        tools: {
          download: false, // Hide the download button
        },
      }
    },
    stroke: {
      width: 1.4,
      colors: ['#fff']
    },
    //   dataLabels: {
    //    enabled: false,
    //   },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '10px',
        colors: ['#304758'],
      },
      formatter: function (val, opts) {
        const { seriesIndex,dataPointIndex, w } = opts;
        // console.log("dataPointIndex",seriesIndex ,dataPointIndex, w.globals.initialSeries[seriesIndex].groupLastIndex[dataPointIndex] ? w.globals.initialSeries[seriesIndex].categoryName : "")
        // return w.globals.initialSeries[seriesIndex].categoryName;
        // const colorLen = w.globals.colors.length/2;
        //   //console.log(seriesIndex, dataPointIndex, colorLen, seriesIndex/colorLen >=1)
        //  if(seriesIndex === w.globals.series.length - 1 )
        //console.log("Testing", seriesIndex,dataPointIndex, w.globals.initialSeries[seriesIndex].data[dataPointIndex], w.globals.initialSeries[seriesIndex].groupLastIndex[dataPointIndex])
        return w.globals.initialSeries[seriesIndex].groupLastIndex[dataPointIndex] ? w.globals.initialSeries[seriesIndex].categoryName : "";
      },
    },
    tooltip: {
      enabled: true,
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        // const colorLen = w.globals.colors.length/2;
        // //console.log(seriesIndex, dataPointIndex, w.globals.colors.length / 2, w.globals.initialSeries)
        // const groupName = w.globals.seriesNames[seriesIndex];
        const category = w.globals.labels[dataPointIndex];
        // const value = series[seriesIndex][dataPointIndex];
        let list = []
        // if(seriesIndex/colorLen >=1){
        let filterVal = w.globals.initialSeries.filter((a) => a.categoryName === w.globals.initialSeries[seriesIndex]?.categoryName);
        let total = 0;
        filterVal.map((i, k) => {
          total += i.data[dataPointIndex];
          return list.push({ category: i.name.split("-")[1], value: i.data[dataPointIndex], color: w.globals.colors[k] })
        });
        //  //console.log(filterVal, list)
        // }
        let str = "";
        list.map((i) => {
          str += `<div class="items-container"><span class="items"> <span class='color-code' style="height: 10px;width: 10px;display: inline-block;background: ${i.color};border-radius: 10px;margin-right: 10px;"></span>${i.category} </span> <span class="value" style="margin-left:10px;">${i.value}</span></div>`
        })
        return `
     <div style="padding: 10px; border: 1px solid #ddd; background-color: #000;color:#fff">     
      <span class="zone-text">${category} - ${w.globals.initialSeries[seriesIndex].categoryName}</span><br />
      ${str
          }      
      <span class="total-text">Total <span class="value"> ${total}</span></span>
     </div>
    `;
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%', // Decrease this value to increase space between columns
        distributed: false,
        // columnWidth: '90',
        // barWidth:'10%',
        // barHeight: '10%',
        // horizontal: false,
        // columnWidth: '80%', // Width of the bars (in percentage)
        // barHeight: '12%',  // Height for horizontal bars
        // barGroupPadding: 1, // Space between groups
        // borderRadius: 4, // Add a slight border radius to bars
        dataLabels: {
          position: 'top',
        },
      }
    },
    xaxis: {
      categories:categories,
      labels: {
        style: {
          colors: ['#F1753F', '#043039', '#F4DCB6', '#8A8A8A', '#8A8A8A'], // Different colors for each label
          fontSize: '10px',
          fontWeight: '600',
          fontFamily: 'Inter'
        },
      },
    },
    fill: {
      opacity: 1
    },
    colors: colors,
    grid: {
      show: false, // Hides the grid lines
    },
    yaxis: {
      show: false,
      // labels: {
      //  formatter: (val) => {
      //   return val / 1000 + 'K'
      //  }
      // }
    },
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        customHTML: function () {
          // Return custom HTML for the legend icon (e.g., square instead of circle)
          return `<div style="width: 15px; height: 15px; background-color: #4b245c; border-radius: 10px;"></div>`;
        }
      },

    },
    states: {
      hover: {
        filter: {
          type: 'none', // Disables the color change on hover
        },
      },
    },
  };

  return <ReactApexChart className={className} options={options} series={series} type="bar" height={300} />;
};

export default StackedGroupChart;