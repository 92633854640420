// import React from 'react';
// import { ResponsivePie } from '@nivo/pie';

// const GradientDonutChart = () => {
//   // Calculate the total value
//  const data = [
//     { id: 'Category 1', value: 40 },
//     { id: 'Category 2', value: 30 },
//     { id: 'Category 3', value: 20 },
//     { id: 'Category 4', value: 10 },
// ]
//   const total = data.reduce((sum, item) => sum + item.value, 0);

//   return (
//     <div style={{ height: '400px', position: 'relative' }}>
//       <ResponsivePie
//         data={data}
//         margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
//         innerRadius={0.6} // Creates the "donut" shape
//         padAngle={0.7}
//         cornerRadius={3}
//         borderWidth={1}
//         borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
//         enableRadialLabels={false}
//         enableSliceLabels={false}
//         defs={[
//           {
//             id: 'gradient1',
//             type: 'linearGradient',
//             colors: [
//               { offset: 0, color: '#f6d365' }, // light color
//               { offset: 100, color: '#fda085' }, // darker color
//             ],
//           },
//           {
//             id: 'gradient2',
//             type: 'linearGradient',
//             colors: [
//               { offset: 0, color: '#a1c4fd' },
//               { offset: 100, color: '#c2e9fb' },
//             ],
//           },
//           {
//             id: 'gradient3',
//             type: 'linearGradient',
//             colors: [
//               { offset: 0, color: '#d4fc79' },
//               { offset: 100, color: '#96e6a1' },
//             ],
//           },
//           {
//             id: 'gradient4',
//             type: 'linearGradient',
//             colors: [
//               { offset: 0, color: '#84fab0' },
//               { offset: 100, color: '#8fd3f4' },
//             ],
//           },
//         ]}
//         fill={[
//           { match: { id: 'Category 1' }, id: 'gradient1' },
//           { match: { id: 'Category 2' }, id: 'gradient2' },
//           { match: { id: 'Category 3' }, id: 'gradient3' },
//           { match: { id: 'Category 4' }, id: 'gradient4' },
//         ]}
//         // You can customize the tooltip, labels, etc.
//       />
//       {/* Centered total label */}
//       <div style={{
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         fontSize: '24px',
//         fontWeight: 'bold',
//         textAlign: 'center'
//       }}>
//         {total}
//       </div>
//     </div>
//   );
// };

// export default GradientDonutChart;


import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import './gradientDonut.scss'

const GradientDonutChart = ({ options }) => {
  //console.log("options", options)
  const { data = [], margin = { top: 20, right: 120, bottom: 20, left: 0 }, legends = [], customLegend = true, className = '', arcLabelEnable=false } = options || {}
  return (
    <div className={`gradient-donut ${className}`}>
      <ResponsivePie
        data={data}
        margin={margin}
        innerRadius={0.5}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              0.2
            ]
          ]
        }}
        colors={['#F1753F','#043039','#F4DCB6','#8A8A8A','#D7E3D5','#E2E2E2','#5A7C44','#364A29', '#799567']}
        arcLinkLabelsTextOffset={0}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsOffset={11}
        arcLinkLabelsDiagonalLength={0}
        arcLinkLabelsStraightLength={0}
        arcLinkLabelsThickness={0}
        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
        arcLinkLabel={d => `${d.data?.arkLabel || d.label}`}
        enableArcLabels={!arcLabelEnable}
        enableArcLinkLabels={arcLabelEnable}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              2
            ]
          ]
        }}
        legends={legends}
      // legends={[
      //   {
      //     anchor: 'right',
      //     direction: 'column',
      //     justify: false,
      //     translateX: 40,
      //     translateY: 3,
      //     itemsSpacing: 0,
      //     itemWidth: 10,
      //     itemHeight: 30,
      //     itemTextColor: '#999',
      //     itemDirection: 'left-to-right',
      //     itemOpacity: 1,
      //     symbolSize: 10,
      //     symbolShape: 'circle',
      //     effects: [
      //       {
      //         on: 'hover',
      //         style: {
      //           itemTextColor: '#000'
      //         }
      //       }
      //     ]
      //   }
      // ]}
      />
      {customLegend &&
        <div className='legend-container'>
          {data.map((item) => (
            <div key={item.id} className='legends'>
              <div
                style={{
                  minWidth: '10px',
                  height: '10px',
                  backgroundColor: item.color,
                  marginRight: '10px',
                  borderRadius: '10px'
                }}
              ></div>
              <div className='legend-content'><span className='label'>{item.label}</span> <span className='value'>{item.value}</span></div>
            </div>
          ))}
        </div>
      }
      {/* {customLegend && <div className="legend-container">
        {
          data.map((i, k) => <p key={k} className="legend"><span className="color" style={{ background: i.color }}></span> {i.label}<span className='value'>{i.value}</span></p>)
        }
      </div>} */}
    </div>
  )
};

export default GradientDonutChart;
