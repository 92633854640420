import React from 'react';
import PageLayout from '../../../global/partialBase/pageLayout';
import LabledBody from './body';

const Labled = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<LabledBody />} selectedItem = "Labelled Orders" />
        </div>
    )
}

export default Labled;