import axios from "axios";
//import jwt_decode from "jwt-decode";
import { API_URL_LIVE, API_URL_STAGING } from './../constants';
let search = window.location.search;
let params = new URLSearchParams(search);
if (params.get('deyga-token')) {
  let awt_token = params.get('deyga-token');
  let name = params.get("name");
  localStorage.setItem('deyga-token', awt_token);
  localStorage.setItem("name", name);
  localStorage.setItem("isShow", "no");
}

localStorage.setItem('status', true)
let API_URL = ''
if (window.location.href.search("staging.deyga.in") > -1) {
 // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_STAGING;
}
else if (window.location.href.search("shipping.deyga.in") > -1) {
 // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_LIVE;
}
else {
 // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_STAGING;
}

const instance = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use(
  config => {
    // Get token from localStorage and set it in the request header
    const token = localStorage.getItem('deyga-token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    localStorage.setItem('status', false);
    //// console.log(localStorage.getItem('status'));
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    localStorage.setItem('status', true)
    return response;
  },
  function (error) {
   // console.log("errorerror", error)
    if (error?.response?.status === 401 || error?.response?.status === 302) {
      localStorage.removeItem('deyga-token');
      localStorage.removeItem("name");
      localStorage.removeItem('isShow');
      localStorage.clear();
      document.getElementById("logout-id").click();
      // window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default instance;
