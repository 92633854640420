import API from './axiosInstance';

export const postData = async (url, data, sucessCallBack) => {
    return new Promise((resolve, reject) => {
        API.post(url, data)
            .then((res) => {

                // //console.log(res);
                sucessCallBack(res?.data, res)

            }).catch((error) => {
                sucessCallBack('error', error)
                reject(error)
            })
    })
}

export const getData = async (url, sucessCallBack) => {
    return new Promise((resolve, reject) => {
        API.post(url)
            .then((res) => {

                // //console.log(res);
                sucessCallBack(res?.data, res)

            }).catch((error) => {
                sucessCallBack('error', error)
                reject(error)
            })
    })
}

// export const postData = (url, data) => {
//     return new Promise((resolve, reject) => {
//         API.post(url, data).then(res => {
//             resolve(res.data)
//         }).catch((error) => {
//             reject(error);
//         });
//     });
// }