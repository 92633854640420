import React, { useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './tabs.scss'
import PerformanceByCourier from "./performanceByCourier/performanceByCourier";
// import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';
import OrderStatus from "./orderStatus/orderStatus";
const TabsList = (props) => {
  const [selectedTab, setSelectedTab] = useState("PerformanceByCourier")
  return (
    <div className="tab-container">
      <Tabs
        id="dashboard-tabs"
        className="mb-3 dashboard-tabs"
        onSelect={(data) => {
          console.log("data", data)
          setSelectedTab(data)
        }}
      >
        <Tab eventKey="PerformanceByCourier" title="Performance by Courier">
          {selectedTab === "PerformanceByCourier" && <PerformanceByCourier {...props} />}
        </Tab>
        <Tab eventKey="OrderStatus" title="Order Status">
          {selectedTab === "OrderStatus" && <OrderStatus {...props} />}
        </Tab>
      </Tabs>

    </div>
  );
}
export default TabsList;