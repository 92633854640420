import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import FeatureIcon from "../../../assets/FeaturedIcon.svg";
import DummyCarrier from '../../../assets/dummy-carrier.svg';

const CourierIntegration = ({ item, show, handleClose, handleSave, cType }) => {
    //    console.log(item, show, handleClose, handleSave, cType);
    // const [enableSaveBtn,] = useState(false);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const data = { ...item };

        data.additional_data = JSON.parse(item.additional_data || "{}");
        data.setting_data = JSON.parse(item.setting_data || "{}");
        console.log(data)
        setFormData(data);
    }, [])
    const summeryChange = (parent, key, value) => {
        let data = formData;
        data[parent][key] = value;
        // console.log("xpressbees form data", data);
        setFormData(data);
    }
    return (<>

        <Modal
            size='sm'
            show={show === 'editCourier'}
            onHide={handleClose}
            centered
            scrollable
            className='courier-modal'
            backdrop="static"
        >
            <div className='modal-content modal-lg'>
                <div className="modal-header border-bottom-0 justify-content-between d-block">
                    <h5 className="setting-courier-model-title ">{cType.toUpperCase()} Edit Courier</h5>
                    <p className='text-center'>
                        <img className="c-img" style={{maxWidth:"140px"}} src={formData.image_url || DummyCarrier} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = DummyCarrier;
                        }} />
                    </p>
                </div>
                {cType === 'delhivery' ?
                    <div className="modal-body modal-lg">
                        <label className='setting-courier-model-label'>Account Nickname</label>
                        <input type='text' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} name='display_name' className='form-control mb-3' defaultValue={formData.display_name} />

                        {/* <label className='setting-courier-model-label'>Client/Warehouse name</label>
                        <input type='text' readOnly onChange={(e) => setFormData({ ...formData, shipping_carrier_name: e.target.value })} name='shipping_carrier_name' className='form-control mb-3' defaultValue={formData?.shipping_carrier_name} /> */}

                        <label className='setting-courier-model-label'>Key</label>
                        <input type='text' onChange={(e) => summeryChange("setting_data", "key", e.target.value)} name='key' className='form-control mb-3' defaultValue={formData?.setting_data?.key} />

                    </div>
                    : cType === 'bluedart' ?
                        <div className="modal-body modal-lg">
                            <label className='setting-courier-model-label'>Account Nickname</label>
                            <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} defaultValue={formData.display_name} />

                            <label className='setting-courier-model-label'>API Key</label>
                            <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "key", e.target.value)} defaultValue={formData?.setting_data?.key} />

                            <label className='setting-courier-model-label'>Password</label>
                            <input type='text' name='productCode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "password", e.target.value)} defaultValue={formData?.setting_data?.password} />

                            <label className='setting-courier-model-label'>Account Number</label>
                            <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "accountnumber", e.target.value)} defaultValue={formData?.setting_data?.accountnumber} />

                            <label className='setting-courier-model-label'>Tracking Login Id</label>
                            <input type='text' name='loginId' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_login_id", e.target.value)} defaultValue={formData?.additional_data?.traking_login_id} />

                            <label className='setting-courier-model-label'>Tracking Licence Key</label>
                            <input type='text' name='trackingKeyLoc' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_licence_key", e.target.value)} defaultValue={formData?.additional_data?.traking_licence_key} />

                            <label className='setting-courier-model-label'>Customer Email Id</label>
                            <input type='text' name='customerCode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_email_id", e.target.value)} defaultValue={formData?.additional_data?.customer_email_id} />

                            <label className='setting-courier-model-label'>Customer Mobile</label>
                            <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_mobile", e.target.value)} defaultValue={formData?.additional_data?.customer_mobile} />

                            <label className='setting-courier-model-label'>Origin Area</label>
                            <input type='text' name='originArea' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "origin_area", e.target.value)} defaultValue={formData?.additional_data?.origin_area} />
                        </div>
                        : cType === 'other' ?
                            <div className="modal-body modal-lg">
                                <label className='setting-courier-model-label'>Account Nickname</label>
                                <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} defaultValue={formData.display_name} />

                                <label className='setting-courier-model-label'>API Key</label>
                                <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "key", e.target.value)} defaultValue={formData?.setting_data?.key} />

                                <label className='setting-courier-model-label'>Password</label>
                                <input type='text' name='productCode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "password", e.target.value)} defaultValue={formData?.setting_data?.password} />

                                <label className='setting-courier-model-label'>Account Number</label>
                                <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "accountnumber", e.target.value)} defaultValue={formData?.setting_data?.accountnumber} />

                                <label className='setting-courier-model-label'>Tracking Login Id</label>
                                <input type='text' name='loginId' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_login_id", e.target.value)} defaultValue={formData?.additional_data?.traking_login_id} />

                                <label className='setting-courier-model-label'>Tracking Licence Key</label>
                                <input type='text' name='trackingKeyLoc' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_licence_key", e.target.value)} defaultValue={formData?.additional_data?.traking_licence_key} />

                                <label className='setting-courier-model-label'>Customer Email Id</label>
                                <input type='text' name='customerCode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_email_id", e.target.value)} defaultValue={formData?.additional_data?.customer_email_id} />

                                <label className='setting-courier-model-label'>Customer Mobile</label>
                                <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_mobile", e.target.value)} defaultValue={formData?.additional_data?.customer_mobile} />

                                <label className='setting-courier-model-label'>Origin Area</label>
                                <input type='text' name='originArea' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "origin_area", e.target.value)} defaultValue={formData?.additional_data?.origin_area} />

                                {/* <label className='setting-courier-model-label'>API Key</label>
                                <input type='text' name='apiKey' className='form-control mb-3' defaultValue={settingData?.key} /> */}

                            </div>
                            : cType === 'dtdc' ?
                                <div className="modal-body modal-lg">

                                    <label className='setting-courier-model-label'>Account Nickname</label>
                                    <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} defaultValue={formData.display_name} />

                                    <label className='setting-courier-model-label'>API Key</label>
                                    <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "key", e.target.value)} defaultValue={formData?.setting_data?.key} />

                                    <label className='setting-courier-model-label'>Password</label>
                                    <input type='text' name='productCode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "password", e.target.value)} defaultValue={formData?.setting_data?.password} />

                                    <label className='setting-courier-model-label'>Account Number</label>
                                    <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "accountnumber", e.target.value)} defaultValue={formData?.setting_data?.accountnumber} />

                                    <label className='setting-courier-model-label'>Tracking Login Id</label>
                                    <input type='text' name='loginId' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_login_id", e.target.value)} defaultValue={formData?.additional_data?.traking_login_id} />

                                    <label className='setting-courier-model-label'>Tracking Licence Key</label>
                                    <input type='text' name='trackingKeyLoc' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_licence_key", e.target.value)} defaultValue={formData?.additional_data?.traking_licence_key} />

                                    <label className='setting-courier-model-label'>Customer Email Id</label>
                                    <input type='text' name='customerCode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_email_id", e.target.value)} defaultValue={formData?.additional_data?.customer_email_id} />

                                    <label className='setting-courier-model-label'>Customer Mobile</label>
                                    <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_mobile", e.target.value)} defaultValue={formData?.additional_data?.customer_mobile} />

                                    <label className='setting-courier-model-label'>Origin Area</label>
                                    <input type='text' name='originArea' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "origin_area", e.target.value)} defaultValue={formData?.additional_data?.origin_area} />


                                </div>
                                : cType === 'ekart' ?
                                    <div className="modal-body modal-lg">
                                        <label className='setting-courier-model-label'>Account Nickname</label>
                                        <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} defaultValue={formData.display_name} />

                                        <label className='setting-courier-model-label'>API Key</label>
                                        <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "key", e.target.value)} defaultValue={formData?.setting_data?.key} />

                                        <label className='setting-courier-model-label'>Password</label>
                                        <input type='text' name='productCode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "password", e.target.value)} defaultValue={formData?.setting_data?.password} />

                                        <label className='setting-courier-model-label'>Account Number</label>
                                        <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "accountnumber", e.target.value)} defaultValue={formData?.setting_data?.accountnumber} />

                                        <label className='setting-courier-model-label'>Tracking Login Id</label>
                                        <input type='text' name='loginId' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_login_id", e.target.value)} defaultValue={formData?.additional_data?.traking_login_id} />

                                        <label className='setting-courier-model-label'>Tracking Licence Key</label>
                                        <input type='text' name='trackingKeyLoc' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_licence_key", e.target.value)} defaultValue={formData?.additional_data?.traking_licence_key} />

                                        <label className='setting-courier-model-label'>Customer Email Id</label>
                                        <input type='text' name='customerCode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_email_id", e.target.value)} defaultValue={formData?.additional_data?.customer_email_id} />

                                        <label className='setting-courier-model-label'>Customer Mobile</label>
                                        <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_mobile", e.target.value)} defaultValue={formData?.additional_data?.customer_mobile} />

                                        <label className='setting-courier-model-label'>Origin Area</label>
                                        <input type='text' name='originArea' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "origin_area", e.target.value)} defaultValue={formData?.additional_data?.origin_area} />


                                    </div>
                                    : cType === 'pickrr' ?
                                        <div className="modal-body modal-lg">
                                            <label className='setting-courier-model-label'>Account Name</label>
                                            <input type='text' name='accountName' className='form-control mb-3' defaultValue={formData.display_name} />

                                            <label className='setting-courier-model-label'>API Key</label>
                                            <input type='text' name='apiKey' className='form-control mb-3' defaultValue={formData.display_name} />

                                        </div>
                                        : cType === 'xpressbees' ?
                                            <div className="modal-body modal-lg">
                                                <label className='setting-courier-model-label'>Account Name</label>
                                                <input type='text' name='accountName' className='form-control mb-3' defaultValue={formData.display_name}
                                                    onChange={(e) => setFormData({ ...formData, display_name: e.target.value })}
                                                />

                                                <label className='setting-courier-model-label'>Username</label>
                                                <input type='text' name='username' className='form-control mb-3' defaultValue={formData?.xpressBeesShippingCarriersRequest?.username}
                                                    onChange={(e) => summeryChange("xpressBeesShippingCarriersRequest", "username", e.target.value)}
                                                />
                                                <label className='setting-courier-model-label'>Password</label>
                                                <input type='text' name='password' className='form-control mb-3' defaultValue={formData?.xpressBeesShippingCarriersRequest?.password}
                                                    onChange={(e) => summeryChange("xpressBeesShippingCarriersRequest", "password", e.target.value)}
                                                />

                                            </div>
                                            : cType === 'dhl' ?
                                                <div className="modal-body modal-lg">
                                                    <label className='setting-courier-model-label'>Account Nickname</label>
                                                    <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} defaultValue={formData.display_name} />

                                                    <label className='setting-courier-model-label'>API Key</label>
                                                    <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "key", e.target.value)} defaultValue={formData?.setting_data?.key} />

                                                    <label className='setting-courier-model-label'>Password</label>
                                                    <input type='text' name='productCode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "password", e.target.value)} defaultValue={formData?.setting_data?.password} />

                                                    <label className='setting-courier-model-label'>Account Number</label>
                                                    <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "accountnumber", e.target.value)} defaultValue={formData?.setting_data?.accountnumber} />

                                                    <label className='setting-courier-model-label'>Tracking Login Id</label>
                                                    <input type='text' name='loginId' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_login_id", e.target.value)} defaultValue={formData?.additional_data?.traking_login_id} />

                                                    <label className='setting-courier-model-label'>Tracking Licence Key</label>
                                                    <input type='text' name='trackingKeyLoc' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_licence_key", e.target.value)} defaultValue={formData?.additional_data?.traking_licence_key} />

                                                    <label className='setting-courier-model-label'>Customer Email Id</label>
                                                    <input type='text' name='customerCode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_email_id", e.target.value)} defaultValue={formData?.additional_data?.customer_email_id} />

                                                    <label className='setting-courier-model-label'>Customer Mobile</label>
                                                    <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_mobile", e.target.value)} defaultValue={formData?.additional_data?.customer_mobile} />

                                                    <label className='setting-courier-model-label'>Origin Area</label>
                                                    <input type='text' name='originArea' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "origin_area", e.target.value)} defaultValue={formData?.additional_data?.origin_area} />

                                                </div> : cType === 'franchexpress' ?
                                                    <div className="modal-body modal-lg">
                                                        <label className='setting-courier-model-label'>Account Nickname</label>
                                                        <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} defaultValue={formData.display_name} />

                                                        <label className='setting-courier-model-label'>API Key</label>
                                                        <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "key", e.target.value)} defaultValue={formData?.setting_data?.key} />

                                                        <label className='setting-courier-model-label'>Password</label>
                                                        <input type='text' name='productCode' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "password", e.target.value)} defaultValue={formData?.setting_data?.password} />

                                                        <label className='setting-courier-model-label'>Account Number</label>
                                                        <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => summeryChange("setting_data", "accountnumber", e.target.value)} defaultValue={formData?.setting_data?.accountnumber} />

                                                        <label className='setting-courier-model-label'>Tracking Login Id</label>
                                                        <input type='text' name='loginId' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_login_id", e.target.value)} defaultValue={formData?.additional_data?.traking_login_id} />

                                                        <label className='setting-courier-model-label'>Tracking Licence Key</label>
                                                        <input type='text' name='trackingKeyLoc' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "traking_licence_key", e.target.value)} defaultValue={formData?.additional_data?.traking_licence_key} />

                                                        <label className='setting-courier-model-label'>Customer Email Id</label>
                                                        <input type='text' name='customerCode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_email_id", e.target.value)} defaultValue={formData?.additional_data?.customer_email_id} />

                                                        <label className='setting-courier-model-label'>Customer Mobile</label>
                                                        <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "customer_mobile", e.target.value)} defaultValue={formData?.additional_data?.customer_mobile} />

                                                        <label className='setting-courier-model-label'>Origin Area</label>
                                                        <input type='text' name='originArea' className='form-control mb-3' onChange={(e) => summeryChange("additional_data", "origin_area", e.target.value)} defaultValue={formData?.additional_data?.origin_area} />


                                                    </div> : ''}

            </div>

            <div className="modal-footer">

                <div className="col-12 mb-4 d-flex justify-content-end">
                    <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm w-100 save-btn btn-primary" onClick={() => handleSave(formData)} type="submit" >Save</button>

                </div>

            </div>

        </Modal>

        <Modal
            size='md'
            show={show === 'deleteCourier'}
            onHide={handleClose}
            centered
            scrollable
            className='courier-delete-popup'
            backdrop="static"
        >
            {/* {cType === 'delhivery' ? */}
            <div className='modal-content modal-lg'>
                <div className="modal-body modal-lg">
                    <div className='row'>
                        <div className='col-md-2' style={{ paddingRight: '0px' }}>
                            <img src={FeatureIcon} />
                        </div>
                        <div className='col-md-8' style={{ paddingLeft: '0px' }}>
                            <h5 style={{ color: '#101828', fontSize: '18px', fontWeight: '600' }}>Clear Details</h5>
                            <p style={{ color: '#667085', fontSize: '14px' }}>Are you sure want to Clear all details? </p>
                        </div>
                    </div>
                </div>

            </div>
            {/* : ''} */}

            <div className="modal-footer">

                <div className="col-12 mb-4 d-flex justify-content-end">
                    <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm clear-now-btn btn-danger" onClick={() => handleSave(formData)} type="submit" >Clear Now</button>

                </div>

            </div>

        </Modal>

    </>)

}

export default CourierIntegration;