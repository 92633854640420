import React from 'react';
import PageLayout from '../../../global/partialBase/pageLayout';
import PrintedOrderBody from './body';

const PrintedOrder = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<PrintedOrderBody />} selectedItem = "Printed Orders" />
        </div>
    )
}

export default PrintedOrder;