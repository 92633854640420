
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IconEmail from './../../assets/login/IconEmail.svg'
import HeaderLogo from '../../assets/logo/logo.png';
import BackIcon from '../../assets/back.png'
// import BackIcon from '../../assets/back-button.png';
import { useNavigate } from 'react-router-dom';
import { postData } from '../../helpers/PostData';
import { Slide, toast } from "react-toastify";
import ErrorToast from '../customToast/ErrorToast';
import SuccessToast from '../customToast/SuccessToast';
const Forgotpassword = () => {

    const navigate = useNavigate();

    const [loginData, setLoginData] = useState({
        username: ''
    });
    const [emailError, setEmailError] = useState('');


    const handleLoginDataChange = (e) => {
        const { name, value } = e.target;
        setLoginData({ [name]: value });

    };

    const validate = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    }

    const formSubmit = (e) => {
        //console.log(loginData);
        e.preventDefault();
        try {

            if (loginData?.username !== '') {
                if (validate(loginData?.username)) {
                    setEmailError('')
                }
                else {
                    setEmailError('Invalid email address')
                }
            }
            else {
                setEmailError('Invalid email address')
            }

            if (emailError === '' && loginData?.username !== '') {

                //console.log('Form Submited');

                postData("forgotpasswordapi", loginData, sucessCallBack);
            }


        }
        catch (e) {
            //console.log(e);
        }


    }

    const sucessCallBack = (data = {}, res) => {
        //console.log(data, res);

        if (res?.status === 200) {

            // localStorage.setItem('deyga-token', res?.data?.token);
            // localStorage.setItem('username', loginData?.username);

            // setTimeout(() => {
            //     navigate("/dashboard");
            // }, 1000);
            data.message = "Instruction sent to email";
            if (data?.message) {

                toast(<SuccessToast body={data?.message} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                setTimeout(() => {
                    navigate("/");
                }, 2000);

            }
            else {
                toast(<ErrorToast body="Email ID not registred" />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            }

        }
        else {
            toast(<ErrorToast body="Email ID not registred" />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        }
    }
    return (
        <>

            <div className='container-fluid'>

                <div className='row'>
                    <div className='col-md-6 loginLeftside  mt-3'>
                        <div className="offset-md-2 vh-93-8 d-flex align-items-center">
                            <div className="row">
                                <div className='col-md-10 mb-5'>
                                    <img alt="logo" className="header-logo" src={HeaderLogo} />
                                </div>
                                <div className='col-md-10 mb-3'>
                                    <div className="col back-container">
                                    <Link to={'/login'} className='text-secondary'><img alt="logo" className="back-logo mb-2" src={BackIcon} /></Link>
                                        <div className='text'>
                                            <h1>Forgot Password </h1>
                                            <p>No Worries, we'll send your instructions</p>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-10 mt-5'>
                                    <form onSubmit={formSubmit}>

                                        <div className="form-group">
                                            <label htmlFor="username" className='mb-1 label'>Email Address</label>
                                            <div style={{ position: 'relative', top: '15px' }}>
                                                <span className="icon"><img alt='' src={IconEmail} /></span>
                                            </div>

                                            <input onChange={handleLoginDataChange} value={loginData.username} type="text" className={emailError !== '' ? `form-control inputWithIcon is-invalid w-100` : `form-control inputWithIcon  w-100`} name='username' id="username" placeholder="Enter your Email Address" />

                                            {emailError !== '' && <div className='text-danger errorMessage'>{emailError}</div>}
                                        </div>

                                        <div className='elementGap mb-1'></div>

                                        <button type="submit" className="btn submit-btn w-100 mb-3" > Reset Password  </button>

                                    </form>
                                    {/* <div className="col text-center">
                                        <Link to={'/login'} className='text-secondary'><i className='bi bi-arrow-left' /> Back to Login</Link>
                                    </div> */}
                                </div>


                            </div>


                        </div>
                    </div>
                    <div className='col-md-6 loginRightside'>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Forgotpassword;
