import React from 'react';
import PageLayout from '../../../global/partialBase/pageLayout';
import DeliveredOrderBody from './body';

const DeliveredOrder = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<DeliveredOrderBody />} selectedItem = "Delivered Orders" />
        </div>
    )
}

export default DeliveredOrder;