import React, { } from "react";
import './orderStatus.scss'
// import ActiveIcon from '../../../../assets/images/dashboard/active-Icon.png'
import ChartsContainers from "../charts/chartsContainers";

const OrderStatus = ({ chardData,...props }) => {
  //   const [partnerChecked, setPartnerChecked] = useState("Bluedart");
  //   const [partnerList,] = useState(["Bluedart", "Delhivery"]);
  //   const changeEvent = (e) => {
  //     //console.log(e)
  //     setPartnerChecked(e)
  //   }
  return (
    <div className="order-status-main-container">
      <div className="order-status-container">
        <ChartsContainers {...props} chardData={chardData} type="order-status" />
      </div>
    </div>
  );
}
export default OrderStatus;