import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { useSelector } from 'react-redux';
import Search from '../../../assets/Iconsearch.svg';
import DataGridPagination from '../../global/dataGridPagination';
import Loader from '../../global/loader';
import instance from '../../../helpers/axiosInstance';

const ProductsBody = () => {
    const userClaim = useSelector((state) => state.user);
    const [products, setProducts] = useState([]);

    const [originalData, setOriginalData] = useState({});
    const gridRef = useRef();
    const per_page = 25;
    const [pageNo, setPageNo] = useState(1);
    const [loader, setLoader] = useState(false);
    const [searchText, setSearchText] = useState("");

    let columnData  = [
        {
            headerName: 'Product Name',
            field: 'products',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            flex : 1,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {
                // let productNames = param.data.orderProducts;
                //console.log(productNames, param.value)
                return (<div>
                    {param.data.product?.title}
                </div>)
            }
        },
        {
            field: 'title',
            flex :1,
            wrapText: true,
            autoHeight: true,
            headerName: 'Variant Name',
        },
        {
            flex :1,
            field: 'sku',
            wrapText: true,
            autoHeight: true,
            headerName: 'SKU',
        },
        {
            field: 'grams',
            flex : 1,
            wrapText: true,
            autoHeight: true,
            headerName: 'Weight(gm)',
            cellClass: "carrier-data",
        }
    ];

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            searchText
        );
    }

    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo, searchText);
            
        }
    }, [userClaim]);

    const getData = async (
        page = 1,
        searchText,
    ) => {
        var rowData = [];
        setLoader(true);
        try {
            await instance.get(`app/product/getvarent?per_page=${per_page}&page=${page}&search=${searchText}`).then((res) => {

                //console.log(res);
                if (res.status === 200 && Array.isArray(res?.data?.products)) {
                    setProducts(res?.data?.products || []);
                    setOriginalData(res?.data)
                }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
                setProducts([]);
            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            setProducts([]);
        }
        return rowData;
    }

    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        getData(
            pageNo,
            searchText
        );
    }

    return (
        <>
            <div className="orders-filter-page commom-listing-page page-container pos-relative">
            <div className="topview"><h4 className="fw-600">Products</h4></div>
                <Loader showHide={loader} />
                <div className='row filter-section'>
                    <div className="filter-left-container">
                        <div className="form-group">
                            <form onSubmit={searchTextChangeEvent}>
                                <div style={{ position: 'relative' }}>
                                    <span className="icon"><img alt='' src={Search} /></span>
                                </div>
                                <input
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Search"
                                    className='form-control inputWithIcon'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>

                </div >
                <DataGridPagination
                    rowData={products}
                    columnDefs={columnData}
                    gridref={gridRef}
                    rowSelection={"multiple"}
                    domLayout="autoHeight"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={originalData.per_page}
                    pageCount={originalData.count}
                />

            </div>
        </>
    );
}

export default ProductsBody;