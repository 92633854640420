import React, { useState } from 'react';
import { 
    // Link,
     useParams } from 'react-router-dom';
import './style.scss'
import HeaderLogo from '../../assets/logo/logo.png';
// import MainLogo from '../../assets/logo/login-logo.png'
import { useNavigate } from 'react-router-dom';
import { postData } from '../../helpers/PostData';
import ErrorToast from '../customToast/ErrorToast';
import SuccessToast from '../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';

const SetPassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');

    const { id } = useParams();

    const [loginData, setLoginData] = useState({
        password: '',
        temp_pwd: id
    });

    const [checkPassword, setCheckPassword] = useState(false);
    const [isUpperCase, setIsUpperCase] = useState(false);
    const [isLowerCase, setIsLowerCase] = useState(false);
    const [hasSpecialChar, setHasSpecialChar] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [isLengthValid, setIsLengthValid] = useState(false);

    const [passwordVisible, 
        // setPasswordVisible
    ] = useState(false);

    // const togglePasswordVisibility = () => {
    //     setPasswordVisible(!passwordVisible);
    // };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        // Check if the password meets the criteria
        const isUpperCase = /[A-Z]/.test(newPassword);
        const isLowerCase = /[a-z]/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
        const hasNumber = /[0-9]/.test(newPassword);
        const isLengthValid = newPassword.length >= 8;
        setIsUpperCase(isUpperCase);
        setIsLowerCase(isLowerCase);
        setHasSpecialChar(hasSpecialChar);
        setHasNumber(hasNumber);
        setIsLengthValid(isLengthValid);


    };

    const confirmPassword = (e) => {

        const newPassword = e.target.value;
        setCpassword(newPassword);

        if (password === newPassword) {
            setCheckPassword(true);
            setLoginData({
                password: password,
                temp_pwd: id
            })

        } else {
            setCheckPassword(false)
        }
    }

    const formSubmit = (e) => {
        e.preventDefault();


        //console.log('====================================');
        //console.log('form submited', loginData);
        //console.log('====================================');

        postData("activateAccountwithToken", loginData, sucessCallBack);

        // setTimeout(() => {

        //     navigate('/all-done');


        // }, 1000);


    }

    const sucessCallBack = async (data, res) => {
        //console.log(data, res);

        if (res?.status === 200) {

            if (data?.error === 'Failed to Activate') {
                toast(<ErrorToast body="Network Error. Please Try After Sometime" />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            }
            else {
                toast(<SuccessToast body={'Account Activated successfully'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                navigate('/login');
            }


        }
        else {
            toast(<ErrorToast body="Invalid Password" />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        }
    }


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-6 loginLeftside  mt-3'>
                        <div className="offset-md-2 mt-5 vh-93-8 d-flex align-items-center">
                            <div className="row">
                                <div className='col-md-10 mb-5'>
                                    <img alt="logo" className="header-logo" src={HeaderLogo} />
                                </div>
                                <div className='col-md-10 mb-3'>
                                    <div className="col back-container">
                                        {/* <img alt="logo" className="mb-2" src={MainLogo} /> */}
                                        <h1>Activate Account </h1>
                                        {/* <p>Must be at least 8 characters</p> */}
                                    </div>
                                </div>
                                <div className='col-md-10 mt-3'>
                                    <form onSubmit={formSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="username" className='mb-1 label'>New Password</label>
                                            <div className="input-group">

                                                <input type={passwordVisible ? 'text' : 'password'} className="form-control inputWithIcon" id="username" placeholder="Enter your new password" onChange={handlePasswordChange} value={password} />
                                                {/* <div className="input-group-append">
                                                    <div className="input-group-text noBorderLeft">
                                                        <Link to={''} onClick={togglePasswordVisibility}>
                                                            {passwordVisible ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                                                        </Link>
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>
                                        <div className='elementGap'></div>
                                        <div className="form-group">
                                            <label htmlFor="password" className='mb-1 label'>Confirm Password</label>
                                            <div className="input-group">

                                                <input type={passwordVisible ? 'text' : 'password'} className="form-control inputWithIcon" id="password" placeholder="Enter your new password" value={cpassword} onChange={confirmPassword} />
                                            </div>
                                        </div>
                                        <div className='elementGap'></div>

                                        <div className='row'>
                                            <div className='col-md-6 mb-2'>
                                                <label className='checkbox-container'><input type='checkbox' checked={isLowerCase} readOnly />  <span className="checkmark"></span><span className='label'>One lowercase chracter</span> </label>
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <label className='checkbox-container'><input type='checkbox' checked={hasSpecialChar} readOnly />  <span className="checkmark"></span> <span className='label'>One Special chracter</span>
                                                </label>
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <label className='checkbox-container'><input type='checkbox' checked={isUpperCase} readOnly />  <span className="checkmark"></span> <span className='label'>One uppercase chracter</span></label>
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <label className='checkbox-container'><input type='checkbox' checked={isLengthValid} readOnly />  <span className="checkmark"></span> <span className='label'>8 chracter minimum</span></label>
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <label className='checkbox-container'><input type='checkbox' checked={hasNumber} readOnly />  <span className="checkmark"></span> <span className='label'>One number</span></label>
                                            </div>
                                        </div>
                                        <div className='elementGap'></div>
                                        {(isUpperCase && isLowerCase && hasSpecialChar && hasNumber && isLengthValid && checkPassword) ? <button type="submit" className="btn submit-btn w-100">Continue</button> : <button type="submit" className="btn submit-btn w-100" disabled={true}>Continue</button>}
                                        <div className='elementGap'></div>

                                    </form>
                                </div>


                            </div>


                        </div>
                    </div>
                    <div className='col-md-6 loginRightside'>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SetPassword;