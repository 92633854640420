import React from "react";
import { Link } from "react-router-dom";
import CloseIcon from '../../../assets/close-icon-white.png';

export const Popup = ({ selecteObject, closePopup, exportMethod, printLabelMethod, cancelMethod, cBulkLabelMethod,  unassignCarrierMethiod,createManifestMethod,
    show={export:false,printLabel:false,cancel:false, cBulkLabel:false, unassignCarrier:false, createManifest:false}
 }) => {
   // console.log(selecteObject);
    return (
        <div className={`popup-container grid-checkbox-popuop`}>
            <div className="popup-body">
                <div className="left-container">
                    <span className="selected-item"> <span className="selected-count">{selecteObject.length}</span> Items Seleted</span>
                </div>
                <div className="right-container">
                    {show.export && <button type='button' onClick={exportMethod} className='btn btn-sm filter-btn'>Export</button> }
                    {show.printLabel && <button type='button' onClick={printLabelMethod} className='btn btn-sm  filter-btn'>Print Label</button>}
                    {show.unassignCarrier && <button type='button' onClick={unassignCarrierMethiod} className='btn btn-sm  filter-btn'>Unassign Carrier</button>}
                    {show.cBulkLabel && <button type='button' onClick={cBulkLabelMethod} className='btn btn-sm filter-btn'>Create Bulk Label</button>}
                    {show.createManifest && <button type='button' onClick={createManifestMethod} className='btn btn-sm filter-btn'>Create Manifest</button>}
                    {show.cancel && <button type='button' onClick={cancelMethod} className='btn btn-sm cancel-button filter-btn'>Cancel</button>}
                    <Link to={''} className="close-icon" onClick={closePopup} > <img src={CloseIcon} /> </Link>
                </div>
            </div>
        </div>
    );
};