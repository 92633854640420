import {configureStore} from '@reduxjs/toolkit';

import initialState from './initialState';
import user from './users';
import chartData from './chartData';
const store = configureStore({
  reducer: {
    user,
    chartData
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState: initialState,
});

export default store;
