import React from 'react';
import { ResponsiveBar } from '@nivo/bar'


const SingleLineStackedBarChart = ({options}) => {    
    return <ResponsiveBar
        data={options.data}
        keys={['COD', 'Prepaid']} // Stacks to display
        indexBy="id"
        margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
        padding={.1}
        innerPadding={3}
        borderRadius={3}
        enableLabel={true}
        label={d => <><tspan>{`${d.id}:`}</tspan><tspan fill='#000' className='label-text-font'>{`${d.value}`}</tspan></>} 
        labelTextColor={d => {
            // //console.log("colors",d)
            if (d.data.id === 'COD') return '#98A2B3'; // Red for categoryA
            if (d.data.id === 'Prepaid') return '#98A2B3'; // Green for categoryB
            return '#000000'; // Default color
        }}
        layout="horizontal" // Can be "horizontal" for a horizontal bar chart
        // valueScale={{ type: 'linear' }}
        // indexScale={{ type: 'band', round: true }}
        // colors={{ scheme: 'nivo' }} // Color scheme for the stacks
        colors={['#CACACA', '#ECECEC']}
        // borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisBottom={null} // Remove X axis if needed
        axisLeft={null}   // Remove Y axis if needed
        enableGridX={false} // Remove grid lines if desired
        enableGridY={false} // Remove grid lines if desired
        labelSkipWidth={2}
        labelSkipHeight={4}
        theme={{
            labels: {
                text: {
                    fontSize: 16,  // Custom font size
                    fontFamily: 'InterMedium',  // Custom font family
                },
            },
        }}
        tooltip={point => {
            //// console.log(point)
            return <div style={{boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",background:'#ffffff', padding:"5px 9px"}}><span style={{background:point.color, width:'12px',height:'12px',marginRight:'10px', display:'inline-block'}}></span>{point.id} - {point.value}</div>;
          }}
        // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    />
};

export default SingleLineStackedBarChart;
