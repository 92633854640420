import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
// import Truck from '../../../../assets/truck.svg';
import './orderDetails.scss';
// import OrderDetail from "./orderDetails";
// import CustomerDetails from "./customerDetails";
// import PackageDetails from "./packageDetails";
// import Notes from "./notes";
// import CallStatus from "./callStatus";
// import Tags from "./tags";
// import ActivityLogs from "./activityLogs";
import { useSelector } from 'react-redux';
import {
    // checkAccess,
    commonServiceMethod
} from "../../../../helpers/helper";
import Loader from "../../../global/loader";
// import { OverlayTrigger, Popover } from "react-bootstrap";
// import SuccessToast from "../../../customToast/SuccessToast";
// import { Slide, toast } from "react-toastify";
// import Cancelpopup from '../../global/popupModal/CallStatus';
// import ErrorToast from "../../../customToast/ErrorToast";
import { Link, useNavigate } from "react-router-dom";
import backArrow from '../../../../assets/back-button.png';
import DummyProduct from '../../../../assets/dummyProduct.png';
import GreenPhoneImage from '../../../../assets/green-phone-image.svg';
import RedPhoneImage from '../../../../assets/red-phone-image.svg';
import YellowPhoneImage from '../../../../assets/yellow-phone-image.svg';
import BlackPhoneImage from '../../../../assets/black-phone-image.svg';
import AlertImag from '../../../../assets/alert-triangle.png';
import moment from "moment";
import OrderDetailsModal from "../../../global/popupModal/orderDetails";
import SuccessToast from "../../../customToast/SuccessToast";
import { Slide, toast } from "react-toastify";
import Cancelpopup from '../../../global/popupModal/CallStatus';
import { Table } from "react-bootstrap";
// import { Modal } from "react-bootstrap";

const OrderDetailsBody = () => {
    const navigate = useNavigate();
    const userClaim = useSelector((state) => state.user);
    let { id,
        // page
    } = useParams();
    id = atob(id);
    const [loader, setLoader] = useState(false);
    const [orderDetail, setOrderDetail] = useState({});
    const [activityDetail, setActivityDetail] = useState([]);
    const [refreshData, setRefreshData] = useState(0);
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })
    const [fraudAlert, setFraudAlert] = useState("low");
    const [address, setAddress] = useState({});
    // const [otherReason, setOtherReason] = useState("");
    const [img, setImg] = useState("");
    const [notes, setNotes] = useState("");
    const [emailValue, setEmailValue] = useState("");
    useEffect(() => {
        // setRefreshData(0);
        if (userClaim.token !== "") {
            getDetailPage();
            getActivityDetail();
        }
        // console.log(refreshData)
    }, [userClaim, refreshData]);

    const getDetailPage = () => {
        commonServiceMethod(`app/order/${id}`, "get", {}, {}, setLoader, (res) => {
            if (res.data?.status_code === 200) {
                setOrderDetail(res.data?.orders);
                setCallStatusOnload(res.data?.orders?.order)
                try {
                    let a = JSON.parse(res.data?.orders?.order?.fraud_data || '{}');
                    let fraud = [a.customerCancel.alert, a.frequency.alert, a.order.alert, a.repeat.alert];
                    switch (fraud.filter(i => i === 1).length) {
                        case 0:
                            setFraudAlert("low");
                            break;
                        case 1:
                            setFraudAlert("medium");
                            break;
                        case 2:
                            setFraudAlert("high");
                            break;
                        default:
                            setFraudAlert("high");
                            break;
                    }
                    let shippingAdd = JSON.parse(res.data?.orders?.order?.shipping_address || '{}');
                    let billingAdd = JSON.parse(res.data?.orders?.order?.billing_address || '{}');
                    setNotes(res.data?.orders?.order?.note || "")
                    setEmailValue(res.data?.orders?.order?.customer_email || "");
                    setAddress({ shippingAdd, billingAdd });
                }
                catch (e) {
                    console.error(e);
                }
            }
        })
    }
    const getActivityDetail = () => {
        commonServiceMethod(`app/order/${id}/activity`, "get", {}, {}, setLoader, (res) => {
            if (res.status === 200) {
                setActivityDetail(res.data);
            }
        })
    }
    const showModal = (popupName, data, callBackMethod) => {
        setPopupData({ popupName, data, callBackMethod })
    }
    const closeModal = () => {
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }

    const updateCutomerShippingDetails = (obj) => {
        customAddressUpdate(`app/order/${orderDetail?.order.order_id}/edit_shipping_address`, obj, orderDetail?.order)
    }


    const customAddressUpdate = (url, obj, orderDetailData) => {
        commonServiceMethod(url, "post", obj, {}, setLoader, (res) => {
            if (res.data?.status_code === 200) {
                setRefreshData(refreshData => refreshData + 1);
                toast(<SuccessToast body={'Customer data updated for order #' + orderDetailData.order_id} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                closeModal();
            }
        })
    }

    const cancelOrder = (obj, item) => {
        const config = {};
        commonServiceMethod("app/orders/cancel", "post", obj, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={'This order is ' + item.order_name + ' cancelled'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                // getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
                closeModal();
            }
        });
    }
    const printInvoiceMethod = () => {
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        commonServiceMethod(`app/order/print-invoice?orderId=${id}`, "get", {}, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
        });
    }
    const setCallStatusOnload = (order) => {
        switch (order?.callStatus) {
            case "accepted":
                setImg(GreenPhoneImage);
                // setText("");
                break;
            case "declined":
                setImg(RedPhoneImage);
                // setText(orderDetail?.order?.reason_text || "");
                break;
            case "other":
                setImg(YellowPhoneImage);
                // setText(orderDetail?.order?.reason_text || "");
                break;
            default:
                setImg(BlackPhoneImage);
                // setText("");
                break;
        }
    }
    const setCallStatus = async (status) => {
        if (status.status === "")
            return false;
        let obj = {};
        obj.order_id = orderDetail?.order?.order_id;
        obj.status = status.status;
        obj.reason_text = status.other;
        try {
            setLoader(true);
            await commonServiceMethod(`app/order/update/callStatus`, "put", obj, {}, setLoader, (res) => {
                if (res.status === 200 && res?.data?.message === "Update order sucessfully") {
                    closeModal();
                    setRefreshData(refreshData => refreshData + 1);
                    const updatedRecord = orderDetail || {};
                    updatedRecord.order.callStatus = status.status;
                    updatedRecord.order.reason_text = status.other;
                    setOrderDetail({ ...updatedRecord });
                    toast(<SuccessToast body={'Call status updated for Order ' + orderDetail?.order?.order_name} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }

            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            // setRowData([]);
        }
    }
    const makeACall = async (obj) => {
        try {
            setLoader(true);
            await commonServiceMethod(`app/order/makeacall`, "POST", obj, {}, setLoader, (res) => {
                if (res.status === 200) {
                    closeModal();
                    toast(<SuccessToast body={'Call status updated for Order ' + orderDetail?.order?.order_name} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }

            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            // setRowData([]);
        }
    }
    const updateNote = async () => {
        try {
            setLoader(true);
            await commonServiceMethod(`app/order/${id}/add_note`, "POST", { note: notes }, {}, setLoader, (res) => {
                if (res.status === 200) {
                    toast(<SuccessToast body={res.data.message} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }

            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            // setRowData([]);
        }
    }
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const [emailEdit, setEmailEdit] = useState(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState(false);
    const saveEmail = async () => {
        if (!validateEmail(emailValue)) {
            setEmailErrorMsg(true);
            return;
        }
        setEmailErrorMsg(false)
        try {
            await commonServiceMethod(`app/order/${id}/edit_contect_info`, "POST", { customer_email: emailValue }, {}, setLoader, (res) => {
                if (res.status === 200) {
                    toast(<SuccessToast body={res.data.message} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });

                    const updatedRecord = orderDetail || {};
                    updatedRecord.order.customer_email = emailValue;
                    setOrderDetail({ ...updatedRecord });

                    setLoader(false);
                    setEmailEdit(false)
                }

            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            // setRowData([]);
        }
    }
    const updateEmail = (value) => {
        setEmailValue(value)
    }

    const printLabel = (obj) => {
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        commonServiceMethod(`app/viewlabel`, "post", { order_id: obj?.order_id }, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
        });
    }
    return (
        <>
            <Loader showHide={loader} />
            <div className="order-detail-page page-container pos-relative">
                <div className="inner-container">
                    <div className="header d-flex">
                        <Link className="back" onClick={() => navigate(-1)}>
                            <img src={backArrow} />
                        </Link>
                        <h1 className='order-number' >{orderDetail?.order?.order_name}</h1>
                    </div>
                    <div className="detail-container">
                        <div className="left-container d-flex">
                            <div className="order-items mb-3">
                                <h3 className="con-title">Order Items <button onClick={() => showModal('cancelPopup', orderDetail?.order, cancelOrder)}>Cancel Order</button></h3>
                                <div className="item-list">
                                    {orderDetail?.order?.order_products?.map((i, l) => {
                                        return <div key={l} className="item">
                                            <div className="img-container">
                                                <span className="img"><img className="product-img" src={i.image_url ? i.image_url : DummyProduct} /></span>
                                                <div className="item-detail">
                                                    <p className="title">{i.product_name}</p>
                                                    <p className="sku">{i.variant_title ? <>{i.variant_title} <br /></> : ""}  <b>SKU</b>: {i.sku}</p>
                                                </div>
                                            </div>
                                            <div className="product-count">
                                                Rs {i.price}x{i.quantity}
                                            </div>
                                            <div className="product-total">
                                                Rs {parseFloat(i.price) * parseInt(i.quantity)}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="order-summary mb-3">
                                <div className="order-items">
                                    <h3 className="con-title">Order Summary <button onClick={() => printInvoiceMethod([orderDetail])}>Print Invoice</button></h3>
                                    <div className="summer-detail">
                                        <div className="list-item">
                                            <p className="lsit"><span className="lable"> Order Date</span><span className="value">{moment(orderDetail?.order?.order_created_at).format("DD MMM YYYY hh:mm:ss")}</span></p>
                                            <p className="lsit"><span className="lable">Paid Date</span><span className="value">{moment(orderDetail?.order?.order_created_at).format("DD MMM YYYY hh:mm:ss")}</span></p>
                                            <p className="lsit"><span className="lable">Total Order</span><span className="value ">Rs {orderDetail?.order?.total_price}</span></p>
                                            <p className="lsit"><span className="lable">Order Status</span><span className="value unlabel"><button>{orderDetail?.order?.app_order_status}</button></span></p>
                                            <p className="lsit"><span className="lable">Fulfilment Status</span><span className="value fullfilment"><button>{orderDetail?.order?.order_fulfillment_status}</button></span></p>
                                            <p className="lsit"><span className="lable">Payment Mode</span><span className={`value mode ${orderDetail?.order?.gateway === "manual" ? "Pre-Paid" : "COD"}`}><button>{orderDetail?.order?.gateway === "manual" ? "Pre-Paid" : "COD"}</button></span></p>
                                        </div>
                                        <div className="list-item">
                                            <p className="lsit"><span className="lable">Dimensions (LxWxH)</span><span className="value">{orderDetail?.order?.length}x{orderDetail?.order?.width}x{orderDetail?.order?.height} CM</span></p>
                                            <p className="lsit"><span className="lable">Weight</span><span className="value">{orderDetail?.order?.weight}KG</span></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="call-information mb-3">
                                <div className="order-items left-con">
                                    <h3 className="con-title">Call Information</h3>
                                    <div className="call-container">
                                        <div className="call-status-container">
                                            <div className="call-status">
                                                <div className="call-img-count">
                                                    <img src={img} onClick={() => showModal('Call', orderDetail?.order, makeACall)} />
                                                    {orderDetail?.order?.callCount > 0 && <p className="count"><span>{orderDetail?.order?.callCount}</span></p>}
                                                </div>
                                                <select className="form-select" value={orderDetail?.order?.callStatus} onChange={(e) => { e.target.value === "other" ? showModal('other-text', orderDetail?.order, setCallStatus) : setCallStatus({ status: e.target.value, other: "" }) }}>
                                                    <option value={""}>Select</option>
                                                    <option value={"accepted"}>Order Accepted</option>
                                                    <option value={"declined"}>Declined</option>
                                                    <option value={"other"}>Other</option>
                                                </select>
                                            </div>
                                            <p className={`status ${orderDetail?.order?.callStatus}`}>{orderDetail?.order?.callStatus === "other" ? orderDetail?.order?.reason_text : orderDetail?.order?.callStatus}</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="order-items right-con">
                                    {
                                        orderDetail?.order?.callactivity?.length > 0 && <>
                                            <h3 className="con-title">Call Activity Logs</h3>
                                            <div className="call-container">
                                                {
                                                    orderDetail?.order?.callactivity?.length > 0 &&
                                                    <div className="call-active">
                                                        <Table className="call-activity-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Customer Number</th>
                                                                    <th>Call Status</th>
                                                                    <th>Started At</th>
                                                                    <th>Edned At</th>
                                                                    <th>Recording</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    orderDetail?.order?.callactivity?.map((i, k) => <tr key={k}>
                                                                        <td>{i.customerNumber}</td>
                                                                        <td>{i.status}</td>
                                                                        <td>{moment(i.created_at).format("YYYY-MM-DD hh:mm:ss")}</td>
                                                                        <td></td>
                                                                        <td>N/A</td>
                                                                    </tr>)
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                }
                                            </div>
                                        </>}
                                </div>
                            </div>
                            <div className="activity-logs mb-3">
                                <div className="order-items">
                                    <h3 className="con-title">Activity Logs</h3>
                                    <div className="activity-list">
                                        {activityDetail.map((i, k) => {
                                            return <div key={k} className="timeline">
                                                <div className="timeline-event">
                                                    <div className="timeline-circle"></div>
                                                    <div className="timeline-content">
                                                        <div className="timeline-title">{i.activity}</div>
                                                        <div className="timeline-date">{moment(i.date).format("DD MMM YYYY hh:mm:ss")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-container">
                            <div className="note-container tracking-number">
                                <h3 className="con-title ">Tracking Number
                                    <p className="link m-0">
                                        <Link target="blank" to={orderDetail?.order?.shipment?.tracking_url}>{orderDetail?.order?.shipment?.tracking_number}</Link>
                                        {orderDetail?.order?.shipment?.tracking_number && <p className="m-0 pointer mt-2" style={{color:"black"}} onClick={() => printLabel(orderDetail?.order)}>View Label</p> }
                                    </p>
                                </h3>
                            </div>
                            <div className="note-container">
                                <h3 className="con-title">Notes</h3>
                                <textarea className="form-control" value={notes} onChange={(e) => setNotes(e.target.value)}>{notes}</textarea>
                                <button className="btn btn-primary mt-3" onClick={() => updateNote()}>Update</button>
                            </div>
                            <div className="customers">
                                <h3 className="con-title">Customers </h3>
                                <div className="name-detail border-bottom mt-3">
                                    <p className="cus-name-1 m-0">{orderDetail?.order?.customer_name || ""}</p>
                                    <p className="cus-name-2 ">{orderDetail?.order?.order_products?.length || 1} Order(s)</p>
                                </div>
                                <div className="border-bottom">
                                    <h3 className="con-title ">Contact Information <Link onClick={() => setEmailEdit(true)} className="link">Edit</Link></h3>
                                    {emailEdit ?
                                        <div className="mb-2">
                                            <input className="form-control mb-2" type="email" required value={emailValue} onChange={(e) => updateEmail(e.target.map)} />
                                            <button className="btn btn-success me-2" onClick={() => saveEmail()}>Update</button>
                                            <button className="btn btn-dark" onClick={() => setEmailEdit(false)}>Cancel</button>
                                            {emailErrorMsg && <p className="errro" style={{ color: "red" }}>Please enter valid email</p>}
                                        </div>
                                        : <p className="email mt-3">{orderDetail?.order?.customer_email || ""}</p>}
                                </div>
                                <div className="border-bottom">
                                    <h3 className="con-title mb-3">Shipping Address <Link onClick={() => showModal('customerEdit', JSON.stringify(address?.shippingAdd), updateCutomerShippingDetails)} className="link">Edit</Link></h3>
                                    {address?.shippingAdd && <>
                                        <p className="cus-name-1 m-0">{address?.shippingAdd?.first_name} {address?.shippingAdd?.last_name || ""}</p>
                                        <p className="address">{address.shippingAdd.address1 || ""}
                                            {address.shippingAdd.address2 ? <><br />{address.shippingAdd.address2} </> : ""}
                                            {address.shippingAdd.city ? <><br />{address.shippingAdd.city} </> : ""}
                                            {address.shippingAdd.province ? <><br />{address.shippingAdd.province} </> : ""}
                                            {address.shippingAdd.country ? <><br />{address.shippingAdd.country} </> : ""}
                                            {address.shippingAdd.zip ? <><br />{address.shippingAdd.zip}<br /> </> : ""}
                                            <span className="phone">{address.shippingAdd.phone}</span> </p>
                                    </>}
                                </div>
                                <div className="border-bottom">
                                    <h3 className="con-title mb-3">Billing Address</h3>
                                    {address?.billingAdd && <>
                                        <p className="cus-name-1 m-0">{address?.billingAdd?.first_name} {address?.billingAdd?.last_name || ""}</p>
                                        <p className="address m-0">{address.billingAdd.address1 || ""}
                                            {address.billingAdd.address2 ? <><br />{address.billingAdd.address2} </> : ""}
                                            {address.billingAdd.city ? <><br />{address.billingAdd.city} </> : ""}
                                            {address.billingAdd.province ? <><br />{address.billingAdd.province} </> : ""}
                                            {address.billingAdd.country ? <><br />{address.billingAdd.country} </> : ""}
                                            {address.billingAdd.zip ? <><br />{address.billingAdd.zip}<br /> </> : ""}
                                            <span className="phone">{address.billingAdd.phone}</span> </p>
                                    </>}
                                </div>

                            </div>
                            <div className="fraud-analysis">
                                <h3 className="con-title"> <img src={AlertImag} /> Fraud Analysis <Link className="link" onClick={() => showModal('fraud-info-popup', orderDetail?.order, cancelOrder)}> Fraud Info</Link></h3>
                                <div className="range-input">
                                    <div className="slider-container">
                                        <div className="range">
                                            <div className={`range-limt ${fraudAlert}`}> </div>
                                        </div>
                                        <div className="slider-labels">
                                            <span>Low</span>
                                            <span>Medium</span>
                                            <span>High</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
            {popupData.popupName !== "" && popupData.popupName === "customerEdit" && <OrderDetailsModal
                item={popupData.data}
                show={popupData.popupName}
                handleSave={popupData.callBackMethod}
                handleClose={closeModal}
            />}

            {popupData.popupName !== "" && popupData.popupName !== "customerEdit" &&
                <Cancelpopup
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closeModal}
                />
            }

        </>
    )
}
export default OrderDetailsBody;