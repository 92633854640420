import React, { useEffect, useState } from "react";
import SettingsIcon from '../../../assets/settings_icon.png';
import DummyCarrier from '../../../assets/dummy-carrier.svg';
import './settings.scss';
import Loader from "../../global/loader";

import SuccessToast from "../../customToast/SuccessToast";
import ErrorToast from "../../customToast/ErrorToast";
// import SuccessToast from "../../customToast/SuccessToast";

import CourierPopup from '../../global/popupModal/courierModal';
import { commonServiceMethod } from "../../../helpers/helper";
import { Link } from "react-router-dom";
import { Slide, toast } from "react-toastify";

const SettingBody = () => {
    const [activeContent, setActiveContent] = useState(1);
    const [courierData, setCourierData] = useState([]);
    const [userRolesData, setUserRolesData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [loader, setLoader] = useState(false);

    const [popupData, setPopupData] = useState({ popupName: "", courierType: "", data: "", callBackMethod: null });

    useEffect(() => {
        getCoueireData();
        getRoleList();
    }, []);

    const getCoueireData = async () => {
        try {
            setLoader(true);
            await commonServiceMethod(`app/shipping_carriers/getshipping_carriers?per_page=25&page=1&search=`, "Get", {}, {}, setLoader, (res) => {
                if (res.status === 200) {
                    console.log(res.data?.shipping_carriers)
                    setCourierData(res.data?.shipping_carriers)
                }

            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            console.log(courierData)
        }
    }

    const getRoleList = async () => {
        try {
            setLoader(true);
            await commonServiceMethod(`app/getRoleList`, "Get", {}, {}, setLoader, (res) => {
                if (res.status === 200) {
                    console.log("CourierData",res.data)
                    setUserRolesData(res.data);
                    getUserList();
                }

            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
        }
    }

    const getUserList = async () => {
        try {
            setLoader(true);
            await commonServiceMethod(`app/getUserList`, "Get", {}, {}, setLoader, (res) => {
                if (res.status === 200) {
                    setUserData(res.data)
                }

            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
        }
    }

    const setPopup = (funData, cType, id) => {
        setPopupData({ popupName: funData, courierType: cType, data: id, callBackMethod: save })
    }
    const closePopup = () => {
        setPopupData({ popupName: "", courierType: "", data: "", callBackMethod: null })
    }

    const save = async (obj) => {
        console.log(obj)
        obj.additional_data = JSON.stringify(obj.additional_data  || {})
        obj.setting_data = JSON.stringify(obj.setting_data || {})
        // delete obj.id
        await commonServiceMethod(`app/shipping_carriers/updateshippingcarriers/${obj.id}`, 'post', obj, {}, setLoader,
            (res) => {
              console.log(res)
              if (res?.data?.status_code === 200) {
             
                toast(<SuccessToast body={`${obj.shipping_carrier_name} updated successfully`} />, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 2000,
                });
                let updatedCourierData = courierData.map(item=>{
                    if(item.id === obj.id){
                        item = obj
                    }
                    return item;
                })
                setCourierData(updatedCourierData)
                closePopup();
      
              }
              else {
                toast(<ErrorToast header='Error' body={`Please try again`} />, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 2000,
                });
                closePopup();
              }
            })
    
      }

    return <>
        <Loader showHide={loader} />
        <div className="settings-page page-container pos-relative">
            <div className="inner-container">
                <div className="left-container">
                    <div className="list">
                        <p onClick={() => setActiveContent(1)} className={`item ${activeContent === 1 && 'active'}`}>Carrier Fulfilment <span className=""><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        </span></p>
                        <p onClick={() => setActiveContent(2)} className={`item ${activeContent === 2 && 'active'}`}>User & Permissions <span className=""><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        </span></p>
                    </div>
                </div>
                <div className="right-container">
                    {
                        activeContent === 1 ?
                            <>
                                <div className="carrier-section">
                                    <div className="text-section">
                                        <h3 className="header">Carriers & Fulfilment</h3>
                                        <p className="text-content">Shipping provides are used when you purchase postage and print labels. Fulfilment provides are used when you have 3rd party services who packages and ships your orders for you  connect and manage your provides for however you ship.</p>
                                    </div>
                                    <div className="button-section">
                                        <button className="btn btn-primary green-btn">Add Courier</button>
                                    </div>
                                </div>
                                <div className="courier-list">
                                    {courierData?.map((i, k) => <div key={k} className="list">
                                        <img className="c-img" src={i.image_url || DummyCarrier} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = DummyCarrier;
                                        }} />
                                        <p className="name">{i.shipping_carrier_name}</p>
                                        <button onClick={()=>setPopup('editCourier', i.shipping_carrier_name?.toLowerCase(), i)} className="btn settings-btn"><img src={SettingsIcon} /> Settings</button>
                                    </div>)}

                                </div>
                            </> : <>
                                <div className="user-section">
                                    <div className="carrier-section">
                                        <div className="text-section">
                                            <h3 className="header">All Users</h3>
                                        </div>
                                        <div className="button-section">
                                            <Link to={`/settings/add-user`} className="btn btn-primary green-btn">Add User</Link>
                                        </div>
                                    </div>
                                    <div>
                                        {userRolesData.map((i, k) => <>
                                            <div key={k}>
                                                <p className="category-name">{i.name}</p>
                                                <div className="item-list">
                                                    {
                                                        userData.map((ll, kk) => <>
                                                            <div className="item" key={kk}>
                                                                <p className="name"><span className="inital">{ll.first_name?.[0]}{ll.last_name?.[0]}</span><span className="user-name">{ll.first_name}</span></p>
                                                                <Link onClick={() => sessionStorage.setItem("d-user-details", JSON.stringify(ll))} to={`/settings/user-details/${ll.id}`} className="btn more-btn"><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 13L7 7L1 1" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg></Link>
                                                            </div>
                                                        </>)
                                                    }
                                                </div>

                                            </div>
                                        </>
                                        )}
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
        {popupData.popupName !== "" &&
            <CourierPopup
                item={popupData.data}
                show={popupData.popupName}
                cType={popupData.courierType}
                handleSave={popupData.callBackMethod}
                handleClose={closePopup}
            />
        }
    </>
}

export default SettingBody;