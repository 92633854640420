import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import instance from '../../../../helpers/axiosInstance';
import Search from '../../../../assets/Iconsearch.svg';
import Calandar from '../../../../assets/Icondate.svg';
import AllFilter from '../../../../assets/filter-icon.png';
import DataGridPagination from '../../../global/dataGridPagination';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import FilterMenu from "../../../global/filterComponent/FilterMenu";
import CallStatus from '../../../global/popupModal/CallStatus';
import { filterOptions } from './filterObj';
import './newOrder.scss';
import { commonServiceMethod } from '../../../../helpers/helper';
import Loader from '../../../global/loader';
import SuccessToast from '../../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import { Popup } from '../../../global/popupModal/BottomDialog';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import actionButton from '../../../../assets/action-button.png';

const ManifestOrderBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [originalData, setOriginalData] = useState({});
    const [selectedObject, setSelectedObject] = useState([]);
    const [open, setOpen] = useState(false);

    // pagination and Filtr variables 
    const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [loader, setLoader] = useState(false);
    // const [callRowID, setCallRowID] = useState('');
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })
    const [searchParam, setSearchParam] = useState("");
    const [columnData, setComlumnData] = useState([]);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        //console.log(isOpen);
    };


    let columnDefsWithCall = [
        {
            headerName: 'Manifest #',
            field: 'manifest_id',
            // headerCheckboxSelection: true,
            // checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            width: 190,
            cellClass: "check-box-container order-number",
        },
        {
            field: 'order_count',
            width: 150,
            wrapText: true,
            autoHeight: true,
            headerName: 'Order Count',
            cellClass: "app-order-status",
        },
        {
            field: 'courier',
            width: 150,
            wrapText: true,
            autoHeight: true,
            headerName: 'Carrier',
            cellClass: "carrier-data",
        },

        {
            field: 'payment_type',
            width: 180,
            wrapText: true,
            autoHeight: true,
            headerName: 'Payment Type',
            cellClass: "carrier-data",
        },

        {
            width: 140,
            field: 'status',
            wrapText: true,
            autoHeight: true,
            headerName: 'Status',
            cellClass: 'order-fulfillment-status',
            cellRenderer(param) {
                return (<span className={`manifest status-value ${param.value}`}>{param.value}</span>)
            }
        },
        {
            field: 'warehouse_location',
            width: 180,
            wrapText: true,
            autoHeight: true,
            headerName: 'Warehouse',
            cellClass: "carrier-data",
        },
        {
            width: 180,
            field: 'created_at',
            wrapText: true,
            autoHeight: true,
            headerName: 'Manifest Date',
            cellClass: 'order-date',
            cellRenderer(param) {
                return (<span className={`order-date`}>{moment(param.value).format("MMM DD, YYYY hh:mm")}</span>)
            }
        },
        {
            width: 100,
            field: '',
            wrapText: true,
            autoHeight: true,
            headerName: 'Actions',
            cellRenderer(param) {
                console.log(param);
                return (
                    <div className="more-action">
                        <OverlayTrigger className="action-button" rootClose={true} trigger="click" placement="bottom" overlay={
                            <Popover id="popover-basic" className='z-index-2000 action-buttons'>
                                <Popover.Body>
                                    <button className='print-invoice' onClick={() => printInvoiceMethod(`app/manifest/label?manifest_id=${param?.data?.manifest_id}`)}>View</button><br />
                                    <button className='print-invoice' onClick={() => downloadManifest(`http://staging.deyga.in:5000/app/manifest/label?manifest_id=${param?.data?.manifest_id}`)}>Download</button><br />
                                    <button className='print-invoice' onClick={() => printInvoiceMethod(`app/manifest/printlabel?manifestid=${param?.data?.manifest_id}`)}>Print Label</button>
                                </Popover.Body>
                            </Popover>
                        }>
                            <img alt='Action' src={actionButton} />
                        </OverlayTrigger>

                    </div>
                )
            }
        },
    ];

    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
            setComlumnData([...columnDefsWithCall]);
        }
    }, [userClaim]);

    const onClearFilter = () => {
        setSearchText("");
        setSearchParam("");
        toggleDropdown();
        getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), "", "");
    }
    const showModal = (popupName, data, callBackMethod) => {

        // //console.log(string, value);
        // setCallDetails(string);
        // setCallRowID(value)
        setPopupData({ popupName, data, callBackMethod })
    }

    const closeModal = () => {
        // setCallDetails('');
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }

    const getData = async (
        page = 1,
        startDate,
        endDate,
        searchText,
        perams = "&carrier=&order_DetailValue="
    ) => {
        var rowData = [];
        setLoader(true);
        if (searchText !== "") {
            if (/^\d{10}$/.test(searchText)) {
                searchText += '&orderDetails=m'
            }
            else if (searchText.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                searchText += '&orderDetails=e'
            }
            else {
                searchText += '&orderDetails='
            }
        }
        try {
            await instance.get(`app/manifest?per_page=${per_page}&page=${page}&start_date=${startDate}&end_date=${endDate}&search=${searchText}${perams}`).then((res) => {

                //console.log(res);
                if (res.status === 200 && Array.isArray(res?.data?.data)) {
                    setRowData(res?.data?.data || []);
                    setOriginalData(res?.data)
                }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText,
            searchParam
        );
    }

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        //console.log(selectedITem.length > 0)
        // setSelectedObject((selectedObject) => [...selectedObject]);
        selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setSelectedObject([]));
    }
    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        getData(
            pageNo,
            date.startDate.format('YYYY-MM-DD'),
            date.endDate.format('YYYY-MM-DD'),
            searchText,
            searchParam
        )
    }
    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText,
            searchParam
        );
    }

    const onBtExport = useCallback((startDate, endDate) => {
        const config = {};
        commonServiceMethod(`app/mainfest/export?start_date=${startDate}&end_date=${endDate}&search=&order_DetailValue=&payment_type=&carrier=`, "get", {}, config, setLoader, (res) => {
            if (res.status === 200) {
                toast(<SuccessToast body={res?.data} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 4000,
                });
            }
        });
    }, []);

    const onApplyFilter = (selectionOption, searchTextval = "") => {
        //console.log(selectionOption)
        let url = "";
        Object.keys(selectionOption).map(i => {

            url += `&${i}=${selectionOption[i]}`
        })
        setSearchParam(url);
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchTextval || searchText,
            url
        );
        toggleDropdown();
    }

    const closeExportPopup = () => {
        setOpen(false);
        setSelectedObject([])
        gridRef.current.api.deselectAll();
    }
    const exportMethod = () => {
        var objdata = {};
        const config = {};
        objdata.order_id = selectedObject.map((i) => i.order_id).join();
        commonServiceMethod("app/order/export", "post", objdata, config, setLoader, (res) => {
            //console.log("res", res)
            const downloadUrl = window.URL.createObjectURL(
                new Blob([res.data])
            );
            //console.log(downloadUrl)
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", "file.csv"); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            closeExportPopup();
        });
    }
    const printInvoiceMethod = (url) => {
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        commonServiceMethod(url, "get", {}, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
            closeExportPopup();
        });
    }

    const downloadManifest = async (url) => {
        setLoader(true);
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        try {
            await instance({
                url: url,
                data: {},
                method: "get",
                ...config,
            })
                .then((res) => {
                    setLoader(false);
                    const blob = new Blob([res.data], { type: "application/pdf" });
                    const objectUrl = window.URL.createObjectURL(blob);
                    window.open(objectUrl);
                });
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            errorMEthod && errorMEthod(e);
        }
    }
    const cancelOrder = (obj, item) => {
        const config = {};
        commonServiceMethod("app/orders/cancel", "post", obj, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={'This order is ' + item.order_name + ' cancelled'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText, searchParam);
                closeModal();
                closeExportPopup();
            }
        });
    }
    const syncOrder = () => {
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            ""
        );
    }
    return (
        <>
            <div className='order-tabs'>

                <Loader showHide={loader} />
                <div className='quick-buttons'>
                    {/* <Link to={'/customers'} className='quickBtn' >Customer</Link>&nbsp;
                <Link to={'/products'} className='quickBtn' >Product</Link>&nbsp; */}

                    {/* {checkAccess(10) && <button className='quickBtn' onClick={() => showModal('quickAccess', 'quickAccess', quickAccessMethod)}> <img src={gridIcon} className="gridIcon"></img> Quick Actions</button>}
                <button className='syncBtn'> <img src={refreshIcon} className="refreshIcon"></img> Sync Orders</button> */}

                    {/* <button className='syncBtn' onClick={() => showModal('courierPartner', 'courierPartner')}>Assign Courier Partner</button>
                <button className='syncBtn' onClick={() => showModal('scanawb', 'scanawb')}>Scan/Paste AWBs</button> */}
                </div>
                <button onClick={() => syncOrder()} className='syncBtn' >Sync</button>
                {open ? <Popup className='all-order' exportMethod={exportMethod} printInvoiceMethod={printInvoiceMethod} cancelMethod={() => showModal('cancelPopup', selectedObject, cancelOrder)} selecteObject={selectedObject} closePopup={() => { closeExportPopup(); }} /> : null}
                <div className='row filter-section'>
                    <div className="filter-left-container">
                        <div className="form-group">
                            <form onSubmit={searchTextChangeEvent}>
                                <div style={{ position: 'relative' }}>
                                    <span className="icon"><img alt='' src={Search} /></span>
                                </div>
                                <input
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Search"
                                    className='form-control inputWithIcon'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>
                    <div className='filter-right-container'>
                        <DateRangePicker
                            onApply={(e, date) => {
                                onBtExport(date.startDate.format('YYYY-MM-DD'), date.endDate.format('YYYY-MM-DD'));
                            }}
                        >
                            <button type="button" className="btn btn-primary export-btn">
                                Export
                            </button>
                        </DateRangePicker>
                        <div className='dropdown-dialog-parent me-2'>
                            <div className={` dropdown-dialog ${isOpen ? 'open' : ''}`}>
                                <button type='button' className='btn btn-sm  filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 17 }} src={AllFilter} /> Filters</button>
                                <FilterMenu show={isOpen} filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                            </div>
                        </div>
                        <div className="form-group datepicker-class me-4">
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Calandar} /></span>
                            </div>
                            <DateRangePicker
                                className="datepicker-custom"
                                initialSettings={
                                    {
                                        startDate: startDate,
                                        endDate: endDate,
                                        maxDate: moment().toDate(),
                                        showDropdowns: true,
                                        locale: {
                                            format: 'MMM DD, YYYY',
                                        },
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, 'days').toDate(),
                                                moment().subtract(1, 'days').toDate(),
                                            ],
                                            'Last 7 Days': [
                                                moment().subtract(6, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'Last 30 Days': [
                                                moment().subtract(30, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'This Month': [
                                                moment().startOf('month').toDate(),
                                                moment().endOf('month').toDate(),
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                            ],
                                        },
                                    }}
                                onApply={(e, val) => dateChangeEvent(val, e)}
                            >
                                <input type="text" className="form-control datepicker-range" />
                            </DateRangePicker>
                        </div>
                    </div>
                    <p className='total-count'>Total Count: {originalData?.count}</p>
                </div >
                <DataGridPagination
                    rowData={rowData}
                    columnDefs={columnData}
                    onSelectionChanged={onCellClick}
                    gridref={gridRef}
                    rowSelection={"multiple"}
                    domLayout="autoHeight"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={originalData.per_page}
                    pageCount={originalData.count}
                />
                {popupData.popupName !== "" &&
                    <CallStatus
                        item={popupData.data}
                        show={popupData.popupName}
                        handleSave={popupData.callBackMethod}
                        handleClose={closeModal}
                    />
                }

            </div>
        </>
    );
}

export default ManifestOrderBody;