import React from 'react';
import PageLayout from '../../../global/partialBase/pageLayout';
import IntransitOrderBody from './body';

const IntransitOrder = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<IntransitOrderBody />} selectedItem = "Intransit" />
        </div>
    )
}

export default IntransitOrder;