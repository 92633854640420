import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import './common.scss'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomLoadingCellRenderer from "./CustomLoadingCellRenderer";
import LeftArrow from '../../assets/Icon-left-arrow.png';
import RightArrow from '../../assets/Icon-right-arrow.png';
import LeftArrowBlack from '../../assets/Icon-left-black-icon.png';
import RightArrowBlack from '../../assets/Icon-right-black-icon.png';

const DataGridPagination = ({ gridref = null, onPageChange = null, pageRangeDisplayed, pageCount = 0, rowData, columnDefs, domLayout, rowSelection, onSelectionChanged, gridReadyEvent = null, topPagination = true, forcePage=null }) => {
    const containerStyle = useMemo(() => ({ width: '100%', height: 'auto' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [forceSelectNo, setForceSelectNo] = useState(null);
    const loadingCellRenderer = useCallback(CustomLoadingCellRenderer, []);
    const reff1 = useRef(null);
    const reff2 = useRef(null);
    const loadingCellRendererParams = useMemo(() => {
        return {
            loadingMessage: true,
        };
    }, []);
    const autoSizeStrategy = useMemo(() => {
        return {
            type: "fitCellContents",
        };
    }, []);

    const onchangeEvent = (e) => {
        setForceSelectNo(e.selected);
        onPageChange(e);
    }

    useEffect(() => {
        reff1.current && (reff1.current.className="");
        reff2.current && (reff2.current.className="");
        if (rowData?.length === 0) {
            reff1.current && (reff1.current.className="disabled");
            reff2.current && (reff2.current.className="disabled");
        }
        setForceSelectNo(forcePage)
    }, [rowData])
    
    return (
        <>
            {topPagination && <div ref={reff1}><ReactPaginate 
                previousLabel={<span><img src={LeftArrow} /></span>}
                nextLabel={<span><img src={RightArrow} /></span>}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(pageCount / pageRangeDisplayed)}
                pageRangeDisplayed={4}
                forcePage={forceSelectNo}
                onPageChange={onchangeEvent}
                containerClassName="pagination top-pagination"
            /></div>}
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridref}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout={domLayout}
                        suppressRowClickSelection={true}
                        rowSelection={rowSelection}
                        onSelectionChanged={onSelectionChanged}
                        onGridReady={gridReadyEvent}
                        loadingOverlayComponent={loadingCellRenderer}
                        loadingOverlayComponentParams={loadingCellRendererParams}
                        autoSizeStrategy={autoSizeStrategy}
                    />
                </div>
            </div>
            <div ref={reff2}>
                <ReactPaginate
                    previousLabel={<span><img src={LeftArrowBlack} /> Previous</span>}
                    nextLabel={<span>Next <img src={RightArrowBlack} /></span>}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={Math.ceil(pageCount / pageRangeDisplayed)}
                    pageRangeDisplayed={4}
                    forcePage={forceSelectNo}
                    onPageChange={onchangeEvent}
                    containerClassName="pagination"
                />
            </div>
        </>
    );
}

export default DataGridPagination;