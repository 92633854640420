import React, { useEffect, useState } from "react";
import './dashboard.scss';
import MapPin from '../../../assets/images/dashboard/map-pin.png'
import Bluedart from '../../../assets/images/dashboard/bluedart.png'
import Dtdc from '../../../assets/images/dashboard/dtdc.png'
import Delhivery from '../../../assets/images/dashboard/delhivry.png'
import FrenchExpress from '../../../assets/images/dashboard/frenchExpress.png'
// import SingleStackedHorizontalBarChart from "./singleLineBar";
import SingleLineStackedBarChart from "./singleLineStackedBarChart";
// import { Link } from "react-router-dom";
import TabsList from "./tabs";
// import { commonServiceMethod } from "../../../helpers/helper";
import Loader from "../../global/loader";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { chartDataThunks } from "../../../store/chartData";

const Dashboard = () => {

    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const dashboardData = useSelector(state => state.chartData?.data);
    const chardData = useSelector(state => state.chartData);
    // const [loader,] = useState(false);
    const [cancelReasons, setCancelReason] = useState([]);
    // const [singlebarcharData,] = useState([
    //     {
    //         id: '',
    //         COD: 2, // Stack for Category A
    //         Prepaid: 6, // Stack for Category B
    //     },
    // ])

    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        // getDashboardCountAPI(date.startDate.format('YYYY-MM-DD'), date.endDate.format('YYYY-MM-DD'));
    }

    // const getDeliveryAttemptData = (startDate, endDate) => {
    //     console.log(startDate, endDate)

    //     commonServiceMethod(`app/dashboard-chart-delivery-attempts?fromDate=${startDate}&toDate=${endDate}`, "get", {}, {}, setLoader, (res) => {
    //         if (res?.status === 200) {
    //             console.log('Counts', res?.data);
    //         }
    //     })
    // }
    const dispach = useDispatch();
    useEffect(() => {
        // need to hide
        // if (!chardData?.hasOwnProperty("total_orders")) {
        dispach(chartDataThunks.getChartDatas({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
        // getDeliveryAttemptData(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
        // }
    }, [dispach, endDate, startDate]);  

    useEffect(() => {
        if (dashboardData?.hasOwnProperty("cancelled_orders")) {
            let a = [];
            dashboardData?.cancelled_orders.map((i) => {
                let cod = i.details?.filter(i => i.payment_type === "COD"),
                    prepaid = i.details?.filter(i => i.payment_type === "Prepaid");
                a.push({
                    reason: i.cancel_reason,
                    item: [
                        {
                            id: 'Total',
                            COD: cod.length > 0 && cod[0]?.order_count || 0, // Stack for Category A
                            Prepaid: prepaid.length > 0 && prepaid[0]?.order_count || 0, // Stack for Category B 
                        }
                    ],
                    COD: cod.length > 0 && cod[0]?.order_count || 0, // Stack for Category A
                    Prepaid: prepaid.length > 0 && prepaid[0]?.order_count || 0, // Stack for Category B 

                })
            });
            //// console.log("prepaid", a)
            setCancelReason(a)
        }
    }, [dashboardData])
    //console.log("dashboardData", dashboardData)
    return (
        <div className="dahsboard page-container">
            <DateRangePicker
                className="datepicker-custom"
                initialSettings={
                    {
                        startDate: startDate,
                        endDate: endDate,
                        maxDate: moment().toDate(),
                        showDropdowns: true,
                        locale: {
                            format: 'MMM DD, YYYY',
                        },
                        ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [
                                moment().subtract(1, 'days').toDate(),
                                moment().subtract(1, 'days').toDate(),
                            ],
                            'Last 7 Days': [
                                moment().subtract(6, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'Last 30 Days': [
                                moment().subtract(30, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'This Month': [
                                moment().startOf('month').toDate(),
                                moment().endOf('month').toDate(),
                            ],
                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().subtract(1, 'month').endOf('month').toDate(),
                            ],
                        },
                    }}
                onApply={(e, val) => dateChangeEvent(val, e)}
            >
                <input type="text" className="form-control dashboard-datepicker-range" />
            </DateRangePicker>
            {/* <Loader showHide={loader} /> */}
            <Loader showHide={chardData.loader} />
            <div className="title-container">
                <h1 className="title-text">Dashboard</h1>
                <button type="button" className="export-btn">Export</button>
            </div>
            <div className="Dashboard-count-container">
                <div className="flex-container">
                    <div className="left-container">
                        <div className="total-container count-item">
                            <div className="inner-div">
                                <p className="total-text">Total Orders</p>
                                <span className="total-value">{dashboardData?.total_orders?.toLocaleString() || '0'}</span>
                            </div>
                        </div>
                        <div className="new-order-container count-item">
                            <div className="inner-div">
                                <p className="total-text">New Orders</p>
                                <div className="d-flex">
                                    <div className="left-container-new">
                                        <p className="total-text">Domestic <span className="total-value">{((dashboardData?.new_orders?.domestic_cod || 0) + (dashboardData?.new_orders?.domestic_prepaid || 0))?.toLocaleString()}</span></p>
                                        <p className="text">Prepaid <span className="value">{dashboardData?.new_orders?.domestic_prepaid || 0}</span></p>
                                        <p className="text">COD <span className="value">{dashboardData?.new_orders?.domestic_cod || 0}</span></p>

                                    </div>
                                    <div className="right-container-new">
                                        <p className="img-container">
                                            <img src={MapPin} />
                                        </p>

                                        <p className="total-text">International <span className="total-value">{dashboardData?.new_orders?.international?.toLocaleString()}</span></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="right-container">
                        <div className="inprogress-container count-item">
                            <div className="inner-div">
                                <p className="inprogress-text text">Inprogress <span className="inprogress-value value">{dashboardData?.in_progress?.total_in_progress?.toLocaleString() || 0}</span></p>
                                <div className="inprogress-split inner-split">
                                    <div className="split-item">
                                        <p>Labelled <span>{(dashboardData?.in_progress?.labelled || 0)}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>Printed <span>{dashboardData?.in_progress?.printed || 0}</span></p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="intransit-container count-item">
                            <div className="inner-div">
                                <p className="intransit-text text">Intransit <span className="intransit-value value">{dashboardData?.in_transit?.total_in_transit?.toLocaleString()}</span></p>
                                <div className="intransit-split inner-split">
                                    {/* {
                                        dashboardData?.in_transit?.map((i, k) =>
                                            <div key={k} className="split-item">
                                                <p>{i.status} <span>{i?.order_count || 0}</span></p>
                                            </div>
                                        )
                                    } */}
                                    <div className="split-item">
                                        <p>Pickup <span>{dashboardData?.in_transit?.pickup}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>Intransit <span>{dashboardData?.in_transit?.in_transit}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>Out for Delivery <span>{dashboardData?.in_transit?.out_for_delivery}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="Delivered-container count-item">
                            <div className="inner-div">
                                <p className="Delivered-text text">Delivered <span className="Delivered-value value">{((dashboardData?.delivered_orders && dashboardData?.delivered_orders[0].ontime_count || 0) + (dashboardData?.delivered_orders && dashboardData?.delivered_orders[0].delayed_count || 0))?.toLocaleString()}</span></p>
                                <div className="inprogress-split inner-split">
                                    <div className="split-item">
                                        <p>Ontime <span>{dashboardData?.delivered_orders && dashboardData?.delivered_orders[0]?.ontime_count || 0}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>Delayed <span>{dashboardData?.delivered_orders && dashboardData?.delivered_orders[0]?.delayed_count || 0}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="delayed-container count-item">
                            <div className="inner-div">
                                <p className="delayed-text text">Delayed <span className="delayed-value value">{(dashboardData?.delayed_report?.total_delay?.internal_delay+dashboardData?.delayed_report?.total_delay?.external_delay).toLocaleString() || 0}</span></p>
                                <div className="delayed-split inner-split">
                                    {/* {
                                        dashboardData?.ndrOrderCounts?.map((i, k) =>
                                            <div key={k} className="split-item">
                                                <p>{i.app_order_status} <span>{i.order_count}</span></p>
                                            </div>
                                        )
                                    } */}
                                    <div className="split-item">
                                        <p>Internal <span>{dashboardData?.delayed_report?.total_delay?.internal_delay || 0}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>External <span>{dashboardData?.delayed_report?.total_delay?.external_delay || 0}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="ndr-container count-item">
                            <div className="inner-div">
                                <p className="ndr-text text">NDR <span className="ndr-value value">{dashboardData?.ndrOrderCounts?.total_orders?.toLocaleString()}</span></p>
                                <div className="ndr-split">
                                    <div className="split-item">
                                        <p>Pickup <span>{dashboardData?.ndrOrderCounts?.pickup}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>Intransit <span>{dashboardData?.ndrOrderCounts?.in_transit}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>Delivered <span>{dashboardData?.ndrOrderCounts?.delivered}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>Non Delivered <span>{dashboardData?.ndrOrderCounts?.not_delivered}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="d-flex container-part2">
                    <div className="items">
                        <div className="manifest-container">
                            <p className="title">Manifest</p>
                            <div className="d-flex">
                                {
                                    dashboardData?.manifest_counts?.map((i, k) => {
                                        let icon, value;
                                        switch (i.courier_name) {
                                            case "Delhivery":
                                                icon = Delhivery;
                                                value = i.manifest_count
                                                return <div key={k} className="item">
                                                    <div className="inner-item">
                                                        <p className="text"><img src={icon} /><span>{i.courier_name}</span></p>
                                                        <p className="value">{value}</p>
                                                    </div>
                                                </div>
                                                break;
                                            case "BlueDart":
                                                icon = Bluedart;
                                                value = i.manifest_count
                                                return <div key={k} className="item">
                                                    <div className="inner-item">
                                                        <p className="text"><img src={icon} /><span>{i.courier_name}</span></p>
                                                        <p className="value">{value}</p>
                                                    </div>
                                                </div>
                                                break;
                                            case "DTDC":
                                                icon = Dtdc;
                                                value = i.manifest_count
                                                return <div key={k} className="item">
                                                    <div className="inner-item">
                                                        <p className="text"><img src={icon} /><span>{i.courier_name}</span></p>
                                                        <p className="value">{value}</p>
                                                    </div>
                                                </div>
                                                break;
                                            case "Other":
                                                icon = FrenchExpress;
                                                value = i.manifest_count
                                                return <div key={k} className="item">
                                                    <div className="inner-item">
                                                        <p className="text"><img src={icon} /><span>{i.courier_name}</span></p>
                                                        <p className="value">{value}</p>
                                                    </div>
                                                </div>
                                                break;
                                            default:
                                                icon = FrenchExpress;
                                                value = i.manifest_count
                                                return <div key={k} className="item">
                                                    <div className="inner-item">
                                                        <p className="text"><img src={icon} /><span>{i.courier_name}</span></p>
                                                        <p className="value">{value}</p>
                                                    </div>
                                                </div>
                                                break;
                                        }
                                        //// console.log(icon, value)

                                    })
                                }
                                {/* <div className="item">
                                    <div className="inner-item">
                                        <p className="text"><img src={Delhivery} /><span>Delhivery</span></p>
                                        <p className="value">69</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="inner-item">
                                        <p className="text"><img src={Bluedart} /><span>Bluedart</span></p>
                                        <p className="value">69</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="inner-item">
                                        <p className="text"><img src={Dtdc} /><span>DTDC</span></p>
                                        <p className="value">69</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="inner-item">
                                        <p className="text"><img src={FrenchExpress} /><span>Franch Express</span></p>
                                        <p className="value">69</p>
                                    </div>
                                </div> */}
                            </div>
                            {/* <p className="link"><Link to={''}>View full report</Link></p> */}
                        </div>
                    </div>
                    <div className="items">

                        <div className="chart-item returned-container">
                            <p className="title">Returned <span className="value">
                                {/* 40 */}
                            </span></p>
                            {/* <div className="inner-split">
                                <div className="item">
                                    <p>Damaged Products <span>48</span></p>
                                    <div className="singe-chart" style={{ height: '51px' }}>
                                        <SingleLineStackedBarChart options={{ data: singlebarcharData, colors: [['#CACACA', '#ECECEC']] }} />
                                    </div>
                                </div>
                                <div className="item">
                                    <p>Wrong Products <span>24</span></p>
                                    <div className="singe-chart" style={{ height: '51px' }}>
                                        <SingleLineStackedBarChart options={{ data: singlebarcharData, colors: [['#EEF4FF', '#FDF2FA']] }} />
                                    </div>
                                </div>
                                <div className="item">
                                    <p>Others <span>24</span></p>
                                    <div className="singe-chart" style={{ height: '51px' }}>
                                        <SingleLineStackedBarChart options={{ data: singlebarcharData, colors: [['#EEF4FF', '#FDF2FA']] }} />
                                    </div>
                                </div>
                            </div> */}
                            {/* <p className="link"><Link to={''}>View full report</Link></p> */}
                        </div>
                    </div>
                    <div className="items">
                        <div className="chart-item cancelled-container">
                            <p className="title">Cancelled <span className="value">{_.sumBy(cancelReasons, "COD") + _.sumBy(cancelReasons.slice(0, 3), "Prepaid")}</span></p>
                            <div className="inner-split">
                                {
                                    cancelReasons.map((i, k) => {
                                        return <div key={k} className="item">
                                            <p>{i.reason.replace(/_/g, " ")} <span>{i.COD + i.Prepaid}</span></p>
                                            <div className="singe-chart" style={{ height: '51px' }}>
                                                <SingleLineStackedBarChart options={{ data: i.item, colors: [['#CACACA', '#ECECEC']] }} />
                                            </div>
                                        </div>
                                    })
                                }
                                {/* <div className="item">
                                    <p>Damaged Products <span>48</span></p>
                                    <div className="singe-chart" style={{ height: '51px' }}>
                                        <SingleLineStackedBarChart options={{ data: singlebarcharData, colors: [['#EEF4FF', '#FDF2FA']] }} />
                                    </div>
                                </div>
                                <div className="item">
                                    <p>Wrong Products <span>24</span></p>
                                    <div className="singe-chart" style={{ height: '51px' }}>
                                        <SingleLineStackedBarChart options={{ data: singlebarcharData, colors: [['#EEF4FF', '#FDF2FA']] }} />
                                    </div>
                                </div>
                                <div className="item">
                                    <p>Others <span>24</span></p>
                                    <div className="singe-chart" style={{ height: '51px' }}>
                                        <SingleLineStackedBarChart options={{ data: singlebarcharData, colors: [['#EEF4FF', '#FDF2FA']] }} />
                                    </div>
                                </div> */}
                            </div>
                            {/* <p className="link"><Link to={''}>View full report</Link></p> */}
                        </div>
                    </div>

                </div>

                <div className="flex-container ndr-main-container">
                    <div className="right-container">
                        <div className="ndr-container count-item">
                            <div className="inner-div">
                                <p className="ndr-text text">NDR <span className="ndr-value value">{dashboardData?.ndrOrderCounts?.total_orders?.toLocaleString()}</span></p>
                                <div className="ndr-split">
                                    <div className="split-item">
                                        <p>Pickup <span>{dashboardData?.ndrOrderCounts?.pickup}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>Intransit <span>{dashboardData?.ndrOrderCounts?.in_transit}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>Delivered <span>{dashboardData?.ndrOrderCounts?.delivered}</span></p>
                                    </div>
                                    <div className="split-item">
                                        <p>Non Delivered <span>{dashboardData?.ndrOrderCounts?.not_delivered}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <TabsList startDate={startDate} endDate={endDate} />
            </div>
        </div>
    );

};

export default Dashboard;
