import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './style.scss'
import HeaderLogo from '../../assets/logo/logo.png';
// import MainLogo from '../../assets/login/mainLogo.svg'
import { useNavigate } from 'react-router-dom';
import { postData } from '../../helpers/PostData';
import { Slide, toast } from "react-toastify";
import ErrorToast from '../customToast/ErrorToast';
import SuccessToast from '../customToast/SuccessToast';

const SetPassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');
    const { id } = useParams();

    const [loginData, setLoginData] = useState({
        password: '',
        confirmPassword: ''
    });

    const [checkPassword, setCheckPassword] = useState(false);
    const [isUpperCase, setIsUpperCase] = useState(false);
    const [isLowerCase, setIsLowerCase] = useState(false);
    const [hasSpecialChar, setHasSpecialChar] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [isLengthValid, setIsLengthValid] = useState(false);

    const [passwordVisible, 
        // setPasswordVisible
    ] = useState(false);

    // const togglePasswordVisibility = () => {
    //     setPasswordVisible(!passwordVisible);
    // };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        // Check if the password meets the criteria
        const isUpperCase = /[A-Z]/.test(newPassword);
        const isLowerCase = /[a-z]/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
        const hasNumber = /[0-9]/.test(newPassword);
        const isLengthValid = newPassword.length >= 8;
        setIsUpperCase(isUpperCase);
        setIsLowerCase(isLowerCase);
        setHasSpecialChar(hasSpecialChar);
        setHasNumber(hasNumber);
        setIsLengthValid(isLengthValid);


    };

    const confirmPassword = (e) => {

        const newPassword = e.target.value;
        setCpassword(newPassword);

        if (password === newPassword) {
            setCheckPassword(true);
            setLoginData({
                password: password,
                confirmPassword: newPassword
            })

        } else {
            setCheckPassword(false)
        }
    }

    const formSubmit = (e) => {
        e.preventDefault();


        //console.log('====================================');
        //console.log('form submited', loginData);
        //console.log('====================================');

        let payload = {
            "password": loginData?.confirmPassword,
            "temp_pwd": id
        }

        postData('resetPasswordwithToken', payload, successCallBack);


    }

    const successCallBack = (data, res) => {
        //console.log(data, res);

        if (res?.status === 200) {

            // localStorage.setItem('deyga-token', res?.data?.token);
            // localStorage.setItem('username', loginData?.username);

            // setTimeout(() => {
            //     navigate("/dashboard");
            // }, 1000);

            if (data?.message) {

                toast(<SuccessToast body={data?.message} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });

                setTimeout(() => {
                    navigate("/all-done");
                }, 3000);

            }
            else {
                toast(<ErrorToast body={data?.error} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            }

        }
        else {
            toast(<ErrorToast body={data?.error} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        }
    }


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-6 loginLeftside  mt-3'>
                        <div className="offset-md-2 mt-5 vh-93-8 d-flex align-items-center">
                            <div className="row">
                                <div className='col-md-10 mb-5'>
                                    <img alt="logo" className="header-logo" src={HeaderLogo} />
                                </div>
                                <div className='col-md-10 mb-3'>
                                    <div className="col back-container">
                                        {/* <img alt="logo" className="mb-2" src={MainLogo} /> */}
                                        <h1>Set new password</h1>
                                        {/* <p>Must be at least 8 characters</p> */}
                                    </div>
                                </div>
                                <div className='col-md-10 mt-3'>
                                    <form onSubmit={formSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="username" className='mb-1 label'>New Password</label>
                                            <div className="input-group">

                                                <input type={passwordVisible ? 'text' : 'password'} className="form-control inputWithIcon " id="username" placeholder="Enter your new password" onChange={handlePasswordChange} value={password} />
                                                {/* <div className="input-group-append">
                                                    <div className="input-group-text noBorderLeft">
                                                        <Link to={''} onClick={togglePasswordVisibility}>
                                                            {passwordVisible ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                                                        </Link>
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>
                                        <div className='elementGap'></div>
                                        <div className="form-group">
                                            <label htmlFor="password" className='mb-1 label'>Confirm Password</label>
                                            <div className="input-group">

                                                <input type={passwordVisible ? 'text' : 'password'} className="form-control inputWithIcon  " id="password" placeholder="Enter your new password" value={cpassword} onChange={confirmPassword} />
                                            </div>
                                        </div>
                                        <div className='elementGap'></div>

                                        <div className='row'>
                                            <div className='col-md-6 mb-2'>
                                                <label className='checkbox-container'><input type='checkbox' checked={isLowerCase} readOnly />  <span className="checkmark"></span>
                                                    <span className='label'>One lowercase chracter</span>
                                                </label>
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <label className='checkbox-container'><input type='checkbox' checked={hasSpecialChar} readOnly />  <span className="checkmark"></span>
                                                    <span className='label'>One Special chracter</span>
                                                </label>
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <label className='checkbox-container'><input type='checkbox' checked={isUpperCase} readOnly />  <span className="checkmark"></span>
                                                    <span className='label'>One uppercase chracter</span>
                                                </label>
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <label className='checkbox-container'><input type='checkbox' checked={isLengthValid} readOnly />  <span className="checkmark"></span>
                                                    <span className='label'>8 chracter minimum</span>
                                                </label>
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <label className='checkbox-container'><input type='checkbox' checked={hasNumber} readOnly />  <span className="checkmark"></span>
                                                    <span className='label'>One number</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='elementGap'></div>
                                        {(isUpperCase && isLowerCase && hasSpecialChar && hasNumber && isLengthValid && checkPassword) ? <button type="submit" className="btn submit-btn w-100">Reset Password</button> : <button type="submit" className="btn submit-btn w-100" disabled={true}>Reset Password</button>}
                                        <div className='elementGap'></div>

                                        <div className="col text-center">
                                            <Link to={'/forgot-password'} className='text-secondary'><h5> Didn't receive the mail? <span className='text-primary'> Click to resend</span> </h5></Link>
                                        </div>

                                        {/* <div className="col text-center">
                                            <Link to={'/login'} className='text-secondary'><i className='bi bi-arrow-left' /> Back to Login</Link>
                                        </div> */}
                                    </form>
                                </div>


                            </div>


                        </div>
                    </div>
                    <div className='col-md-6 loginRightside'>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SetPassword;