import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import './chartsContainers.scss'
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap/dist/js/bootstrap.min";
import LearnMore from '../../../../assets/images/dashboard/learnmore.png';
import LongLine from '../../../../assets/images/dashboard/Vector 66_long.png';
import ShortLine from '../../../../assets/images/dashboard/Vector 66_short.png';
import DonutChart from "../../../global/chartsDesigns/donetChart/donetChart";
import StackedGroupChart from "../../../global/chartsDesigns/stackedGroupChart/stackedGroupChart";
import SingleStackedHorizontalBarChart from "../../../global/chartsDesigns/SingleStackedHorizontalBarChart/SingleStackedHorizontalBarChart";
import HorizontalStackedBarChart from "../../../global/chartsDesigns/horizontalStackedBarChart/horizontalStackedBarChart";
import { Link } from "react-router-dom";
import BarChart from "../../../global/chartsDesigns/barChart/barChart";
import GradientDonutChart from "../../../global/chartsDesigns/gradientDonut/gradientDonut";
import ArrowImg from '../../../../assets/images/dashboard/arrow.png'
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { chartDataThunks } from "../../../../store/chartData";
// import DonutChartWithTotal from "../barchart";
// import ActiveIcon from '../../../../assets/images/dashboard/active-Icon.png'
const ChartsContainers = ({ selectedCourier, type, startDate, endDate }) => {
  const chartData = useSelector((state) => state.chartData.data);
  const deliveryAttempts = useSelector((state) => state.chartData.deliveryAttempts);
  const deliveryAttemptsTable = useSelector((state) => state.chartData.deliveryAttemptsTable);
  const shipmentOverview = useSelector((state) => state.chartData.shipmentOverview);
  const shipmentDelivered = useSelector((state) => state.chartData.shipmentDelivered);
  const shipmentDeliveredTable = useSelector((state) => state.chartData.shipmentDeliveredTable);
  const ordersFromTopCities = useSelector((state) => state.chartData.ordersFromTopCities);
  const ordersCourierSplit = useSelector((state) => state.chartData.ordersCourierSplit);
  const overallShipmentStatus = useSelector((state) => state.chartData.overallShipmentStatus);
  const cancelledReasons = useSelector((state) => state.chartData.cancelledReasons);
  const zoneWiseTatPerformance = useSelector((state) => state.chartData.zoneWiseTatPerformance);
  const zoneWiseTatPerformanceTable = useSelector((state) => state.chartData.zoneWiseTatPerformanceTable);
  const zoneWisePerformance = useSelector((state) => state.chartData.zoneWisePerformance);

  //   const [partnerChecked, setPartnerChecked] = useState("Bluedart");
  //   const [partnerList,] = useState(["Bluedart", "Delhivery"]);
  const [internalData, setInternalData] = useState([
    {
      // id: 'Total',
      Labelled: 10, // Stack for Category A
      Unlabel: 100,
      Printed: 20, // Stack for Category B      
      Manifest: 123,
    },
  ])
  const [externalData, setExternalData] = useState([
    {
      Intransit: 80,
      Delivered: 22
    },
  ])
  const [internalExtermalPercentage, setInternalExtermalPercentage] = useState({});
  const [delayReportData, setDelayReportData] = useState({});

  const [internalDataColor,] = useState(['#F1753F', '#043039', '#F4DCB6', '#8A8A8A'])
  const [externalDataColor] = useState(['#D7E3D5', '#E2E2E2'])
  const [zoneWiseeColorData] = useState([
    {
      color: "#F1753F",
      legend: "Intransit"
    },
    {
      color: "#043039",
      legend: "Delivered"
    },
    {
      color: "#F4DCB6",
      legend: "Delayed"
    },
    {
      color: "#8A8A8A",
      legend: "Returned"
    }
  ])

  const [deliveryAttemptData, setDeliveryAttemptData] = useState([]);

  const [zone_performance_data_series, setZone_performance_data_series] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [zoneWiseTAT, setZoneWiseTAT] = useState([]);
  const [zoneWiseTATTable, setZoneWiseTATTable] = useState({});
  const [averageDeliveryTime, setAverageDeliveryTime] = useState({});
  const [averageDeliveryTimeTable, setAverageDeliveryTimeTable] = useState([]);
  const [averageDeliveryTimeTableOrder, setAverageDeliveryTimeTableOrder] = useState([]);
  const [, setZoneWiseData] = useState({});
  const zoneWiseTATOption = {
    totalText: "Current TAT",
    getFieldTotal: true,
    getTotalField: "avg_delivery_time",
    isTotal: true,
    ismargin: false,
    innerRadius: .7,
    padAngle: 3,
    cornerRadius: 5,
    legends: {
      isLegent: true,
      direction: 'column',
      translateX: 10,
      translateY: 0
    }
  }
  const [zoneWiseStatus, setZoneWiseStatus] = useState(true);
  const [delayedReportStatus, setDelayedReportStatus] = useState(false);
  const [averageDTStatus, setAverageDTStatus] = useState(false);
  const [deliveryReportStatus, setDeliveryReportStatus] = useState(false);
  const [zoneWiseTATStatus, setZoneWiseTATStatus] = useState(false);

  const dispach = useDispatch();
  useEffect(() => {

    APICall()
  }, [dispach, startDate, endDate])

  const APICall = async () => {
    if (type !== "order-status") {
      await dispach(chartDataThunks.getdShipmentOverview());
      await dispach(chartDataThunks.getdDeliveryAttempts({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
      await dispach(chartDataThunks.getdDeliveryAttemptsTable({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
      await dispach(chartDataThunks.getdShipmentDelivered({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
      await dispach(chartDataThunks.getdShipmentDeliveredTable({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
      await dispach(chartDataThunks.getZoneWiseTatPerformance({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
      await dispach(chartDataThunks.getZoneWiseTatPerformanceTable({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
      await dispach(chartDataThunks.getZoneWisePerformance({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
    }
    else {
      await dispach(chartDataThunks.getOrdersFromTopCities({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
      await dispach(chartDataThunks.getOrdersCourierSplit({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
      await dispach(chartDataThunks.getOverallShipmentStatus({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
      await dispach(chartDataThunks.getCancelledReasons({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }));
    }
  }

  useEffect(() => {
    // console.log(chartData)
    if (type !== "order-status") {
      // Zone wise performance
      // let CourierZoneData = chartData?.zone_performance_data ? chartData?.zone_performance_data[selectedCourier] || [] : [];
      let CourierZoneData = zoneWisePerformance ? zoneWisePerformance || [] : [];
      if (CourierZoneData.length > 0) {
        setZoneWiseStatus(false);
        let zoneData = CourierZoneData//_.orderBy(CourierZoneData, "zone");
        setZoneWiseData(_.groupBy(zoneData, "zone"))
        let zoneListData = Object.keys(_.groupBy(zoneData, "zone"));
        let ptype = _.groupBy(zoneData, "paymentType");
        setZoneList(zoneListData)
        let zoneLength = zoneListData.length;
        var series_updatd = [
          {
            name: 'cod-Intransit',
            categoryName: 'COD',
            group: 'cod',
            data: checkZonedata("COD", "inTransit", ptype, zoneListData),//ptype["COD"].map(i=>i.in_transit_orders),
            id: 1,
            groupLastIndex: new Array(zoneLength).fill(false)
          },
          {
            name: 'cod-Delivered',
            categoryName: 'COD',
            group: 'cod',
            data: checkZonedata("COD", "delivered", ptype, zoneListData), //ptype["COD"].map(i=>i.delivered_orders),
            id: 3,
            groupLastIndex: new Array(zoneLength).fill(false)
          },
          {
            name: 'COD-Delayed',
            categoryName: 'COD',
            group: 'cod',
            data: checkZonedata("COD", "delayed", ptype, zoneListData),//ptype["COD"].map(i=>i.delayed_orders),
            id: 3,
            groupLastIndex: new Array(zoneLength).fill(false)
          },
          {
            name: 'COD-Returned',
            categoryName: 'COD',
            group: 'cod',
            data: checkZonedata("COD", "returned", ptype, zoneListData), //ptype["COD"].map(i=>i.returned_orders),
            id: 4,
            groupLastIndex: new Array(zoneLength).fill(false)
          },
          {
            name: 'prepaid-Intransit',
            categoryName: 'Prepaid',
            group: 'Prepaid',
            data: checkZonedata("prepaid", "inTransit", ptype, zoneListData), //ptype["prepaid"].map(i=>i.delivered_orders),
            id: 5,
            groupLastIndex: new Array(zoneLength).fill(false)
          },
          {
            name: 'Prepaid-Delivered',
            categoryName: 'Prepaid',
            group: 'Prepaid',
            data: checkZonedata("prepaid", "delivered", ptype, zoneListData), //ptype["prepaid"].map(i=>i.delivered_orders),
            id: 6,
            groupLastIndex: new Array(zoneLength).fill(false)
          },
          {
            name: 'Prepaid-Delayed',
            categoryName: 'Prepaid',
            group: 'Prepaid',
            data: checkZonedata("prepaid", "delayed", ptype, zoneListData), //ptype["prepaid"].map(i=>i.delayed_orders),
            id: 7,
            groupLastIndex: new Array(zoneLength).fill(false)
          },
          {
            name: 'Prepaid-Returned',
            categoryName: 'Prepaid',
            group: 'Prepaid',
            data: checkZonedata("prepaid", "returned", ptype, zoneListData), //ptype["prepaid"].map(i=>i.returned_orders),
            id: 8,
            groupLastIndex: new Array(zoneLength).fill(false)
          },
        ]
        setZone_performance_data_series([...series_updatd]);
        let a = _.groupBy(series_updatd, "group")
        Object.keys(a).map(item => {
          var b = a[item].map(k => k.data);
          var gr = a[item].map(k => k.groupLastIndex);
          var ar = [];
          for (var i = b.length - 1; i >= 0; i--) {
            var count = 0;
            b[i].map((p, m) => {
              if (p > 0 && ar.indexOf(m) === -1) {
                count++;
                ar.push(m);
                gr[i][m] = true;
              }
              else {
                gr[i][m] = false
              }
            });
            if (count === Object.keys(a).length) {
              break;
            }
          }
        });
      }
      else {
        setZoneWiseStatus(true);
      }

      // Average Delivery Time
      let averageDTData = chartData?.average_delivery_time ? chartData?.average_delivery_time || {} : {};
      if (JSON.stringify(averageDTData) !== "{}") {
        setAverageDTStatus(false)
        setAverageDeliveryTime({ ...averageDTData });
      }
      else {
        setAverageDeliveryTime({})
        setAverageDTStatus(true)
      }

      if (chartData?.average_delivery_time_table?.length) {
        setAverageDTStatus(false)
        let Data = new Array(2);
        chartData?.average_delivery_time_table.map(i => {
          if (i.paymentType?.toLowerCase() === "cod") {
            Data[0] = i;
          }
          if (i.paymentType?.toLowerCase() === "prepaid") {
            Data[1] = i;
          }
        });
        const obj = { ...Data[0] };
        delete obj.paymentType;
        const collator = new Intl.Collator([], { numeric: true });
        const categories = Object.keys(obj).sort((a, b) => collator.compare(a, b)) || [];
        setAverageDeliveryTimeTableOrder([...categories])
        // console.log("DataData", categories, Data)
        setAverageDeliveryTimeTable([...Data]);
      }
      else {
        setAverageDeliveryTimeTable([]);
        setAverageDTStatus(true)
      }

      // Zone wise TAT
      let zone = _.groupBy(zoneWiseTatPerformance, "zone") || {};
      // console.log("zone", zone)
      if (JSON.stringify(zone) !== "{}") {
        setZoneWiseTATStatus(false);
        var na = [], zoneTat = {};
        Object.keys(zone).map(i => {
          // console.log("zone[i]", zone[i])
          na.push({ id: 'In TAT', label: 'In TAT %', value: _.sumBy(zone[i], "onTime_percentage"), color: '#F1753F', avg_delivery_time: zone[i][0].avg_delivery_time })
          na.push({ id: 'Out TAT', label: 'Out TAT %', value: _.sumBy(zone[i], "delayed_percentage"), color: '#F4DCB6', avg_delivery_time: zone[i][0].avg_delivery_time })
          zoneTat[i] = na;
          na = []
        });
        // console.log("zoneTat", zoneTat)
        setZoneWiseTAT(zoneTat)
      }
      else {
        setZoneWiseTATStatus(true)
      }

      if (zoneWiseTatPerformanceTable.length > 0) {
        let a = _.groupBy(zoneWiseTatPerformanceTable, "zone");
        setZoneWiseTATTable(a)
      }

      // Delayed report
      // console.log("selectedCourier", selectedCourier, chartData?.delayed_report?.delayed_by_category)
      let deliveryReportData = chartData?.delayed_report ? chartData?.delayed_report?.delayed_by_category?.filter(i => {
        return i.delivery_name?.toLowerCase() === selectedCourier.toLowerCase()
      }) || [] : [];
      if (deliveryReportData.length > 0) {
        setDelayedReportStatus(false)
        // console.log("deliveryReportData", deliveryReportData)
        let groupby = _.groupBy(deliveryReportData, "payment_type");
        let cod = groupby["COD"]?.length > 0 ? groupby["COD"][0] : {};
        let prepaid = groupby["Prepaid"]?.length > 0 ? groupby["Prepaid"][0] : {};
        setDelayReportData({ cod, prepaid })
        let internalDelayPercentage = (_.sumBy(deliveryReportData, "internal_delay") / (_.sumBy(deliveryReportData, "internal_delay") + _.sumBy(deliveryReportData, "external_delay")))?.toFixed(2) * 100 || 0
        let externalDelayPercentage = (_.sumBy(deliveryReportData, "external_delay") / (_.sumBy(deliveryReportData, "internal_delay") + _.sumBy(deliveryReportData, "external_delay")))?.toFixed(2) * 100 || 0
        setInternalData([
          {
            // id: 'Total',
            Labelled: _.sumBy(deliveryReportData, "label_delay_count"), // Stack for Category A
            Unlabel: _.sumBy(deliveryReportData, "unlabel_delay_count"),
            Printed: _.sumBy(deliveryReportData, "printed_delay_count"), // Stack for Category B      
            Manifest: _.sumBy(deliveryReportData, "manifest_delay_count"),
          },
        ]);
        setExternalData([
          {
            Intransit: _.sumBy(deliveryReportData, "in_transit_delay_count"),
            Delivered: _.sumBy(deliveryReportData, "delivered_delay_count")
          },
        ])

        // console.log("chartData", chartData)
        setInternalExtermalPercentage({ internal_delay_percentage: internalDelayPercentage, external_delay_percentage: externalDelayPercentage });
      }
      else {
        setDelayedReportStatus(true)
      }

      let deliveryAttempt = deliveryAttempts || [];
      if (deliveryAttempt.length > 0) {
        setDeliveryReportStatus(false)
        deliveryAttempt = deliveryAttempt.map((i) => {
          let color = ""
          if (i.attempt_category?.toLowerCase() === "4+ attempts") {
            color = '#F1753F'
          } else if (i.attempt_category?.toLowerCase() === "3rd attempt") {
            color = '#043039'
          } else if (i.attempt_category?.toLowerCase() === "2nd attempt") {
            color = '#F4DCB6'
          } else if (i.attempt_category?.toLowerCase() === "1st attempt") {
            color = '#8A8A8A'
          }
          return { id: i.attempt_category, label: i.attempt_category, value: i.total_orders, color: color };
        })
        // console.log(deliveryAttempt)
        setDeliveryAttemptData(deliveryAttempt)
      }
      else {
        setDeliveryReportStatus(true)
      }
    }
  }, [chartData,zoneWisePerformance,zoneWiseTatPerformance,zoneWiseTatPerformanceTable, selectedCourier])

  const [shipmentOverviewSeries, setshipmentOverviewSeries] = useState([]);
  const [shipmentOverviewSorted, setShipmentOverviewSorted] = useState([])
  const [shipmentOverviewCount, setShipmentOverviewCount] = useState([])
  useEffect(() => {
    let data = JSON.parse(JSON.stringify(shipmentOverview));
    if (data.length > 0) {
      data = data.sort((a, b) => moment(b.date) - moment(a.date))
      // console.log(data)
      data = data.splice(0, 5)
      // console.log(data)
      setShipmentOverviewSorted([...data])
      let count = Array(0, 0, 0, 0, 0)
      data.map((i, k) => {
        if (k == 0) {
          let b = i.data.cod["inTransit"] + i.data.prepaid["inTransit"] +
            i.data.cod["delayed"] + i.data.prepaid["delayed"] +
            i.data.cod["delivered"] + i.data.prepaid["delivered"] +
            i.data.cod["returned"] + i.data.prepaid["returned"];
          count[0] = b;
        }
        if (k == 1) {
          let b = i.data.cod["inTransit"] + i.data.prepaid["inTransit"] +
            i.data.cod["delayed"] + i.data.prepaid["delayed"] +
            i.data.cod["delivered"] + i.data.prepaid["delivered"] +
            i.data.cod["returned"] + i.data.prepaid["returned"];
          count[1] = b;
        }
        if (k == 2) {
          let b = i.data.cod["inTransit"] + i.data.prepaid["inTransit"] +
            i.data.cod["delayed"] + i.data.prepaid["delayed"] +
            i.data.cod["delivered"] + i.data.prepaid["delivered"] +
            i.data.cod["returned"] + i.data.prepaid["returned"];
          count[2] = b;
        }
        if (k == 3) {
          let b = i.data.cod["inTransit"] + i.data.prepaid["inTransit"] +
            i.data.cod["delayed"] + i.data.prepaid["delayed"] +
            i.data.cod["delivered"] + i.data.prepaid["delivered"] +
            i.data.cod["returned"] + i.data.prepaid["returned"];
          count[3] = b;
        }
        if (k == 4) {
          let b = i.data.cod["inTransit"] + i.data.prepaid["inTransit"] +
            i.data.cod["delayed"] + i.data.prepaid["delayed"] +
            i.data.cod["delivered"] + i.data.prepaid["delivered"] +
            i.data.cod["returned"] + i.data.prepaid["returned"];
          count[4] = b;
        }
      });

      setShipmentOverviewCount(count)


      let Intransit = data.map(i => {
        return i.data.cod["inTransit"] + i.data.prepaid["inTransit"]
      });
      let delayed = data.map(i => {
        return i.data.cod["delayed"] + i.data.prepaid["delayed"]
      });
      let delivered = data.map(i => {
        return i.data.cod["delivered"] + i.data.prepaid["delivered"]
      });
      let returned = data.map(i => {
        return i.data.cod["returned"] + i.data.prepaid["returned"]
      });
      // console.log(Intransit, returned, delivered, delayed)
      let series = [{
        name: 'Intransit',
        data: Intransit
      }, {
        name: 'Delivered',
        data: delivered
      }, {
        name: 'Delayed',
        data: delayed
      }, {
        name: 'Return',
        data: returned
      }
      ];
      setshipmentOverviewSeries(series)
    }
  }, [shipmentOverview])
  // console.log("shipmentOverviewSorted", shipmentOverviewSorted)
  const checkZonedata = (type, key, ptype, zoneList) => {
    let typ = ptype[type]?.map(i => i[key]) || [];
    zoneList?.length === typ?.length ? typ : typ.push(0);
    return typ;
  }

  const colors = ['#F1753F', '#043039', '#F4DCB6', '#8A8A8A', '#D7E3D5', '#24321B', '#5A7C44', '#364A29', '#799567', '#B6C6AC'];
  const [shipmentDeliveredData, setShipmentDeliveredData] = useState([]);
  useEffect(() => {
    let data = shipmentDelivered.map((i, k) => {
      return { id: i.zone, arkLabel: i.zone, label: i.zone, value: i.total_delivered, color: colors[k] }
    })
    data = data.filter(i => i.value > 0);
    setShipmentDeliveredData([...data])
  }, [shipmentDelivered])

  const [courierSplitData, setCourierSplitData] = useState([]);

  useEffect(() => {
    let data = ordersCourierSplit.map((i, k) => {
      return { id: i.ecom_name, arkLabel: i.ecom_name, label: i.ecom_name, value: i.total_orders, color: colors[k] }
    })
    // console.log(data)
    setCourierSplitData(data)
  }, [ordersCourierSplit])

  const [overallShipmentStatusData, setoverallShipmentStatusData] = useState([]);
  useEffect(() => {
    let data = overallShipmentStatus.map((i, k) => {
      return { id: i.app_order_status, arkLabel: i.app_order_status, label: i.app_order_status, value: i.total_orders, color: colors[k] }
    })
    // console.log(data)
    setoverallShipmentStatusData(data)
    // console.log("overallShipmentStatus", overallShipmentStatus)
  }, [overallShipmentStatus])

  const [cancelledReasonsData, setCancelledReasons] = useState([]);
  useEffect(() => {
    let data = cancelledReasons.map((i, k) => {
      return { id: i.cancel_reason_group, arkLabel: i.cancel_reason_group, label: i.cancel_reason_group, value: i.total_orders, color: colors[k] }
    })
    // console.log(data)
    setCancelledReasons(data)
    // console.log("overallShipmentStatus", cancelledReasons)
  }, [cancelledReasons])

  return (
    <div className="chart-container">
      {type !== "order-status" ? <>
        <div className="chart-items">
          <div className="zone-wise chart-item">
            <h2 className="head-title">Zone wise performance</h2>
            <Tabs
              id="charts-toggle-tab"
              className="mb-3 charts-toggle-tab"
            >
              <Tab eventKey="home" title="Chart View">
                {!zoneWiseStatus ? zone_performance_data_series.length > 0 ? <>
                  <StackedGroupChart categories={zoneList.map((i) => i)} colors={zoneWiseeColorData.map(i => i.color)} series={zone_performance_data_series} className="zone-wise-chart" />
                  <div className="legend-list">
                    {
                      zoneWiseeColorData.map((i, k) => <p key={k} className="legend"><span className="color" style={{ background: i.color }}></span> {i.legend}</p>)
                    }
                  </div>
                </> : "Loading..." : <div>No Results Found</div>}
              </Tab>
              <Tab eventKey="profile" title="Report View">
                {!zoneWiseStatus ? zone_performance_data_series.length > 0 ? <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        {zoneList?.map((i, k) => <th key={k}>{i}</th>)}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        zoneWiseeColorData.map((i, k) => {
                          let cod = _.groupBy(zone_performance_data_series, "group")["cod"] || [];
                          let Prepaid = _.groupBy(zone_performance_data_series, "group")["Prepaid"] || [];
                          return <Fragment key={k}>
                            <tr>
                              <td rowSpan="2" className="status-title" style={{ color: i.color }}>{i.legend}</td>
                              <td className="type">COD</td>
                              {cod[k]?.data.map((ii, kk) => <td key={kk}>{ii}</td>)}
                              <td rowSpan="2" className="action"><button><img src={ArrowImg} /></button></td>
                            </tr>
                            <tr>
                              <td className="type">Prepaid</td>
                              {Prepaid[k]?.data.map((ii, kk) => <td key={kk}>{ii}</td>)}
                            </tr>
                          </Fragment>

                        })
                      }
                    </tbody>
                  </table>
                </div>
                  : "Loading..." : <div>No Results Found</div>}
              </Tab>
            </Tabs>

          </div>
          <div className="delivery-attempts chart-item">
            <h2 className="head-title">Delivery Attempts</h2>
            <Tabs
              id="charts-toggle-tab"
              className="mb-3 charts-toggle-tab delivery-attempts-chart"
            >
              <Tab eventKey="home" title="Chart View">
                <div className="donet-dashboard">
                  {!deliveryReportStatus ?
                    <DonutChart data={deliveryAttemptData} options={{ totalText: "Total Orders", isTotal: true, legends: {} }} className='dashboard-donet' />
                    : <div>No Results Found</div>}
                </div>
              </Tab>
              <Tab eventKey="profile" title="Report View">
                <div className="table-container">
                  {!deliveryReportStatus ?
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th>A</th>
                          <th>B</th>
                          <th>C</th>
                          <th>D</th>
                          <th>F</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          deliveryAttemptsTable.map((i, k) => {
                            return <Fragment key={k}>
                              <tr className="">
                                <td rowSpan="2" className="status-title">{i.attemptType}</td>
                                <td className="type">COD</td>
                                <td>{i.zones.A.cod}</td>
                                <td>{i.zones.B.cod}</td>
                                <td>{i.zones.C.cod}</td>
                                <td>{i.zones.E.cod}</td>
                                <td>{i.zones.F.cod}</td>
                                <td rowSpan="2" className="action"><button><img src={ArrowImg} /></button></td>
                              </tr>
                              <tr>
                                <td className="type">Prepaid</td>
                                <td>{i.zones.A.prepaid}</td>
                                <td>{i.zones.B.prepaid}</td>
                                <td>{i.zones.C.prepaid}</td>
                                <td>{i.zones.D.prepaid}</td>
                                <td>{i.zones.F.prepaid}</td>
                              </tr>
                            </Fragment>
                          })
                        }
                      </tbody>
                    </table>
                    : <div>No Results Found</div>}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="delayed-report-chart">
          <div className="delayed-report chart-item">
            <h2 className="head-title">Delayed Report</h2>
            <Tabs
              id="charts-toggle-tab"
              className="mb-3 charts-toggle-tab"
            >
              <Tab eventKey="chart" className="" title="Chart View">
                {!delayedReportStatus ?
                  <div className="delayed-report-main-container">
                    <div className="singe-chart delayed-report-container" style={{ height: '69px' }}>
                      {internalExtermalPercentage.internal_delay_percentage > 0 && <div className="first-chart">
                        <SingleStackedHorizontalBarChart options={{ data: internalData, color: ['#F1753F', '#043039', '#F4DCB6', '#8A8A8A'] }} enableLabel={false} />
                        <p className="split-container"><img src={LongLine} /> <span>{internalExtermalPercentage.internal_delay_percentage?.toFixed(0)}%</span>Internal Delay</p>
                      </div>}

                      {
                        internalExtermalPercentage.external_delay_percentage > 0 && <div className="second-chart">
                          <SingleStackedHorizontalBarChart options={{ data: externalData, color: ['#D7E3D5', '#E2E2E2'] }} enableLabel={false} />
                          <p className="split-container"><img src={ShortLine} /> <span>{internalExtermalPercentage.external_delay_percentage?.toFixed(0)}%</span>Courier Delay</p>
                        </div>
                      }
                    </div>
                    <div className="delayed-report-data">
                      <div className="internal-container item">
                        <p className="title">INTERNAL <span className="value">{internalExtermalPercentage.internal_delay_percentage?.toFixed(0)}%</span></p>
                        <ul>
                          {Object.keys(internalData[0]).map((i, k) => {
                            return <li key={k}>
                              <span className="color" style={{ background: internalDataColor[k] }}></span>{i} <span className="value">{internalData[0][i]} <img src={LearnMore} /></span>
                            </li>
                          })}
                        </ul>
                      </div>
                      <div className="courier-containr item">
                        <p className="title">Courier <span className="value">{internalExtermalPercentage.external_delay_percentage?.toFixed(0)}%</span></p>
                        <ul>
                          {Object.keys(externalData[0]).map((i, k) => {
                            return <li key={k}>
                              <span className="color" style={{ background: externalDataColor[k] }}></span>{i} <span className="value">{externalData[0][i]} <Link className=""> <img src={LearnMore} /></Link></span>
                            </li>
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  : <div>No Results Found</div>}
              </Tab>
              <Tab eventKey="table" title="Report View">
                {!delayedReportStatus ?
                  <div className="delayed-report-table">
                    <div className="delayed-report-table-internal">
                      <p className="title">Internal</p>
                      <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Unlabel</th>
                              <th>Label</th>
                              <th>Printed</th>
                              <th>Manifest</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="">
                              <td className="type">COD</td>
                              <td>{delayReportData.cod?.unlabel_delay_count || 0}</td>
                              <td>{delayReportData.cod?.label_delay_count || 0}</td>
                              <td>{delayReportData.cod?.printed_delay_count || 0}</td>
                              <td>{delayReportData.cod?.manifest_delay_count || 0}</td>
                              <td className="action"><Link to={'/orders/all-orders?payment_type=cod'}><img src={ArrowImg} /></Link></td>
                            </tr>
                            <tr>
                              <td className="type">Prepaid</td>
                              <td>{delayReportData.prepaid?.unlabel_delay_count || 0}</td>
                              <td>{delayReportData.prepaid?.label_delay_count || 0}</td>
                              <td>{delayReportData.prepaid?.printed_delay_count || 0}</td>
                              <td>{delayReportData.prepaid?.manifest_delay_count || 0}</td>
                              <td className="action"><Link to={'/orders/all-orders?payment_type=prepaid'}><img src={ArrowImg} /></Link></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="delayed-report-table-external">
                      <p className="title">External</p>

                      <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Intransit</th>
                              <th>Delivered</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="intransit">
                              <td className="type">COD</td>
                              <td>{delayReportData.cod?.in_transit_delay_count || 0}</td>
                              <td>{delayReportData.cod?.delivered_delay_count || 0}</td>
                              <td className="action"><button><img src={ArrowImg} /></button></td>
                            </tr>
                            <tr>
                              <td className="type">Prepaid</td>
                              <td>{delayReportData.prepaid?.in_transit_delay_count || 0}</td>
                              <td>{delayReportData.prepaid?.delivered_delay_count || 0}</td>
                              <td className="action"><button><img src={ArrowImg} /></button></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  : <div>No Results Found</div>}
              </Tab>
            </Tabs>

          </div>
        </div>
        <div className="chart-items">
          <div className="zone-wise-tat chart-item">
            <h2 className="head-title">Zone wise TAT Performance</h2>
            <Tabs
              id="charts-toggle-tab"
              className="mb-3 charts-toggle-tab"
            >
              <Tab eventKey="home" title="Chart View">
                {!zoneWiseTATStatus ?
                  <div className="donet-chat-list">
                    {
                      Object.keys(zoneWiseTAT).map((i, k) => <DonutChart key={k} data={zoneWiseTAT[i]} options={zoneWiseTATOption} className='dashboard-donet zone-donet' showDom={<p className="showZone">{i}</p>} />)
                    }
                  </div>
                  : <div>No Results Found</div>}
              </Tab>
              <Tab eventKey="profile" title="Report View">
                {!zoneWiseTATStatus ? zoneWiseTATTable ? <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>In TAT</th>
                        <th>Out TAT</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        Object.keys(zoneWiseTATTable || {})?.map((i, k) => {
                          let cod = _.groupBy(zoneWiseTATTable[i], "paymentType")["COD"] || [];
                          let prepaid = _.groupBy(zoneWiseTATTable[i], "paymentType")["prepaid"] || [];
                          cod = cod.length > 0 ? cod[0] : {};
                          prepaid = prepaid.length > 0 ? prepaid[0] : {};
                          return <Fragment key={k}>
                            <tr className="">
                              <td rowSpan="2" className="status-title">{i}</td>
                              <td className="type">COD</td>
                              <td>{cod.onTime_percentage || 0}%</td>
                              <td>{cod.delayed_percentage || 0}%</td>
                              <td rowSpan="2" className="action"><button><img src={ArrowImg} /></button></td>
                            </tr>
                            <tr>
                              <td className="type">Prepaid</td>
                              <td>{prepaid.onTime_percentage || 0}%</td>
                              <td>{prepaid.delayed_percentage || 0}%</td>
                            </tr>
                          </Fragment>
                        })
                      }
                    </tbody>
                  </table>
                </div>
                  : "Loading..." : <div>No Results Found</div>}
              </Tab>
            </Tabs>

          </div>
          <div className="shipment-overview chart-item">
            <h2 className="head-title">Shipment overview past 5 days</h2>
            <Tabs
              id="charts-toggle-tab"
              className="mb-3 charts-toggle-tab shipment-overview-chart"
            >
              <Tab eventKey="home" title="Chart View" className="shipment-over-view">

                {shipmentOverviewSeries.length > 0
                  ? <>
                    <p className="titles"><span>Date</span> <span>Orders Picked</span></p>
                    <p className="values">
                      <span>{shipmentOverviewCount[0]}</span>
                      <span>{shipmentOverviewCount[1]}</span>
                      <span>{shipmentOverviewCount[2]}</span>
                      <span>{shipmentOverviewCount[3]}</span>
                      <span>{shipmentOverviewCount[4]}</span>

                    </p>
                    <div className="shipment-overview-chart-dashboard">
                      <HorizontalStackedBarChart isTotal={true} shipmentOverviewSeries={shipmentOverviewSeries} className='' />
                    </div>
                  </>
                  :
                  <div>No Results Found</div>}

              </Tab>
              <Tab eventKey="profile" title="Report View">
                {true
                  ? <div className="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          {["Intransit", "Delivered", "Delayed", "Returned"]?.map((i, k) => <th key={k}>{i}</th>)}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          shipmentOverviewSorted.map((i, k) => {
                            return <Fragment key={k}>
                              <tr className="">
                                <td rowSpan="2" className="status-title">{moment(i.date).format("DD/MM")}</td>
                                <td className="type">COD</td>
                                <td>{i?.cod?.inTransit ?? 0}</td>
                                <td>{i?.cod?.delivered ?? 0}</td>
                                <td>{i?.cod?.delayed ?? 0}</td>
                                <td>{i?.cod?.returned ?? 0}</td>
                                <td rowSpan="2" className="action"><button><img src={ArrowImg} /></button></td>
                              </tr>
                              <tr>
                                <td className="type">Prepaid</td>
                                <td>{i?.prepaid?.inTransit ?? 0}</td>
                                <td>{i?.prepaid?.delivered ?? 0}</td>
                                <td>{i?.prepaid?.delayed ?? 0}</td>
                                <td>{i?.prepaid?.returned ?? 0}</td>
                              </tr>
                            </Fragment>
                          })
                        }
                      </tbody>
                    </table>
                  </div> :
                  <div>No Results Found</div>}
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="chart-items">
          <div className="average-delivery-time chart-item">
            <h2 className="head-title">Average Delivery Time</h2>
            <Tabs
              id="charts-toggle-tab"
              className="mb-3 charts-toggle-tab"
            >
              <Tab eventKey="home" title="Chart View">
                {!averageDTStatus ? <BarChart barChartData={averageDeliveryTime} />
                  : <div>No Results Found</div>}
              </Tab>
              <Tab eventKey="profile" title="Report View">
                <div className="table-container">
                  {!averageDTStatus ?
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>COD</th>
                          <th>Prepaid</th>
                        </tr>
                      </thead>
                      <tbody>
                        {

                          Object.keys(averageDeliveryTimeTableOrder).map((i, k) => {
                            if (i === "total_delivered") {
                              i = "totalDelivered"
                            }
                            return <tr key={k} className="">
                              <td className="type">{averageDeliveryTimeTableOrder[i]}</td>
                              <td>{averageDeliveryTimeTable[0][averageDeliveryTimeTableOrder[i]]}</td>
                              <td>{averageDeliveryTimeTable[1][averageDeliveryTimeTableOrder[i]]}</td>
                            </tr>
                          })
                        }

                        {
                          // averageDeliveryTimeTable.map((i, k) => {
                          //   return <Fragment key={k}>
                          //     <tr className="">
                          //       <td className="type">{i}</td>
                          //       <td>10</td>
                          //       <td>12</td>
                          //     </tr>
                          //   </Fragment>
                          // })
                        }
                      </tbody>
                    </table>
                    : <div>No Results Found</div>}
                </div>
              </Tab>
            </Tabs>

          </div>
          <div className="delivery-attempts chart-item">
            <h2 className="head-title">Order Delivered</h2>
            <Tabs
              id="charts-toggle-tab"
              className="mb-3 charts-toggle-tab delivery-attempts-chart"
            >
              <Tab eventKey="home" title="Chart View">
                {shipmentDeliveredData.length > 0 ? <div className="donet-dashboard">
                  <GradientDonutChart options={{
                    data: shipmentDeliveredData,
                    arcLabelEnable: true
                  }} />
                  {/* <DonutChart options={{ data: zoneWiseTATData, totalText: "Total Orders", isTotal: true }} className='dashboard-donet' /> */}
                </div>
                  : <div>No Results Found</div>}
              </Tab>
              <Tab eventKey="profile" title="Report View">
                {shipmentDeliveredTable.length ? <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>COD</th>
                        <th>Prepaid</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        shipmentDeliveredTable?.map((i, k) => {
                          return <tr key={k} className="intransit">
                            <td className="type">{i.zone}</td>
                            <td>{i.cod}</td>
                            <td>{i.prepaid}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
                  : <div>No Results Found</div>}
              </Tab>
            </Tabs>
          </div>
        </div></>
        : <>
          <div className="chart-items">
            <div className="chart-item high-order-volume">
              <h2 className="head-title">High Order volume Cities</h2>
              <p className="h-line"></p>
              <div className="high-order-volume-container">
                <table className="high-order-volume-table">
                  <thead>
                    <tr>
                      <td>Cities</td>
                      <td>Order Count</td>
                      <td>Proportion (in Total)</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      ordersFromTopCities.map((i, k) => <tr className={`color-${k}`} key={k}>
                        <td>{i.city}</td>
                        <td>{i.order_count}</td>
                        <td>{i.proportion}%</td>
                      </tr>)
                    }

                  </tbody>
                </table>
              </div>
            </div>
            <div className="chart-item">
              <h2 className="head-title">Courier Split</h2>
              <GradientDonutChart options={{
                data: courierSplitData,
                className: 'courier-split',
                margin: { top: 0, right: 164, bottom: 20, left: 165 },
                customLegend: true,
              }
              } />
            </div>
          </div>
          <div className="chart-items">
            <div className="chart-item">
              <h2 className="head-title">Overall Shipment Status</h2>
              <GradientDonutChart options={{
                data: overallShipmentStatusData,
                className: 'courier-split full-w-wrap',
                margin: { top: 0, right: 164, bottom: 20, left: 165 },
                customLegend: true,
              }
              } />
            </div>
            <div className="chart-item">
              <h2 className="head-title">Cancellation Reason</h2>
              <GradientDonutChart options={{
                data: cancelledReasonsData,
                className: 'courier-split full-w-wrap',
                margin: { top: 0, right: 164, bottom: 20, left: 165 },
                customLegend: true,
              }
              } />
            </div>
          </div>
        </>

      }

    </div>
  );
}
export default ChartsContainers;