import React from "react";
import SideMenu from "../sideMenu/sideMenu";
import Body from "./body";
import OrdersTabMenu from "../ordersTabMenu/OrdersTabMenu";
import jwt_decode from 'jwt-decode' // import dependency
import moment from 'moment';

const PageLayout = ({ tabMenu, body, selectedItem , quickActionClick, isQuick, returnTabMenu}) => {

    if (localStorage.getItem('deyga-token') !== "" && localStorage.getItem('deyga-token') !== null) {
        const user = jwt_decode(localStorage.getItem('deyga-token') );
        if(moment(user.exp*1000).isBefore(moment())){
          localStorage.clear();
          window.location.href = "/";
        }
    }
    return (
        <>
            <div className="main-container" style={{marginTop:"-50px"}}>
                <SideMenu />
                {!tabMenu && !returnTabMenu && <Body body={body} />}
                {tabMenu && <OrdersTabMenu body={body} isQuick={isQuick} quickActionClick={quickActionClick} selectedItem={selectedItem} />}
            </div>

        </>
    )
}

export default PageLayout;