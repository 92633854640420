import React from 'react';
import Chart from 'react-apexcharts';
import './horizontalStackedBarChart.scss'
import moment from 'moment';
const HorizontalStackedBarChart = ({shipmentOverviewSeries}) => {
    // Calculate the total for each category
    // shipmentOverviewSeries = [{
    //     name: 'Intransit',
    //     data: [44, 55, 41, 37, 22]
    // }, {
    //     name: 'Delivered',
    //     data: [53, 32, 33, 52, 13]
    // }, {
    //     name: 'Delayed',
    //     data: [12, 17, 11, 9, 15]
    // }, {
    //     name: 'Return',
    //     data: [9, 7, 5, 8, 6]
    // }
    // ];
    const options = {
        colors :['#F1753F','#043039','#F4DCB6','#8A8A8A'],
        chart: {
            type: 'bar',
            height: 400,
            stacked: true,
            stackType: '100%',
            toolbar: {
                show: false, // Show the toolbar
                tools: {
                    download: false, // Hide the download button
                },
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                // columnWidth: '100',
                barHeight: '100%',
                dataLabels: {
                    position: 'top',
                  },
            },
        },
        stroke: {
            width: 1,
            height:1,
            colors: ['#fff']
        },
        title: {
            //   text: '100% Stacked Bar'
        },
        dataLabels: {
            enabled: false,
            // offsetX: 11,
            textAnchor: 'start',
            formatter: function (val, opts) {
                const seriesIndex = opts.seriesIndex;
                const dataPointIndex = opts.dataPointIndex;

                // Calculate the total for this group
                const total = opts.w.globals.stackedSeriesTotals[dataPointIndex];

                // Only show the total on the last stack of each group
                if (seriesIndex === opts.w.globals.series.length - 1) {
                    return total;
                } else {
                    return '';
                }
            },
            // offsetY: -20, // Position the labels above the bars
            // offsetX: 30, // Position the labels above the bars
            style: {
                fontSize: '12px',
                colors: ['#000'], // You can customize the color
            },
        },
        xaxis: {
            categories: [moment().format("DD/MM"), moment().subtract(1, "days").format("DD/MM"), moment().subtract(2, "days").format("DD/MM"), moment().subtract(3, "days").format("DD/MM"), moment().subtract(4, "days").format("DD/MM")],
            labels: {
                show: false, // Hide x-axis labels
            },
            axisBorder: {
                show: false, // Hide x-axis border
            },
            axisTicks: {
                show: false, // Hide x-axis ticks
            },
        },
        // yaxis: {
        //     title: {
        //         text: 'Total',
        //     },
        //     labels: {
        //         show: true, // Show y-axis labels
        //     },
        //     axisBorder: {
        //         show: true, // Show y-axis border
        //     },
        //     axisTicks: {
        //         show: true, // Show y-axis ticks
        //     },
        // },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "K"
                }
            }
        },
        fill: {
            opacity: 1

        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 40
        },
        grid: {
            show: false,
        },

    };

    
    return <Chart options={options} series={shipmentOverviewSeries} type="bar" height={300} />;
};

export default HorizontalStackedBarChart;
