import React from 'react';
import PageLayout from '../../../global/partialBase/pageLayout';
import AllOrdersBody from './body';

const AllOrders = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<AllOrdersBody />} selectedItem={"All Orders"}/>
        </div>
    )
}

export default AllOrders;