const storage_constant = {token: 'deyga-token',user_data: 'user_data'}

export const getUserData = () => {
  const data = localStorage.getItem(storage_constant.user_data);
  const token = localStorage.getItem(storage_constant.token);

  return { data, token }
}
// const chartData = {
//     "top_product": [
//         {
//             "product": "Acne Vanishing Combo",
//             "price": "510449.55"
//         },
//         {
//             "product": "Acne Relief Combo  Test",
//             "price": "335225.55"
//         },
//         {
//             "product": "Anti Acne kit",
//             "price": "266937.0"
//         },
//         {
//             "product": "Adequate Skin Hydrators Combo",
//             "price": "222276.0"
//         },
//         {
//             "product": "Hair Strengthening Combo",
//             "price": "167860.0"
//         }
//     ],
//     "delayed_report": {
//         "total_delay": {
//             "payment_type": "All",
//             "internal_delay": 1842,
//             "external_delay_percentage": 1,
//             "delivery_name": "Total",
//             "external_delay": 25,
//             "internal_delay_percentage": 99
//         },
//         "delayed_by_category": [
//             {
//                 "payment_type": "Prepaid",
//                 "delivery_name": "BlueDart",
//                 "manifest_delay_count": 475,
//                 "printed_delay_count": 278,
//                 "delivered_delay_count": 0,
//                 "unlabel_delay_count": 0,
//                 "internal_delay": 755,
//                 "external_delay_percentage": 3,
//                 "in_transit_delay_count": 20,
//                 "external_delay": 20,
//                 "label_delay_count": 2,
//                 "internal_delay_percentage": 97
//             },
//             {
//                 "payment_type": "COD",
//                 "delivery_name": "Delhivery",
//                 "manifest_delay_count": 53,
//                 "printed_delay_count": 12,
//                 "delivered_delay_count": 0,
//                 "unlabel_delay_count": 13,
//                 "internal_delay": 66,
//                 "external_delay_percentage": 1,
//                 "in_transit_delay_count": 1,
//                 "external_delay": 1,
//                 "label_delay_count": 21,
//                 "internal_delay_percentage": 99
//             },
//             {
//                 "payment_type": "COD",
//                 "delivery_name": "BlueDart",
//                 "manifest_delay_count": 434,
//                 "printed_delay_count": 295,
//                 "delivered_delay_count": 0,
//                 "unlabel_delay_count": 6,
//                 "internal_delay": 739,
//                 "external_delay_percentage": 0,
//                 "in_transit_delay_count": 2,
//                 "external_delay": 2,
//                 "label_delay_count": 4,
//                 "internal_delay_percentage": 100
//             },
//             {
//                 "payment_type": "Prepaid",
//                 "delivery_name": "DTDC",
//                 "manifest_delay_count": 38,
//                 "printed_delay_count": 23,
//                 "delivered_delay_count": 0,
//                 "unlabel_delay_count": 0,
//                 "internal_delay": 61,
//                 "external_delay_percentage": 3,
//                 "in_transit_delay_count": 2,
//                 "external_delay": 2,
//                 "label_delay_count": 0,
//                 "internal_delay_percentage": 97
//             },
//             {
//                 "payment_type": "Prepaid",
//                 "delivery_name": "Delhivery",
//                 "manifest_delay_count": 100,
//                 "printed_delay_count": 0,
//                 "delivered_delay_count": 0,
//                 "unlabel_delay_count": 0,
//                 "internal_delay": 100,
//                 "external_delay_percentage": 0,
//                 "in_transit_delay_count": 0,
//                 "external_delay": 0,
//                 "label_delay_count": 0,
//                 "internal_delay_percentage": 100
//             },
//             {
//                 "payment_type": "COD",
//                 "delivery_name": "Ekart",
//                 "manifest_delay_count": 7,
//                 "printed_delay_count": 6,
//                 "delivered_delay_count": 0,
//                 "unlabel_delay_count": 0,
//                 "internal_delay": 13,
//                 "external_delay_percentage": 0,
//                 "in_transit_delay_count": 0,
//                 "external_delay": 0,
//                 "label_delay_count": 0,
//                 "internal_delay_percentage": 100
//             },
//             {
//                 "payment_type": "Prepaid",
//                 "delivery_name": "Ekart",
//                 "manifest_delay_count": 4,
//                 "printed_delay_count": 11,
//                 "delivered_delay_count": 0,
//                 "unlabel_delay_count": 0,
//                 "internal_delay": 15,
//                 "external_delay_percentage": 0,
//                 "in_transit_delay_count": 0,
//                 "external_delay": 0,
//                 "label_delay_count": 0,
//                 "internal_delay_percentage": 100
//             },
//             {
//                 "payment_type": "COD",
//                 "delivery_name": "Other",
//                 "manifest_delay_count": 2,
//                 "printed_delay_count": 3,
//                 "delivered_delay_count": 0,
//                 "unlabel_delay_count": 0,
//                 "internal_delay": 5,
//                 "external_delay_percentage": 0,
//                 "in_transit_delay_count": 0,
//                 "external_delay": 0,
//                 "label_delay_count": 0,
//                 "internal_delay_percentage": 100
//             },
//             {
//                 "payment_type": "Prepaid",
//                 "delivery_name": "DHL",
//                 "manifest_delay_count": 81,
//                 "printed_delay_count": 6,
//                 "delivered_delay_count": 0,
//                 "unlabel_delay_count": 0,
//                 "internal_delay": 87,
//                 "external_delay_percentage": 0,
//                 "in_transit_delay_count": 0,
//                 "external_delay": 0,
//                 "label_delay_count": 0,
//                 "internal_delay_percentage": 100
//             },
//             {
//                 "payment_type": "Prepaid",
//                 "delivery_name": "Other",
//                 "manifest_delay_count": 1,
//                 "printed_delay_count": 0,
//                 "delivered_delay_count": 0,
//                 "unlabel_delay_count": 0,
//                 "internal_delay": 1,
//                 "external_delay_percentage": 0,
//                 "in_transit_delay_count": 0,
//                 "external_delay": 0,
//                 "label_delay_count": 0,
//                 "internal_delay_percentage": 100
//             }
//         ]
//     },
//     "cancelled_orders": [
//         {
//             "cancel_reason": "wrong_contact_information",
//             "details": [
//                 {
//                     "order_count": 1,
//                     "payment_type": "COD"
//                 },
//                 {
//                     "order_count": 2,
//                     "payment_type": "Prepaid"
//                 }
//             ]
//         },
//         {
//             "cancel_reason": "other",
//             "details": [
//                 {
//                     "order_count": 49,
//                     "payment_type": "Prepaid"
//                 },
//                 {
//                     "order_count": 52,
//                     "payment_type": "COD"
//                 }
//             ]
//         },
//         {
//             "cancel_reason": "cod_to_prepaid_conversion",
//             "details": [
//                 {
//                     "order_count": 1,
//                     "payment_type": "Prepaid"
//                 }
//             ]
//         },
//         {
//             "cancel_reason": "ordered_twice",
//             "details": [
//                 {
//                     "order_count": 2,
//                     "payment_type": "COD"
//                 },
//                 {
//                     "order_count": 1,
//                     "payment_type": "Prepaid"
//                 }
//             ]
//         },
//         {
//             "cancel_reason": "unservicable_pincode",
//             "details": [
//                 {
//                     "order_count": 2,
//                     "payment_type": "Prepaid"
//                 },
//                 {
//                     "order_count": 1,
//                     "payment_type": "COD"
//                 }
//             ]
//         },
//         {
//             "cancel_reason": "merge_orders",
//             "details": [
//                 {
//                     "order_count": 1,
//                     "payment_type": "Prepaid"
//                 }
//             ]
//         },
//         {
//             "cancel_reason": "no_reason",
//             "details": [
//                 {
//                     "order_count": 9,
//                     "payment_type": "Prepaid"
//                 }
//             ]
//         },
//         {
//             "cancel_reason": "others",
//             "details": [
//                 {
//                     "order_count": 1,
//                     "payment_type": "COD"
//                 },
//                 {
//                     "order_count": 1,
//                     "payment_type": "Prepaid"
//                 }
//             ]
//         },
//         {
//             "cancel_reason": "unknown",
//             "details": [
//                 {
//                     "order_count": 1,
//                     "payment_type": "Prepaid"
//                 }
//             ]
//         },
//         {
//             "cancel_reason": "customer",
//             "details": [
//                 {
//                     "order_count": 1,
//                     "payment_type": "Prepaid"
//                 }
//             ]
//         }
//     ],
//     "manifest_counts": [
//         {
//             "manifest_count": 381,
//             "courier_name": "BlueDart"
//         },
//         {
//             "manifest_count": 60,
//             "courier_name": "Delhivery"
//         },
//         {
//             "manifest_count": 12,
//             "courier_name": "DHL"
//         },
//         {
//             "manifest_count": 13,
//             "courier_name": "DTDC"
//         },
//         {
//             "manifest_count": 9,
//             "courier_name": "EKART"
//         },
//         {
//             "manifest_count": 13,
//             "courier_name": "Other"
//         }
//     ],
//     "in_transit": {
//         "pickup": 0,
//         "total_in_transit": 2,
//         "in_transit": 0,
//         "out_for_delivery": 2
//     },
//     "delivery_attempts": [
//         {
//             "total_orders": 0,
//             "attempt_category": "4+ Attempts",
//             "courier_name": "DHL"
//         },
//         {
//             "total_orders": 0,
//             "attempt_category": "2nd Attempt",
//             "courier_name": "DHL"
//         },
//         {
//             "total_orders": 4,
//             "attempt_category": "1st Attempt",
//             "courier_name": "DHL"
//         },
//         {
//             "total_orders": 0,
//             "attempt_category": "3rd Attempt",
//             "courier_name": "DHL"
//         },
//         {
//             "total_orders": 1,
//             "attempt_category": "4+ Attempts",
//             "courier_name": "BlueDart"
//         },
//         {
//             "total_orders": 2,
//             "attempt_category": "2nd Attempt",
//             "courier_name": "BlueDart"
//         },
//         {
//             "total_orders": 3,
//             "attempt_category": "1st Attempt",
//             "courier_name": "BlueDart"
//         },
//         {
//             "total_orders": 3,
//             "attempt_category": "3rd Attempt",
//             "courier_name": "BlueDart"
//         },
//         {
//             "total_orders": 21,
//             "attempt_category": "4+ Attempts",
//             "courier_name": "Delhivery"
//         },
//         {
//             "total_orders": 11,
//             "attempt_category": "2nd Attempt",
//             "courier_name": "Delhivery"
//         },
//         {
//             "total_orders": 32,
//             "attempt_category": "1st Attempt",
//             "courier_name": "Delhivery"
//         },
//         {
//             "total_orders": 41,
//             "attempt_category": "3rd Attempt",
//             "courier_name": "Delhivery"
//         },
//         {
//             "total_orders": 0,
//             "attempt_category": "4+ Attempts",
//             "courier_name": "Other"
//         },
//         {
//             "total_orders": 0,
//             "attempt_category": "2nd Attempt",
//             "courier_name": "Other"
//         },
//         {
//             "total_orders": 2,
//             "attempt_category": "1st Attempt",
//             "courier_name": "Other"
//         },
//         {
//             "total_orders": 0,
//             "attempt_category": "3rd Attempt",
//             "courier_name": "Other"
//         }
//     ],
//     "avg_sales": 2128.34,
//     "ndrOrderCounts": {
//         "pickup": 1,
//         "delivered": 4,
//         "not_delivered": 0,
//         "total_ndr_orders": 20,
//         "in_transit": 15
//     },
//     "average_delivery_time": {
//         "delhivery": {
//             "14+ days": 3,
//             "10-12 days": 2,
//             "0-2 days": 2,
//             "4-6 days": 1,
//             "12-14 days": 2,
//             "8-10 days": 4,
//             "2-4 days": 7,
//             "6-8 days": 2
//         }
//     },
//     "total_orders": 1242,
//     "new_orders": {
//         "domestic_cod": 131,
//         "domestic_prepaid": 82,
//         "International": 5
//     },
//     "shipment_carrier": [
//         {
//             "count": "1611",
//             "name": "bluedart"
//         },
//         {
//             "count": "188",
//             "name": "delhivery"
//         },
//         {
//             "count": "63",
//             "name": "dtdc"
//         },
//         {
//             "count": "38",
//             "name": "other"
//         },
//         {
//             "count": "33",
//             "name": "ekart"
//         },
//         {
//             "count": "84",
//             "name": "dhl"
//         }
//     ],
//     "sales": [
//         {
//             "start_time": "0:00",
//             "total": 0,
//             "end_time": "3:00"
//         },
//         {
//             "start_time": "3:00",
//             "total": 0,
//             "end_time": "6:00"
//         },
//         {
//             "start_time": "6:00",
//             "total": 0,
//             "end_time": "9:00"
//         },
//         {
//             "start_time": "9:00",
//             "total": 0,
//             "end_time": "12:00"
//         },
//         {
//             "start_time": "12:00",
//             "total": 0,
//             "end_time": "15:00"
//         },
//         {
//             "start_time": "15:00",
//             "total": 0,
//             "end_time": "18:00"
//         },
//         {
//             "start_time": "18:00",
//             "total": 0,
//             "end_time": "21:00"
//         },
//         {
//             "start_time": "21:00",
//             "total": 0,
//             "end_time": "24:00"
//         }
//     ],
//     "delivered_orders": [
//         {
//             "delayed_count": 2,
//             "ontime_count": 2
//         }
//     ],
//     "perpaid_order": 621,
//     "cod_order": 621,
//     "in_progress": {
//         "printed": 358,
//         "total_in_progress": 364,
//         "labelled": 6
//     },
//     "shipped": 2017,
//     "zone_delivery_attempts": [
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "E",
//             "delivery_attempts": 4
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 1,
//             "zone": "B",
//             "delivery_attempts": 1
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "B",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "E",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "B",
//             "delivery_attempts": 3
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "E",
//             "delivery_attempts": 3
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "C",
//             "delivery_attempts": 1
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "C",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "C",
//             "delivery_attempts": 3
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "C",
//             "delivery_attempts": 4
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "D",
//             "delivery_attempts": 3
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "D",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "D",
//             "delivery_attempts": 4
//         },
//         {
//             "payment_type": "prepaid",
//             "order_count": 1,
//             "zone": "B",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "E",
//             "delivery_attempts": 1
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "D",
//             "delivery_attempts": 1
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "B",
//             "delivery_attempts": 1
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "B",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "B",
//             "delivery_attempts": 3
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "B",
//             "delivery_attempts": 4
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "B",
//             "delivery_attempts": 4
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "C",
//             "delivery_attempts": 4
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "D",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "C",
//             "delivery_attempts": 3
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "D",
//             "delivery_attempts": 1
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "A",
//             "delivery_attempts": 3
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "A",
//             "delivery_attempts": 4
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "A",
//             "delivery_attempts": 1
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "A",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "prepaid",
//             "order_count": 1,
//             "zone": "C",
//             "delivery_attempts": 1
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "D",
//             "delivery_attempts": 4
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "E",
//             "delivery_attempts": 4
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "D",
//             "delivery_attempts": 3
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "E",
//             "delivery_attempts": 3
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "E",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "E",
//             "delivery_attempts": 1
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "A",
//             "delivery_attempts": 4
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "A",
//             "delivery_attempts": 3
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "A",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "Prepaid",
//             "order_count": 0,
//             "zone": "A",
//             "delivery_attempts": 1
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "C",
//             "delivery_attempts": 2
//         },
//         {
//             "payment_type": "COD",
//             "order_count": 0,
//             "zone": "C",
//             "delivery_attempts": 1
//         }
//     ],
//     "recent_order": [
//         {
//             "price": "890.0",
//             "order_number": "2333",
//             "order_id": "5876529725689",
//             "order_name": "#2333"
//         },
//         {
//             "price": "640.0",
//             "order_number": "2332",
//             "order_id": "5876231340281",
//             "order_name": "#2332"
//         },
//         {
//             "price": "640.0",
//             "order_number": "2331",
//             "order_id": "5876230815993",
//             "order_name": "#2331"
//         },
//         {
//             "price": "840.0",
//             "order_number": "2330",
//             "order_id": "5871846293753",
//             "order_name": "#2330"
//         },
//         {
//             "price": "840.0",
//             "order_number": "2329",
//             "order_id": "5871846064377",
//             "order_name": "#2329"
//         }
//     ],
//     "orders": [
//         {
//             "date": "2024-11-20",
//             "order_count": 0,
//             "return_count": 0
//         },
//         {
//             "date": "2024-11-19",
//             "order_count": 0,
//             "return_count": 0
//         },
//         {
//             "date": "2024-11-18",
//             "order_count": 0,
//             "return_count": 0
//         },
//         {
//             "date": "2024-11-17",
//             "order_count": 0,
//             "return_count": 0
//         },
//         {
//             "date": "2024-11-16",
//             "order_count": 0,
//             "return_count": 0
//         },
//         {
//             "date": "2024-11-15",
//             "order_count": 0,
//             "return_count": 0
//         },
//         {
//             "date": "2024-11-14",
//             "order_count": 0,
//             "return_count": 0
//         }
//     ],
//     "zone_performance_data": {
//         "Delhivery": [
//             {
//                 "shipping_carrier_name": "Delhivery",
//                 "returned_orders": 0,
//                 "delivery_attempts": 1,
//                 "in_tat_orders": 2,
//                 "delayed_orders": 3,
//                 "avg_tat": 20.6,
//                 "total_orders": 5,
//                 "delivered_orders": 5,
//                 "payment_type": "COD",
//                 "in_transit_orders": 0,
//                 "zone": "B",
//                 "out_tat_orders": 3,
//                 "in_tat_percentage": 40,
//                 "out_tat_percentage": 60
//             },
//             {
//                 "shipping_carrier_name": "Delhivery",
//                 "returned_orders": 0,
//                 "delivery_attempts": 2,
//                 "in_tat_orders": 1,
//                 "delayed_orders": 2,
//                 "avg_tat": 21.33,
//                 "total_orders": 4,
//                 "delivered_orders": 3,
//                 "payment_type": "prepaid",
//                 "in_transit_orders": 1,
//                 "zone": "B",
//                 "out_tat_orders": 2,
//                 "in_tat_percentage": 25,
//                 "out_tat_percentage": 50
//             },
//             {
//                 "shipping_carrier_name": "Delhivery",
//                 "returned_orders": 0,
//                 "delivery_attempts": 1,
//                 "in_tat_orders": 0,
//                 "delayed_orders": 1,
//                 "avg_tat": 658,
//                 "total_orders": 1,
//                 "delivered_orders": 1,
//                 "payment_type": "prepaid",
//                 "in_transit_orders": 0,
//                 "zone": "C",
//                 "out_tat_orders": 1,
//                 "in_tat_percentage": 0,
//                 "out_tat_percentage": 100
//             },
//             {
//                 "shipping_carrier_name": "Delhivery",
//                 "returned_orders": 0,
//                 "delivery_attempts": 1,
//                 "in_tat_orders": 0,
//                 "delayed_orders": 1,
//                 "avg_tat": 658,
//                 "total_orders": 1,
//                 "delivered_orders": 1,
//                 "payment_type": "COD",
//                 "in_transit_orders": 0,
//                 "zone": "C",
//                 "out_tat_orders": 1,
//                 "in_tat_percentage": 0,
//                 "out_tat_percentage": 100
//             },
//             {
//                 "shipping_carrier_name": "Delhivery",
//                 "returned_orders": 0,
//                 "delivery_attempts": 1,
//                 "in_tat_orders": 2,
//                 "delayed_orders": 3,
//                 "avg_tat": 20.6,
//                 "total_orders": 5,
//                 "delivered_orders": 5,
//                 "payment_type": "COD",
//                 "in_transit_orders": 0,
//                 "zone": "D",
//                 "out_tat_orders": 3,
//                 "in_tat_percentage": 40,
//                 "out_tat_percentage": 60
//             },
//             {
//                 "shipping_carrier_name": "Delhivery",
//                 "returned_orders": 0,
//                 "delivery_attempts": 2,
//                 "in_tat_orders": 1,
//                 "delayed_orders": 2,
//                 "avg_tat": 21.33,
//                 "total_orders": 4,
//                 "delivered_orders": 3,
//                 "payment_type": "prepaid",
//                 "in_transit_orders": 1,
//                 "zone": "D",
//                 "out_tat_orders": 2,
//                 "in_tat_percentage": 25,
//                 "out_tat_percentage": 50
//             },
//             {
//                 "shipping_carrier_name": "Delhivery",
//                 "returned_orders": 0,
//                 "delivery_attempts": 1,
//                 "in_tat_orders": 0,
//                 "delayed_orders": 1,
//                 "avg_tat": 658,
//                 "total_orders": 1,
//                 "delivered_orders": 1,
//                 "payment_type": "prepaid",
//                 "in_transit_orders": 0,
//                 "zone": "E",
//                 "out_tat_orders": 1,
//                 "in_tat_percentage": 0,
//                 "out_tat_percentage": 100
//             },
//             {
//                 "shipping_carrier_name": "Delhivery",
//                 "returned_orders": 0,
//                 "delivery_attempts": 1,
//                 "in_tat_orders": 0,
//                 "delayed_orders": 1,
//                 "avg_tat": 658,
//                 "total_orders": 1,
//                 "delivered_orders": 1,
//                 "payment_type": "COD",
//                 "in_transit_orders": 0,
//                 "zone": "E",
//                 "out_tat_orders": 1,
//                 "in_tat_percentage": 0,
//                 "out_tat_percentage": 100
//             },
//             {
//                 "shipping_carrier_name": "Delhivery",
//                 "returned_orders": 1,
//                 "delivery_attempts": 1,
//                 "in_tat_orders": 0,
//                 "delayed_orders": 4,
//                 "avg_tat": 658,
//                 "total_orders": 1,
//                 "delivered_orders": 2,
//                 "payment_type": "prepaid",
//                 "in_transit_orders": 4,
//                 "zone": "F",
//                 "out_tat_orders": 3,
//                 "in_tat_percentage": 0,
//                 "out_tat_percentage": 100
//             },
//             {
//                 "shipping_carrier_name": "Delhivery",
//                 "returned_orders": 1,
//                 "delivery_attempts":9,
//                 "in_tat_orders": 0,
//                 "delayed_orders": 3,
//                 "avg_tat": 658,
//                 "total_orders": 2,
//                 "delivered_orders": 5,
//                 "payment_type": "COD",
//                 "in_transit_orders": 4,
//                 "zone": "F",
//                 "out_tat_orders": 1,
//                 "in_tat_percentage": 0,
//                 "out_tat_percentage": 100
//             }
//         ]
//     },
//     "total_sales": 2643401.85,
//     "customer": 611833
// }
const initialState = {
  user:{
    details:getUserData(),
    permissions:[],    
  },
  chartData : {
    loader:false,
    data: [],//chartData,
    deliveryAttempts:[],
    shipmentOverview:[],
    shipmentDelivered:[],
    shipmentDeliveredTable:[],
    deliveryAttemptsTable:[],
    overallShipmentStatus:[],
    ordersCourierSplit:[],
    ordersFromTopCities:[],
    cancelledReasons:[],
    zoneWiseTatPerformance:[],
    zoneWiseTatPerformanceTable:[],
    zoneWisePerformance:[]
  }
};

export default initialState;
