import React, { useState } from "react";
import './sideMenu.scss';
import logo from '../../../assets/images/sidebar/logo.png';
// import item1 from '../../../assets/images/sidebar/item1.png';
// import item2 from '../../../assets/images/sidebar/item2.png';
// import item3 from '../../../assets/images/sidebar/item3.png';
// import item4 from '../../../assets/images/sidebar/item4.png';
// import item5 from '../../../assets/images/sidebar/item5.png';
// import item6 from '../../../assets/images/sidebar/item6.png';
import bottom from '../../../assets/images/sidebar/bottom.png'
import { SideBarMenu } from "../../../constants";

import LogOut from '../../../assets/logout.svg';

import {
    Link,
    useNavigate
    // , useNavigate 
} from 'react-router-dom';
import SuccessToast from "../../customToast/SuccessToast";
import { Slide, toast } from "react-toastify";

const SideMenu = () => {
    const navigate = useNavigate();
    const [sideMenuItem,] = useState([...SideBarMenu]);
    const [logoutShow, setLogoutShow] = useState(false);
    const Logout = () => {
        localStorage.clear();


        toast(<SuccessToast body={'Successfully logged out'} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
        });

        setTimeout(() => {
            navigate('/')
        }, 1000);
    }

    return (
        <div className={`side-menu ${location.href.search("settings") > -1 && 'border-right'}`}>
            <div className="pos-fixed">
                <div className="top-container">
                    <img src={logo} />
                </div>
                <div className="middle-container">
                    <div className="middle-contrainer-1">
                        <div className="m-container">
                            {
                                sideMenuItem.map((i, k) => <div key={k} className={`item ${location.pathname.includes(`/${i.menu.toLowerCase()}`) && 'active'}`}>
                                    <Link to={i.url}> <img src={i.icon} /> </Link>
                                </div>)
                            }

                            {/* <div className="item">
                            <img src={item2} />
                        </div>
                        <div className="item">
                            <img src={item3} />
                        </div>
                        <div className="item">
                            <img src={item4} />
                        </div>
                        <div className="item">
                            <img src={item5} />
                        </div>
                        <div className="item">
                            <img src={item6} />
                        </div> */}
                        </div>
                    </div>
                </div>

                <div className="bottom-container curser-pointer">
                    <div className={`profile-container-logout ${!logoutShow && 'd-none'}`} >
                        <Link to={''} className="menu-item" id="logout-id" onClick={Logout}>
                            <img src={LogOut} /> Log out
                        </Link>
                    </div>
                    <img src={bottom} onClick={() => setLogoutShow(!logoutShow)} />
                </div>
            </div>
        </div>
    )

}

export default SideMenu;