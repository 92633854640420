import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import IconEmail from './../../assets/login/IconEmail.svg'
import IconPassword from './../../assets/login/IconPassword.svg'
import HeaderLogo from '../../assets/logo/logo.png';
//import MainLogo from '../../assets/logo/login-logo.png';
import { postData } from '../../helpers/PostData';
import { Slide, toast } from "react-toastify";
import ErrorToast from '../customToast/ErrorToast';
import SuccessToast from '../customToast/SuccessToast';
import { userThunks } from '../../store/users';
import { useDispatch } from "react-redux";
import { AllOrderTabDetails, SideBarMenu } from '../../constants';

const Login = () => {
    const dispach = useDispatch();
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({
        username: '',
        password: '',
    });


    const [isLoggedin, setisLoggedin] = useState(false);

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { token } = useParams();

    //console.log('Token from useParams:', token);


    const handleLoginDataChange = (e) => {
        const { name, value } = e.target;
        setLoginData((oldData) => ({ ...oldData, [name]: value }));

    };

    const validate = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    }
    const loginClick = async (e) => {
        //console.log(loginData);
        e.preventDefault();
        try {

            if (loginData?.username !== '') {
                if (validate(loginData?.username)) {
                    setEmailError('')
                }
                else {
                    setEmailError('Invalid email address')
                }
            }
            else {
                setEmailError('Invalid email address')
            }
            if (loginData?.password === '') {
                setPasswordError('Password required')
            }
            else {
                setPasswordError('')
            }
            if (emailError === '' && passwordError === '' && loginData?.username !== '' && loginData?.password !== '') {

                postData("authenticate", loginData, sucessCallBack);
            }


        }
        catch (e) {
            //console.log(e);
        }
    }

    const sucessCallBack = async (data, res) => {
        //console.log(data, res);

        if (res?.status === 200) {

            //if (data?.jwttoken?.length > 0) {

            toast(<SuccessToast body={'Login successfull'} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });

            const expirationTime = 1 * 60 * 1000; // 30 minutes in milliseconds
            const tokenExpiration = new Date().getTime() + expirationTime;

            localStorage.setItem('deyga-token', res?.data?.token);
            localStorage.setItem('refreshToken', res?.data?.refreshToken);
            localStorage.setItem('username', loginData?.username);
            localStorage.setItem('userID', loginData?.id);
            localStorage.setItem("tokenExpiration", tokenExpiration);

            let data = await dispach(userThunks.getPermission());
            let permission = [...data.payload.data] || [];
            try {
                permission.push(0);
            }
            catch (e) {
                permission = [...permission]
            }
            localStorage.setItem("userPermission", permission || [])

            let finalData = SideBarMenu.filter((item) => {
                return permission.includes(item.moduleId)
            });
            if (AllOrderTabDetails.length > 0) {
                let filterData = AllOrderTabDetails.filter((item) => {
                    return permission.includes(item.moduleId)
                });
                filterData = _.orderBy(filterData, ["order"], ['asc']);
                if (filterData.length > 0) {
                    finalData.push({
                        "id": 2,
                        "menu": 'Orders',
                        "moduleId": 0,
                        "url": filterData[0]?.url,
                        "order": 2,
                        "label": "ORDERS",
                    });
                }
                if (finalData.length > 0)
                    navigate(finalData[0]?.url);
                else
                    navigate(filterData[0]?.url);
            }
        }
        else {
            toast(<ErrorToast body="Invalid Email ID or Password" />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        }
    }
    useEffect(() => {
        if (localStorage.getItem('deyga-token')) {
            setisLoggedin(true);
            navigate("/dashboard");
        } else if (token) {
            const tokendata = {
                token: token
            }
            postData("authenticatetoken", tokendata, sucessCallBack);

        }
    }, []);
    return (
        <>
            {/* {(loginStoreData?.loader) && <div className='loader'>Loading....</div>} */}
            {!isLoggedin &&
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6 loginLeftside  mt-3'>
                            <div className="offset-md-2 vh-93-8 d-flex align-items-center">
                                <div className="row">
                                    <div className='col-md-10 mb-5'>
                                        <img alt="logo" className="header-logo" src={HeaderLogo} />
                                    </div>
                                    <div className='col-md-10 mb-3'>
                                        <div className="col text-center titles">
                                            {/* <img alt="logo" className="mb-2 logo-alone" src={MainLogo} /> */}
                                            <h1 className='h1-title'>Sign In </h1>
                                            <p className='title-description'>Welcome Back! Please Sign In to Continue.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-10 mt-4'>
                                        <form onSubmit={loginClick}>
                                            <div className="form-group">
                                                <label htmlFor="username" className='mb-1 label'>Email Address</label>
                                                <div style={{ position: 'relative', top: '15px' }}>
                                                    <span className="icon"><img alt='' src={IconEmail} /></span>
                                                </div>

                                                <input onChange={handleLoginDataChange} value={loginData.username} type="text" className={emailError !== '' ? `form-control inputWithIcon is-invalid w-100` : `form-control inputWithIcon w-100`} name='username' id="username" placeholder="Enter your Email Address" />

                                                {emailError !== '' && <div className='text-danger errorMessage'>{emailError}</div>}
                                            </div>
                                            <div className='elementGap'></div>
                                            <div className="form-group" >
                                                <label htmlFor="password" className='mb-1 label'>Password</label>
                                                <div style={{ position: 'relative', top: '15px' }}>
                                                    <span className="icon">
                                                        <img alt='' src={IconPassword} />
                                                    </span>
                                                </div>

                                                <input onChange={handleLoginDataChange}
                                                    value={loginData.password}
                                                    type="password"
                                                    className={passwordError !== '' ? `form-control inputWithIcon is-invalid w-100` : `form-control inputWithIcon w-100`}
                                                    name='password'
                                                    id="password"
                                                    placeholder="Enter your Password"
                                                />


                                            </div>
                                            <div className='mb-1'></div>
                                            <div className='row'>

                                                <div className='col-md-7 '>
                                                    {passwordError !== '' && <div className='text-danger errorMessage'>{passwordError}</div>}
                                                </div>

                                                <div className='d-flex justify-content-end'>
                                                    <Link to={'/forgot-password'} className='text-primary'><span className='forgotPassword'>Forgot Password?</span></Link>
                                                </div>

                                            </div>
                                            <div className='elementGap'></div>
                                            {/* {errorMessage} */}
                                            <button type="submit" className="btn submit-btn w-100" > Log In  </button>

                                            {/* {(formErrors.username === '' && formErrors.password === '' && loginData?.username !== '' && loginData?.password !== '') ? <button type="submit" className="btn btn-primary w-100" > Log In  </button> : <button type="submit" className="btn btn-primary w-100" disabled={true}> Log In  </button>} */}

                                        </form>
                                    </div>


                                </div>


                            </div>
                        </div>
                        <div className='col-md-6 loginRightside'>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Login;