import React, {
    useState,
    useEffect,
    useRef,
    useCallback
} from 'react';
import './style.scss';
import { useSelector } from 'react-redux';
import instance from '../../../../helpers/axiosInstance';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Search from '../../../../assets/Iconsearch.svg';
import Calandar from '../../../../assets/Icondate.svg';
import ExportIcon from '../../../../assets/Iconexport.svg';
import FraudFilter from '../../../../assets/fraud-icon.png';
import FraudFilterYellow from '../../../../assets/alert-triangle-yellow.svg';
import FraudFilterGreen from '../../../../assets/alert-triangle-green.svg';
import FraudFilterRed from '../../../../assets/alert-triangle-red.svg';
import AllFilter from '../../../../assets/filter-icon.png';
import { Popup } from "../../../global/popupModal/BottomDialog";
import DataGridPagination from '../../../global/dataGridPagination';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import greenPhone from '../../../../assets/green-phone-image.svg';
import redPhone from '../../../../assets/red-phone-image.svg';
import yellowPhone from '../../../../assets/yellow-phone-image.svg';
import blackPhone from '../../../../assets/black-phone-image.svg';
import 'bootstrap-daterangepicker/daterangepicker.css';
import FilterMenu from "../../../global/filterComponent/FilterMenu";
import CallStatus from '../../../global/popupModal/CallStatus';
import { filterOptions } from './filterObj';
import { Slide, toast } from "react-toastify";
import SuccessToast from '../../../customToast/SuccessToast';
import Loader from '../../../global/loader';
import { commonServiceMethod } from '../../../../helpers/helper';
import Popover from 'react-bootstrap/Popover';

const AllOrdersBody = () => {
    let navigate = useNavigate();
    let location = useLocation();
    // let params = new URLSearchParams(location.search);
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [originalData, setOriginalData] = useState({});
    const [selectedObject, setSelectedObject] = useState([]);
    const [open, setOpen] = useState(false);

    // pagination and Filtr variables 
    // const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [loader, setLoader] = useState(false);
    // const [callRowID, setCallRowID] = useState('');
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })
    const [searchParam, setSearchParam] = useState("");
    const [columnData, setComlumnData] = useState([]);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        //console.log(isOpen);
    };
    const [fraudval, setFraudVal] = useState("");
    const [preSelectedOption, setPreSelectedOption] = useState({})
    const checkFraudAlert = (param) => {
        let a = JSON.parse(param.data?.fraud_data || '{}'), fraudVAlue = "";
        let fraud = [a?.customerCancel?.alert || 0, a?.frequency?.alert || 0, a?.order?.alert || 0, a?.repeat?.alert || 0];
        switch (fraud.filter(i => i === 1).length) {
            case 0:
                fraudVAlue = FraudFilterGreen;
                break;
            case 1:
                fraudVAlue = FraudFilterYellow;
                break;
            case 2:
                fraudVAlue = FraudFilterRed;
                break;
            default:
                fraudVAlue = FraudFilterRed;
                break;
        }
        return fraudVAlue
    }
    let columnDefsWithCall = [
        {
            headerName: 'Order Id',
            field: 'order_name',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            width: 160,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {
                // let productNames = param.data.orderProducts;
                let fraud = checkFraudAlert(param)
                return (<div>
                    <Link className='grid-link' to={`/orders/all-order/order-details/${btoa(param.data?.order_id)}`}>{param.value}</Link>
                    <img src={fraud} style={{ marginLeft: "5px" }} />
                    {/* <p className='font-insetMedium order-date'><Moment format={"DD MMM YYYY hh:mm A"}>{param.data?.order_created_at}</Moment> </p>
                    <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={
                        <Popover id="popover-basic" className='z-index-2000 produc-list'>
                            <Popover.Body>
                                {productNames?.map((i, j) => <div key={j} className='product-list-container'>
                                    <p className='p-name bold'><b>{i.product_name}</b></p>
                                    <p className='p-sku'>SKU <b>{i.sku}</b></p>
                                    <p className='p-qty'>QTY <b>{i.quantity}</b></p>
                                </div>)}
                            </Popover.Body>
                        </Popover>
                    }>
                        <p className='viewMore'>
                            View Products
                        </p>
                    </OverlayTrigger> */}
                </div>)
            }
        },
        {
            field: 'app_order_status',
            width: 120,
            wrapText: true,
            autoHeight: true,
            headerName: 'Order Status',
            cellClass: "app-order-status",
            cellRenderer: (params) => {
                let order_status_class = "";
                if (params.value.toLowerCase() === "cancel") {
                    order_status_class = "cancel";
                } else if (params.value.toLowerCase() === "refunded") {
                    order_status_class = "refund";
                } else if (params.value.toLowerCase() === "delivered") {
                    order_status_class = "delivered";
                } else {
                    order_status_class = "";
                }
                return (<span className={`default-status ${order_status_class}`}>
                    {params.value}
                </span>)
            }
        },
        {
            width: 170,
            field: 'shipping_address',
            wrapText: true,
            autoHeight: true,
            headerName: 'Recipient',
            cellClass: "d-flex-center customers",
            cellRenderer(params) {
                let address = params.data?.shipping_address;
                return (
                    <div className="address-wrap">
                        <p className="customer-name">
                            {params.data?.customer_name}
                        </p>
                        <p className="address">
                            {params.data?.customer_email}
                        </p>
                        <p className="address">
                            {address.phone ? address.phone : ''}
                        </p>
                        <p className="address">
                            {address.city ? address.city : ''} - {address.zip ? address.zip : ''}
                        </p>
                    </div>
                )
            }
        },
        {
            field: 'callStatus',
            width: 180,
            wrapText: true,
            autoHeight: true,
            headerName: 'Call Status',
            cellRenderer(param) {
                let img, text;
                switch (param.value) {
                    case "accepted":
                        img = greenPhone;
                        text = "Order Accepted ";
                        break;
                    case "declined":
                        img = redPhone;
                        text = param.data?.reason_text || "Order Declined";
                        break;
                    case "other":
                        img = yellowPhone;
                        text = param.data?.reason_text || "";
                        break;
                    default:
                        img = blackPhone;
                        text = "";
                        break;
                }
                return (<div className='call-status-block'>

                    <span onClick={() => showModal('Call', param?.data, handleCallStatusChange)} className='call-count-status'>
                        <img alt='call status' src={img} />
                        {param.data?.callCount > 0 && <span className='count'>{param.data?.callCount}</span>}
                    </span>
                    {/* <select value={param.value} onClick={() => showModal('CallUpdate', param?.data, handleStatusChange)} className="call-status form-select mb-3">
                        <option value="select">Select</option>
                        <option value="accepted">Order Accepted</option>
                        <option value="declined">Order Declined</option>
                        <option value="other">Other</option>
                    </select> */}
                    <p className={`btn btn-sm btn-primary ${param.value}`} onClick={() => showModal('CallUpdate', param?.data, handleStatusChange)}>{param.value || "Select Status"}</p>
                    {text !== "" && <p className={`status-text ${param.value}`}>{text}</p>}

                </div>)
            }
        },
        {
            headerName: 'Order Total',
            field: 'total_price',
            width: 120,
            cellClass: "d-flex-center",
            cellRenderer(params) {
                let payment_mode = "";
                let payment_status = "";
                if (params.data?.gateway === "Cash on Delivery (COD)") {
                    payment_mode = "COD";
                    payment_status = "info";
                } else {
                    payment_mode = "Pre-paid";
                    payment_status = "success";
                }
                return (
                    <div className='address-wrap'>
                        <p className="price_tag">{"Rs. " + params.value}</p>
                        <p className="price_tag">
                            <span className={payment_status}>{payment_mode}</span>
                        </p>
                    </div>
                )
            }
        },
        {
            field: 'carrier_name',
            width: 100,
            wrapText: true,
            autoHeight: true,
            headerName: 'Carrier',
            cellClass: "carrier-data",
            // cellRenderer(param) {
            //     return (<>
            //         {param?.value?.length === 0 || (param.value[0].tracking_number === null || param.value[0].tracking_number?.trim() === "") ? "-" : <>
            //             <p className='carrier'>{param.value[0].ecom_name}</p><span className={`delivery-id`}>#{param.value[0].tracking_number || ""} <img onClick={() => {
            //                 navigator.clipboard.writeText(param.value[0].tracking_number);
            //                 toast(<SuccessToast body={'Coppied'} />, {
            //                     transition: Slide,
            //                     hideProgressBar: true,
            //                     autoClose: 2000,
            //                 });
            //             }}
            //                 alt='' src={copy} /></span>
            //         </>}
            //     </>)
            // }
        },
        {
            width: 100,
            field: 'order_created_at',
            wrapText: true,
            autoHeight: true,
            headerName: 'Order Date',
            cellClass: 'order-date',
            cellRenderer(param) {
                return (<span className={`order-date`}>{moment(param.value).format("MMM DD, YYYY hh:mm")}</span>)
            }
        },
        {
            width: 120,
            field: 'order_fulfillment_status',
            wrapText: true,
            autoHeight: true,
            headerName: 'Fulfilment Status',
            cellClass: 'order-fulfillment-status',
            cellRenderer(param) {
                return (<span className={`status-value ${param.value}`}>{param.value}</span>)
            }
        },

        {
            width: 185,
            field: 'product_name',
            wrapText: true,
            autoHeight: true,
            headerName: 'Item Name',
            cellClass: 'product-name',
            cellRenderer(param) {
                return (<span title={param.value} className={``}>{param.value}</span>)
            }
        },
    ];

    const [refreshData, setRefreshData] = useState(1);
    useEffect(() => {
        if (userClaim.token !== "") {
            // let queryparam = "";
            // if (params.get('paymenttype') !== "" && params.get('paymenttype') !== null) {
            //     queryparam = params.get('paymenttype') === "cod" ? '&payment_type=cod' : '&payment_type=Pre-paid';
            // }
            // console.log(params.get('paymenttype'), queryparam, "queryparam")
            // getData("", pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText, queryparam);
            // if (true) {
            //     setComlumnData([...columnDefs]);
            // }
            // else
            setComlumnData([...columnDefsWithCall]);
        }
    }, [userClaim]);

    const onClearFilter = () => {
        setSearchText("");
        setSearchParam("");
        toggleDropdown();
        navigate(`${location.pathname}`);

    }
    const showModal = (popupName, data, callBackMethod) => {

        // //console.log(string, value);
        // setCallDetails(string);
        // setCallRowID(value)
        setPopupData({ popupName, data, callBackMethod })
    }

    const closeModal = () => {
        // setCallDetails('');
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }


    const handleStatusChange = (val, obj) => {
        //console.log(val, obj)
        setCallStatus(val, obj);
    }

    const setCallStatus = async (status, item) => {
        let url = `app/order/update/callStatus`;
        let obj = {};
        obj.order_id = item.order_id;
        obj.status = status.status;
        obj.reason_text = status.other;
        try {
            setLoader(true);
            await instance.put(url, obj).then((res) => {
                if (res.status === 200 && res?.data?.message === "Update order sucessfully") {
                    closeModal();
                    // const updatedRecord = rowData.map(i => {
                    //     if (i.order_id === item.order_id) {
                    //         i.callStatus = status.status;
                    //         i.reason_text = status.other;
                    //     }
                    //     return i
                    // });
                    // setRowData([...updatedRecord]);
                    // let e = document.querySelector("[data-startDate]");
                    setRefreshData((prev)=>prev+ 1);
                    // getData(fraudval, e.getAttribute('data-pageNo'), e.getAttribute('data-startdate'), e.getAttribute('data-endDate'), searchText, e.getAttribute('data-searchParam'));
                    toast(<SuccessToast body={'Call status updated for Order ' + item.order_name} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
        }
    }
    // const getData = async (
    //     fraud = "",
    //     page = 1,
    //     startDate,
    //     endDate,
    //     searchText,
    //     perams = "&payment_type=&fulfillment=&order_status=&carrier=&type=&tag=",

    // ) => {
    //     var rowData = [];
    //     setLoader(true);
    //     if (fraud !== "") {
    //         perams += "&filter=" + fraud
    //     }
    //     try {
    //         await instance.get(`app/order/getOrder?per_page=${per_page}&page=${page}&order_date=${startDate}&end_order_date=${endDate}&search=${searchText}${perams}`).then((res) => {

    //             //console.log(res);
    //             if (res.status === 200 && Array.isArray(res?.data?.orders)) {
    //                 setRowData(res?.data?.orders || []);
    //                 setOriginalData(res?.data)
    //             }
    //             setLoader(false);
    //         }).catch(e => {
    //             setLoader(false);
    //             console.error(e);
    //             setRowData([]);
    //         })
    //     }
    //     catch (e) {
    //         setLoader(false);
    //         console.error(e);
    //         setRowData([]);
    //     }
    //     return rowData;
    // }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        var searchParams = new URLSearchParams(location.search);
        searchParams.set("page", event.selected + 1)
        navigate(`${location.pathname}?${searchParams.toString()}`);
        // getData(
        //     fraudval,
        //     event.selected + 1,
        //     moment(startDate).format('YYYY-MM-DD'),
        //     moment(endDate).format('YYYY-MM-DD'),
        //     searchText,
        //     searchParam
        // );
    }

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        //console.log(selectedITem.length > 0)
        // setSelectedObject((selectedObject) => [...selectedObject]);
        selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setSelectedObject([]));
    }
    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        var searchParams = new URLSearchParams(location.search);
        searchParams.set("order_date", date.startDate.format('YYYY-MM-DD'));
        searchParams.set("end_order_date", date.endDate.format('YYYY-MM-DD'));
        searchParams.set("page", 1);
        navigate(`${location.pathname}?${searchParams.toString()}`);
        // getData(
        //     fraudval,
        //     pageNo,
        //     date.startDate.format('YYYY-MM-DD'),
        //     date.endDate.format('YYYY-MM-DD'),
        //     searchText,
        //     searchParam
        // )
    }
    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        var searchParams = new URLSearchParams(location.search);
        searchParams.set("search", searchText);
        searchParams.set("page", 1);
        navigate(`${location.pathname}?${searchParams.toString()}`);
        // getData(
        //     fraudval,
        //     pageNo,
        //     moment(startDate).format('YYYY-MM-DD'),
        //     moment(endDate).format('YYYY-MM-DD'),
        //     searchText,
        //     searchParam
        // );
    }

    const onBtExport = useCallback((startDate, endDate) => {
        const config = {};
        commonServiceMethod(`app/order/export?startDate=${startDate}&endDate=${endDate}`, "get", {}, config, setLoader, (res) => {
            if (res.status === 200) {
                toast(<SuccessToast body={res?.data} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 4000,
                });
            }
        });
    }, []);

    const onApplyFilter = (selectionOption = {} , searchTextval = "") => {
        var searchParams = new URLSearchParams(location.search);
        // console.log(selectionOption)
        let url = "";
        Object.keys(selectionOption).map(i => {
            searchParams.set(i, selectionOption[i]);
            url += `&${i}=${selectionOption[i]}`
        })
        searchParams.set("page", 1);
        searchParams.set("search", searchTextval);
        setSearchParam(url);
        localStorage.setItem("searchPeram", url);
        toggleDropdown();
        navigate(`${location.pathname}?${searchParams.toString()}`);
    }

    useEffect(() => {
        var searchParams = new URLSearchParams(location.search);
        let pageNo = searchParams.get("page") || 1;
        let fraudval = searchParams.get("filter") || "";
        let order_date = searchParams.get("order_date") || moment(startDate).format('YYYY-MM-DD');
        let end_order_date = searchParams.get("end_order_date") || moment(endDate).format('YYYY-MM-DD');
        let searchText = searchParams.get("search") || "";
        setSearchText(searchText);
        setFraudVal(fraudval);
        setPageNo(pageNo);
        searchParams.set("page", pageNo);
        if (fraudval === "") {
            searchParams.delete("filter");
        }
        else {
            searchParams.set("filter", fraudval);
        }
        const pageFromDashboard = searchParams.get("pageFrom") === "dashboard" ? true : false;
        searchParams.delete("pageFrom");
        searchParams.set("order_date", order_date);
        searchParams.set("end_order_date", end_order_date);
        searchParams.set("search", searchText);
        let obj = {};
        for (let [key, value] of searchParams.entries()) {
            obj[key] = value;
        }
        
        delete obj.end_order_date;
        delete obj.order_date;
        delete obj.page;
        setPreSelectedOption({...obj});        
        
        navigate(`${location.pathname}?${searchParams.toString()}`);
        getGridData(searchParams.toString(),pageFromDashboard);
    }, [location.search, refreshData])

    const getGridData = async (param, pageFromDashboard) => {
        var rowData = [];
        setLoader(true);
        let url = !pageFromDashboard ? `app/order/getOrder?per_page=50&${param}` : `/app/order/getOrder/dashboard?per_page=50&${param}`;
        try {
            await instance.get(url).then((res) => {

                //console.log(res);
                if (res.status === 200 && Array.isArray(res?.data?.orders)) {
                    setRowData(res?.data?.orders || []);
                    setOriginalData(res?.data)
                }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handleCallStatusChange = async (data, item) => {
        setLoader(true);
        try {
            await instance.post(`app/order/makeacall`, data).then((res) => {
                if (res.status === 200 && res?.data?.status_code === 200) {
                    closeModal();
                    setRefreshData((prev)=>prev+ 1);
                    // getData(fraudval, pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText, searchParam);
                    toast(<SuccessToast body={res?.data?.message + ' for the Order ' + item.order_name} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }
                setLoader(false);
            }).catch(e => {
                console.error(e);
                setLoader(false);
            })
        }
        catch (e) {
            console.error(e);
            setLoader(false);
        }
    }

    const closeExportPopup = () => {
        setOpen(false);
        setSelectedObject([])
        gridRef.current.api.deselectAll();
    }
    const exportMethod = () => {
        var objdata = {};
        const config = {};
        objdata.order_id = selectedObject.map((i) => i.order_id).join();
        commonServiceMethod("app/order/export", "post", objdata, config, setLoader, (res) => {
            //console.log("res", res)
            const downloadUrl = window.URL.createObjectURL(
                new Blob([res.data])
            );
            //console.log(downloadUrl)
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", "file.csv"); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            closeExportPopup();
        });
    }
    const printInvoiceMethod = (obj = []) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        var objdata = "";
        objdata = obj.map((i) => i.order_id).join();
        commonServiceMethod(`app/order/print-invoice/${objdata}?templateName=temp1`, "post", {}, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
            closeExportPopup();
        });
    }

    const cancelOrder = (obj, item) => {
        const config = {};
        commonServiceMethod("app/orders/cancel", "post", obj, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={'This order is ' + item.order_name + ' cancelled'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                setRefreshData((prev)=>prev+ 1);
                // getData(fraudval, pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText, searchParam);
                closeModal();
                closeExportPopup();
            }
        });
    }
    // const gridReadyEvent = () =>{
    //     let allColumnIds = [];
    //     gridRef.current.columnApi.getColumns().forEach((column) => {
    //         allColumnIds.push(column.getId());
    //     });
    //     gridRef.current.columnApi.autoSizeColumns(allColumnIds, true);
    // }
    const fraudFilterChange = (value) => {
        var searchParams = new URLSearchParams(location.search);
        if (value !== fraudval) {
            setFraudVal(value)
            searchParams.set("filter", value);
        }
        else {
            setFraudVal("")
            value = ""
            searchParams.delete("filter");
        }
        setFraudVal(value);
        searchParams.set("page", 1);
        navigate(`${location.pathname}?${searchParams.toString()}`);

        // getData(
        //     value,
        //     pageNo,
        //     moment(startDate).format('YYYY-MM-DD'),
        //     moment(endDate).format('YYYY-MM-DD'),
        //     searchText,
        //     searchParam
        // );
    }
    const syncOrder = () => {
        commonServiceMethod("app/syncOrders", "post", {}, {}, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={'Syncing Order process is running in background'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            }
            else {
                toast(<SuccessToast body={'Syncing Order process is running in background'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            }
        },
            () => {
                toast(<SuccessToast body={'Syncing Order process is running in background'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            }
        );
    }

    return (
        <>
            <div className='order-tabs' data-pageNo={pageNo} data-searchParam={searchParam} data-startDate={moment(startDate).format('YYYY-MM-DD')} data-endDate={moment(endDate).format('YYYY-MM-DD')}>

                <Loader showHide={loader} />
                <div className='quick-buttons'>
                    {/* <Link to={'/customers'} className='quickBtn' >Customer</Link>&nbsp; 
                <Link to={'/products'} className='quickBtn' >Product</Link>&nbsp; */}

                    {/* {checkAccess(10) && <button className='quickBtn' onClick={() => showModal('quickAccess', 'quickAccess', quickAccessMethod)}> <img src={gridIcon} className="gridIcon"></img> Quick Actions</button>}
                <button className='syncBtn'> <img src={refreshIcon} className="refreshIcon"></img> Sync Orders</button> */}

                    {/* <button className='syncBtn' onClick={() => showModal('courierPartner', 'courierPartner')}>Assign Courier Partner</button>
                <button className='syncBtn' onClick={() => showModal('scanawb', 'scanawb')}>Scan/Paste AWBs</button> */}
                </div>
                <button onClick={() => syncOrder()} className='syncBtn' >Sync Orders</button>
                {open ? <Popup className='all-order' show={{ cancel: true }} exportMethod={exportMethod} printInvoiceMethod={printInvoiceMethod} cancelMethod={() => showModal('cancelPopup', selectedObject, cancelOrder)} selecteObject={selectedObject} closePopup={() => { closeExportPopup(); }} /> : null}
                <div className='row filter-section'>
                    <div className="filter-left-container">
                        <div className="form-group">
                            <form onSubmit={searchTextChangeEvent}>
                                <div style={{ position: 'relative' }}>
                                    <span className="icon"><img alt='' src={Search} /></span>
                                </div>
                                <input
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Search"
                                    className='form-control inputWithIcon'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>
                    <div className='filter-right-container'>
                        <DateRangePicker
                            onApply={(e, date) => {
                                onBtExport(date.startDate.format('YYYY-MM-DD'), date.endDate.format('YYYY-MM-DD'));
                            }}
                        >
                            <button type="button" className="btn btn-primary export-btn">
                                Export
                            </button>
                        </DateRangePicker>
                        <div className='dropdown-dialog-parent me-2'>
                            <div className={` dropdown-dialog ${isOpen ? 'open' : ''}`}>
                                <button type='button' className='btn btn-sm  filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 17 }} src={AllFilter} /> Filters</button>
                                <FilterMenu show={isOpen} preSelectedOption={preSelectedOption} filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                                {/* {isOpen && (
                                    <>
                                        <FilterMenu show={isOpen} filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                                    </>
                                )} */}
                            </div>
                        </div>
                        <div className="form-group datepicker-class me-4">
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Calandar} /></span>
                            </div>
                            <DateRangePicker
                                className="datepicker-custom"
                                initialSettings={
                                    {
                                        startDate: startDate,
                                        endDate: endDate,
                                        maxDate: moment().toDate(),
                                        showDropdowns: true,
                                        locale: {
                                            format: 'MMM DD, YYYY',
                                        },
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, 'days').toDate(),
                                                moment().subtract(1, 'days').toDate(),
                                            ],
                                            'Last 7 Days': [
                                                moment().subtract(6, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'Last 30 Days': [
                                                moment().subtract(30, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'This Month': [
                                                moment().startOf('month').toDate(),
                                                moment().endOf('month').toDate(),
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                            ],
                                        },
                                    }}
                                onApply={(e, val) => dateChangeEvent(val, e)}
                            >
                                <input type="text" className="form-control datepicker-range" />
                            </DateRangePicker>
                        </div>
                        <div className='dropdown-dialog-parent'>
                            <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={
                                <Popover id="popover-basic" className='z-index-2000 produc-list'>
                                    <Popover.Body>
                                        <div className=''>
                                            <label className='d-flex p-sku mb-2'><input id='green' checked={fraudval === "green"} style={{ marginRight: '10px' }} type='checkbox' name="fraudType" onClick={() => fraudFilterChange("green")} /> <img src={FraudFilterGreen} style={{ marginRight: '5px' }} /> Low </label>
                                            <label className='d-flex p-sku mb-2'><input style={{ marginRight: '10px' }} checked={fraudval === "yellow"} type='checkbox' name="fraudType" onClick={() => fraudFilterChange("yellow")} /> <img src={FraudFilterYellow} style={{ marginRight: '5px' }} /> Medium </label>
                                            <label className='d-flex p-sku'><input style={{ marginRight: '10px' }} checked={fraudval === "red"} type='checkbox' name="fraudType" onClick={() => fraudFilterChange("red")} /> <img src={FraudFilterRed} style={{ marginRight: '5px' }} /> High </label>
                                        </div>
                                    </Popover.Body>
                                </Popover>
                            }>
                                <p className='btn btn-sm  filter-btn'>
                                    <img src={FraudFilter} /> Fraud Filter
                                </p>
                            </OverlayTrigger>
                            {/* <select className='form-control' onChange={(e) => fraudFilterChange(e.target.value)} >
                                <option value='' className='alert-img'><img src={FraudFilter} /> Fraud Filter</option>
                                <option value='green'>Low</option>
                                <option value='yellow'>Medium</option>
                                <option value='red'>red</option>
                            </select> */}
                        </div>
                        <div className='export-btn hide'>
                            <button type='button' onClick={() => onBtExport(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText, searchParam)} className='btn btn-sm w-100 filter-btn' style={{ color: '#7F56D9' }}><img alt='' style={{ width: 15 }} src={ExportIcon} /> Export</button>
                        </div>
                    </div>
                    <p className='total-count'>Total Count: {originalData?.count}</p>
                </div >
                <DataGridPagination
                    rowData={rowData}
                    columnDefs={columnData}
                    onSelectionChanged={onCellClick}
                    gridref={gridRef}
                    rowSelection={"multiple"}
                    domLayout="autoHeight"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={originalData.per_page}
                    pageCount={originalData.count}
                    forcePage={pageNo-1}
                />
                {popupData.popupName !== "" &&
                    <CallStatus
                        item={popupData.data}
                        show={popupData.popupName}
                        handleSave={popupData.callBackMethod}
                        handleClose={closeModal}
                    />
                }

            </div>
        </>
    );
}

export default AllOrdersBody;

