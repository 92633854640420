import React from 'react';
import PageLayout from '../../global/partialBase/pageLayout';
import ProductsBody from './body';

const Products = () => {
    return (
        <div>
            <PageLayout tabMenu={false} body={<ProductsBody />} />
        </div>
    )
}

export default Products;