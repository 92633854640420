import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import './donetChart.scss';
const DonutChart = ({ data = [], options, className, showDom = '' }) => {
    // Calculate the total value
    // console.log("Donut ",data)
    const { totalText, isTotal, ismargin = true, innerRadius = .75, padAngle = 2, cornerRadius = 10,getFieldTotal=false,getTotalField } = options;
    const { direction = "row", isLegent = true, translateX = 20, translateY = 30 } = options.legends || {}
    const total =getFieldTotal ? data[0][getTotalField] :  data?.reduce((sum, item) => sum + item.value, 0);
    //console.log("//console.log(options, isLegent)",showDom)
    return (
        <div className={className}>
            <ResponsivePie
                data={data || []}
                margin={ismargin ? { top: 40, right: 80, bottom: 40, left: 80 } : 0}
                innerRadius={innerRadius} // This creates the "donut" shape
                padAngle={padAngle}
                cornerRadius={cornerRadius}
                colors={{ datum: 'data.color' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="#333333"
                enableRadialLabels={false} // Disable default labels
                enableSliceLabels={false} // Disable slice labels
                enableArcLinkLabels={false}
                enableArcLabels={false}
                legends={
                    isLegent ?
                        [
                            {
                                anchor: 'bottom',
                                direction: direction,
                                justify: false,
                                translateX: translateX,
                                translateY: translateY,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 9,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]
                        : []
                }
                // tooltip={({ datum }) => (
                //     <div
                //         style={{
                //             background: "white",
                //             padding: "6px 12px",
                //             borderRadius: "4px",
                //             boxShadow: "0 1px 3px rgba(0,0,0,0.3)",
                //             color: datum.color,
                //             fontWeight: "bold"
                //         }}
                //     >
                //         {datum.label}: {datum.value}
                //     </div>
                // )}
            // Add additional custom properties here
            />
            {/* Centered total label */}
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '24px',
                fontWeight: 'bold',
                textAlign: 'center'
            }}>
                {
                    isTotal && <div className='totel-text'>
                        <p className='text'>{totalText}</p>
                        <p className='value'>{total}</p>
                    </div>
                }
            </div>
            {
                showDom ? showDom : ''
            }
        </div>
    );
};

export default DonutChart;
