export const API_URL_STAGING = "http://staging.deyga.in:5000/";
export const API_URL_LIVE = "https://shipping.deyga.in/api/v1/";

import item1 from '../assets/images/sidebar/dashboard.png';
import item2 from '../assets/images/sidebar/orders.png';
// import item3 from '../assets/images/sidebar/cart.png';
import item4 from '../assets/images/sidebar/crown.png';
import item5 from '../assets/images/sidebar/bin.png';
import item6 from '../assets/images/sidebar/settings.png';

export const AllOrderTabDetails = [
    {
        moduleId: 2,
        value: 'All Orders',
        label: 'All Orders',
        "order": 6,
        url: "/orders/all-orders"
    },
    {
        moduleId: 3,
        value: 'Unlabelled Orders',
        label: 'Unlabelled Orders',
        "order": 1,
        url: "/orders/unlabelled"
    },
    {
        moduleId: 4,
        value: 'Labelled Orders',
        label: 'Labelled Orders',
        "order": 2,
        url: "/orders/processOrder"
    },
    {
        moduleId: 5,
        value: 'Printed Orders',
        label: 'Printed Orders',
        "order": 3,
        url: "/orders/printedOrder"
    },
    {
        moduleId: 5,
        value: 'Manifests',
        label: 'Manifests',
        "order": 3,
        url: "/orders/manifestList"
    },
    {
        moduleId: 6,
        value: 'Intransit',
        label: 'Intransit',
        "order": 4,
        url: "/orders/readyShipment"
    },
    {
        moduleId: 7,
        value: 'Delivered Orders',
        label: 'Delivered Orders',
        "order": 5,
        url: "/orders/delivered"
    }
]

export const SideBarMenu = [

    {
        "id": 1,
        "moduleId": 1,
        "menu": 'Dashboard',
        "url": "/dashboard",
        "order": 1,
        "label": "OVERVIEW",
        icon:item1
    },
    {
        "id": 2,
        "menu": 'Orders',
        "moduleId": 0,
        "url": "/orders/all-orders",
        "order": 2,
        "label": "ORDERS",
        icon:item2
    },
    // {
    //     "id": 4,
    //     "moduleId": 9,
    //     "menu": 'Returns',
    //     "url": "/returns/return-requests",
    //     "order": 4,
    //     "label": "ORDERS",
    //     icon:item3
    // },
    {
        "id": 5,
        "moduleId": 10,
        "menu": 'Products',
        "url": "/products",
        "order": 5,
        "label": "SETTINGS",
        icon:item4
    },
    {
        "id": 6,
        "moduleId": 11,
        "menu": 'Customers',
        "url": "/customers",
        "order": 6,
        "label": "SETTINGS",
        icon:item5
    },
    {
        "id": 7,
        "moduleId": 0,
        "menu": 'Settings',
        "url": "/settings",
        "order": 7,
        "label": "SETTINGS",
        icon:item6
    }
]