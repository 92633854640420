import React, { useEffect } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.min.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './styles/styles.scss';
import './styles/responsive.scss';
import { Provider } from "react-redux";
import store from "./store";
import instance from './helpers/axiosInstance.js';
// import { Route, Routes } from 'react-router-dom';
// import PageLayout from './components/global/partialBase/pageLayout.js';
// import Dashboard from './components/screens/dashboard/dashboard.js';
import RootApp from './routes/index.js';
import jwt_decode from 'jwt-decode' // import dependency
import moment from 'moment';


const App = () => {
  useEffect(() => {
    //// console.log("localStorage.getItem('deyga-token')", localStorage.getItem('deyga-token'))
    if (localStorage.getItem('deyga-token') !== "" && localStorage.getItem('deyga-token') !== null) {
      const user = jwt_decode(localStorage.getItem('deyga-token') );
      localStorage.setItem("userDetails",user);
      if(moment(user.exp*1000).isBefore(moment())){
        localStorage.clear();
        window.location.href = "/";
      }
      else{
        getPermissions();
      }
    }

  });
  const getPermissions = async () => {
    try {
      instance.get("app/getPermission").then((res) => {

        if (res.status === 200) {
          let permissions = [...res.data] || [];
          try {
            permissions.push("0");
          }
          catch (e) {
            permissions = [...permissions]
          }
          localStorage.setItem("userPermission", permissions || [])
        }
        else {
          localStorage.setItem("userPermission", [1,3,5,7,9,11,2,4,6,8,10,12,13])
        }

      }).catch(e => {
        console.error(e);
        localStorage.setItem("userPermission", [1,3,5,7,9,11,2,4,6,8,10,12,13])
      })
    }
    catch (e) {
      console.error(e);
      localStorage.setItem("userPermission", [1,3,5,7,9,11,2,4,6,8,10,12,13])
    }

  }


  return (
    <Provider store={store}>
      <ToastContainer />
      <RootApp />
      {/* <BrowserRouter> */}
                {/* <Routes>
                <Route path='/dashboard' element={<PageLayout body = {<Dashboard/>}/>} />
                </Routes> */}
                {/* </BrowserRouter> */}
    </Provider>
  );
}

export default App;
