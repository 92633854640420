import React, { Fragment, useEffect, useState } from "react";
import './performanceByCourier.scss'
import ActiveIcon from '../../../../assets/images/dashboard/active-Icon.png'
import ChartsContainers from "../charts/chartsContainers";
import instance from "../../../../helpers/axiosInstance";

const PerformanceByCourier = (props) => {
  const [partnerChecked, setPartnerChecked] = useState("Delhivery");
  const [partnerList, setPartnerList] = useState([]);
  const changeEvent = (e) => {
    //console.log(e)
    setPartnerChecked(e)
  }
  useEffect(() => {
    getdeliveryPartners();
  },[])
  const getdeliveryPartners = async () => {
    let url = `app/delivery-partners`;
    let obj = {};
    await instance.get(url, obj).then((res) => {
      if (res.status === 200) {
        // console.log(res)
        setPartnerList(res?.data)
      }
    }).catch(e => {
      setLoader(false);
      console.error(e);
    })
  }
  //// console.log(chardData)
  return (
    <div className="Performance-by-courier">
      <div className="delivery-partners">
        <div className="courier-list">
          {
            partnerList.map((i, k) => i && <Fragment key={k}>
              <input className="radio-button" onChange={(e) => changeEvent(i, e)} type="radio" name="partner" value={i} id={"partnerId" + k} defaultChecked={partnerChecked === i} />
              <label className="partners" htmlFor={"partnerId" + k}>{i} <span><img src={ActiveIcon} /></span></label>
            </Fragment>)
          }
        </div>
        <ChartsContainers {...props} selectedCourier={partnerChecked} />
        {/* <input className="radio-button" type="radio" name="sizeBy" value="bluedart" id="sizeWeight" checked={partnerChecked === "bluedart"} />
        <label className="partners" htmlFor="sizeWeight">Bluedart <span><img src={ActiveIcon} /></span></label>
        <input className="radio-button"type="radio" name="sizeBy" value="weight" id="sizeWeight1" checked={partnerChecked === "bluedart"}/>
        <label className="partners" htmlFor="sizeWeight1">Delhivery <span><img src={ActiveIcon} /></span></label>         */}
      </div>
    </div>
  );
}
export default PerformanceByCourier;